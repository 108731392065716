import { grabInterSec } from "./grabPlaneIntect";
import * as THREE from "three";
import { getCurrentUser } from "../firebase";
import { sendRTMmessage } from "../ActionBar/VideoChatAgora/initAgoraRTM";
import { getLiveCursorConfig } from "./getLiveCursorConfig";
import { moveMyCursor } from "../ActionBar/VideoChatAgora/onLiveCursorMessage";

export const onPositionData = (event) => {

  // setTimeout(() => {

  if (getLiveCursorConfig().shareCursor) {

    const planeIntersect = grabInterSec(event);

    const mat = new THREE.Matrix4();
    mat.makeRotationY(-event.eventObject.rotation.y);
    planeIntersect.applyMatrix4(mat);
    const { x, z } = planeIntersect;

    const xScaleFactor = event.eventObject.scale.x || 1;
    const shiftedX = x - event.eventObject.position.x;
    const scaledX = shiftedX / xScaleFactor;

    const zScaleFactor = event.eventObject.scale.z || 1;
    const shiftedZ = z - event.eventObject.position.z;
    const scaledZ = shiftedZ / zScaleFactor;
    const scaledPoistion = new THREE.Vector3(scaledX, 0, scaledZ);


    // requestAnimationFrame(() => {
    //   moveMyCursor({
    //     scaledX,
    //     scaledZ,
    //     user: user.id,
    //   })
    // })
    const user = getCurrentUser();

    // console.log("🚀 ~ file: useTouchHooks.js:30 ~ setTimeout ~ user:", user);

    sendRTMmessage({
      msg: JSON.stringify({
        scaledX,
        scaledZ,
        user: user.id,
        type: "liveCursor",
      }),
    });
  }
  // }, 2)
};
