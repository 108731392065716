import { useState } from "react";

export const NextLevelCollapse = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <p className="key-menu-item fw-bolder">
        <img
          onClick={() => {
            setOpen(!open);
          }}
          data-bs-toggle="collapse"
          data-bs-target=".nextlevel-collapse"
          aria-expanded="false"
          aria-controls="nextlevel-collapse"
          src="images/closemenuarrow.png"
          style={{
            position: "relative",
            width: 25,
            marginRight: 6,
            transition: "rotate .5s ease",
            rotate: `${open ? "180deg" : "0deg"}`,
          }}
        />
        <span
          onClick={() => {
            setOpen(!open);
          }}
          data-bs-toggle="collapse"
          data-bs-target=".nextlevel-collapse"
          aria-expanded="false"
          aria-controls="nextlevel-collapse"
        >
          The Next Level
        </span>
      </p>
      <div
        className="collapse info-collapse nextlevel-collapse sub-collapse"
        id="nextlevel-collapse"
      >
        <div className="card card-body">
          <p>
            The next level game will be released soon. In this game the COE can
            exceed the face value of the pieces. We call this the Plus Sum game
            in which the whole is greater than the sum of the parts. Consistent
            play and progress will open access to this emergent game. We look
            forward to sharing this with you.
          </p>
        </div>
      </div>
    </>
  );
};
