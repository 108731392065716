const size = 1;
const scale = .1;
export const scaled = size * scale;
export const a = Math.PI / 3;
export const hexRadius = size * scale;
const rToHeightFac = 2 * Math.sin(a);
export const height = hexRadius * rToHeightFac;
export const diameter = hexRadius * 2;
export const next = diameter * 3 / 4;
export const baseCount = 15;
export const dubYOffset = height/2
