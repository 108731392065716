

const liveCursorConfig = {
  shareCursor: false,
  showOtherCursors: false,
};

export const getLiveCursorConfig = () => liveCursorConfig;

export const setLiveCursor = (show) => {
  liveCursorConfig.shareCursor = show;
};



export const setOtherCursor = (show) => {
  liveCursorConfig.showOtherCursors = show;
};

