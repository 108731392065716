import { useCallback, useState, useRef, useMemo } from "react";

export const PhaseImg = (props) => {
  const { phaseSrcs = [], time, ...imgProps } = props;

  const phaseTimeOut = useRef({});
  const [srcIndex, setSrcIndex] = useState(0);

  const currentSrc = useMemo(() => phaseSrcs[srcIndex], [srcIndex]);

  const phaseTimer = useCallback(() => {
    if (time) {
      if (phaseTimeOut.current.srcIndex === 1) {
        phaseTimeOut.current.srcIndex = 0;
        setSrcIndex(0);
      } else {
        phaseTimeOut.current.srcIndex = 1;
        setSrcIndex(1);
      }
      phaseTimeOut.current.timeout = setTimeout(() => {
        phaseTimer();
      }, time);
    }
  }, [srcIndex, setSrcIndex, phaseTimeOut]);

  return (
    <img
      {...imgProps}
      ref={(ref) => {
        if (ref && !phaseTimeOut.current.timeout) {
          phaseTimer();
        }
      }}
      src={currentSrc} />
  );
};
