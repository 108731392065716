import React from "react";
import { useLoadWholeHex } from "./useLoadWholeHex";
import { dubYOffset, diameter } from "../hexContants";

export const WholeHexModel2 = (props) => {
  const wholeHexglb = useLoadWholeHex({mode:'low'});
  const {
    pos,
    // dropGrid
  } = props;

  return (
    <group
      scale={0.2}
      position={[
        diameter,
        0, // dropGrid ? -0.1 : 0,
        -dubYOffset / 2,
      ]}
      rotation={[0, -90 * (Math.PI / 180), 0]}
    >
      <primitive
        position={[
          0,
          0, 
          -0.5,
        ]}
        object={wholeHexglb.children[1]}
      />
    </group>
  );
};
