import AgoraRTM from "agora-rtm-sdk";
import { onLiveCursorMessage } from "./onLiveCursorMessage";
import { hideCursor } from "../../Cursors";
import { updateAnimationState } from "../PlayerOrder/useAnimationState";
//const agoraRTMTokenUrl = "http://localhost:5001/big-hado/us-central1/buildAgoraRTMToken";
const agoraRTMTokenUrl =
  "https://buildagorartmtoken-yydrqfodxq-uc.a.run.app";

const appID = "1f2fac45930641f4a4179db6f8b420e4";

const cursorClient = AgoraRTM.createInstance(appID);

let rtmChannel;

const getRtmToken = async (props) => {
  const { user } = props;
  const url = `${agoraRTMTokenUrl}?uid=${user.id}`;
  const raw = await fetch(url);
  const json = await raw.json();
  const { options } = json;

  return options;
};

export const sendRTMmessage = (props) => {
  const { msg = "" } = props;

  try {
    if (rtmChannel) {
      rtmChannel.sendMessage({ text: msg });
    }
  } catch (error) {
    console.log("🚀 ~ file: initAgoraRTM.js:34 ~ sendRTMmessage ~ error:", error)
  }

};

const onParsedMessage = (parsedMessage) => {
  const { type } = parsedMessage;
  switch (type) {
    case "liveCursor": {
      return onLiveCursorMessage(parsedMessage)
    }
    case "hideCursor": {
      const { glyphNumber } = parsedMessage;
      return hideCursor({glyphNumber})
    }
    case "animationState": {
      return updateAnimationState(parsedMessage)
    }
    default: {
      return;
    }
  }
};

const parseMessage = (message) => {
  try {
    const parsedMsg = JSON.parse(message);
    return parsedMsg;
  } catch (error) {
    console.log("🚀 ~ file: initAgoraRTM.js:37 ~ parseMessage ~ error:", error);
  }
};

export const initAgoraRTM = async (props) => {
  const { channelName, user } = props;

  const rtmOptions = await getRtmToken({ user });

  let channel = cursorClient.createChannel(channelName);

  await cursorClient.login(rtmOptions);

  channel.on("ChannelMessage", function (message, memberId) {
    const parsed = parseMessage(message.text);
    if (parsed) {
      onParsedMessage(parsed);
    }
  });
  await channel.join();

  rtmChannel = channel;
};
