import { getBoneCombos } from "./placeReport";

import { setlastSpot } from "../HexGrid/lastSpot";

export const subyGrid = Math.tan(60 * (Math.PI / 180)) / 4 / 10;
export const subxGrid = subyGrid * Math.sin(60 * (Math.PI / 180)) * 2;
export const gridUnitHeight = 4 * subyGrid;
export const gridUnitWidth = 2 * subxGrid;

export const getyGridAdjustMent = (props = {}) => {
  const { deg = 0, xsmooth } = props;
  const xIs4 = xsmooth % 4 == 0;

  if (deg == 0 || deg == 180 || deg === 360) {
    return xIs4 ? 0 : subyGrid * 2;
  }

  if (deg == 60 || deg == 240) {
    const xIs4 = (xsmooth + 1) % 4 == 0;

    return xIs4 ? -subyGrid : subyGrid;
  }
  if (deg == 120 || deg == 300) {
    const xIs4 = (xsmooth + 1) % 4 == 0;

    return xIs4 ? subyGrid : -subyGrid;
  }
};

export const getxGridAdjustMent = (props = {}) => {
  const { deg } = props;

  if (deg == 0 || deg == 180 || deg === 360) {
    return 0;
  }

  if (deg == 60 || deg == 240) {
    return subxGrid;
  }

  if (deg == 120 || deg == 300) {
    return -subxGrid;
  }

  return 0;
};

export const getGridCoords = (x, z, rotation) => {
  const hasRotation = typeof rotation === "number";
  const gridFacttor = hasRotation ? 4 : 1;
  // rotation will determine  xGridAdjustMent

  const smoothdeg = calcGridROtaion(rotation);

  let deg = Math.round(smoothdeg / (Math.PI / 180));

  if (deg < 0) {
    deg = deg + 360;
  }

  const xGridAdjustMent = getxGridAdjustMent({ deg });

  const xGridUnit = subxGrid * (hasRotation ? 2 : 1);

  const xCoord = x / xGridUnit;
  const xCoordRounded = Math.round(xCoord);
  const xGridPoint = xCoordRounded * xGridUnit + xGridAdjustMent;

  const xsmooth = Math.round(xGridPoint / subxGrid);

  const yGridUnit = subyGrid * gridFacttor;
  const yGridAdjustMent = hasRotation
    ? getyGridAdjustMent({ xsmooth, deg })
    : 0;

  const zCoord = z / yGridUnit;

  const zCoordRounded = Math.round(zCoord);

  const zGridPoint = zCoordRounded * yGridUnit - yGridAdjustMent;
  const zsmooth = Math.round(zGridPoint / subyGrid);

  const dropstateKey = `${xsmooth}-${zsmooth}`;

  const pack = {
    zGridPoint,
    xGridPoint,
    dropstateKey,
    xsmooth,
    zsmooth,
    deg,
  };

  return {
    ...pack,
  };
};

export const isValidDropNoAdjust = (x, z, rotation) => {
  const pack = getGridCoords(x, z);

  const { xsmooth, zsmooth } = pack;

  const smoothdeg = calcGridROtaion(rotation);

  const deg = Math.round(smoothdeg / (Math.PI / 180));

  const slant = deg > 0 ? deg % 180 : (360 + deg) % 180;

  const xEven = xsmooth % 2 == 0;
  const zEven = zsmooth % 2 == 0;
  const posSlope = slant > 0 ? slant % 120 === 0 : slant % 60 === 0;

  const bothEven = xEven && zEven;
  const bottOdd = !xEven && !zEven;

  if (slant && bottOdd) {
    const xIs4 = (xsmooth + 1) % 4 == 0;
    const zIs4 = (zsmooth + 1) % 4 == 0;

    const flipedCoords = xIs4 !== zIs4;

    if (posSlope && !flipedCoords) {
      setlastSpot({
        ...pack,
      });
      return true;
    }

    if (!posSlope && flipedCoords) {
      setlastSpot({
        ...pack,
      });
      return true;
    }
  } else if (!slant && bothEven) {
    const xIs4 = xsmooth % 4 == 0;
    const zIs4 = zsmooth % 4 == 0;

    const bothIs4 = xIs4 && zIs4;
    const bothIsNot4 = !xIs4 && !zIs4;

    if (bothIs4 || bothIsNot4) {
      setlastSpot({
        ...pack,
      });
      return true;
    }
  }
};

export const isValidDrop = (x, z, rotation) => {
  const pack = getGridCoords(x, z, rotation);

  const { xsmooth, zsmooth } = pack;

  const smoothdeg = calcGridROtaion(rotation);

  const deg = Math.round(smoothdeg / (Math.PI / 180));

  const slant = deg > 0 ? deg % 180 : (360 + deg) % 180;

  const xEven = xsmooth % 2 == 0;
  const zEven = zsmooth % 2 == 0;
  const posSlope = slant > 0 ? slant % 120 === 0 : slant % 60 === 0;

  const bothEven = xEven && zEven;
  const bottOdd = !xEven && !zEven;

  if (slant && bottOdd) {
    const xIs4 = (xsmooth + 1) % 4 == 0;
    const zIs4 = (zsmooth + 1) % 4 == 0;

    const flipedCoords = xIs4 !== zIs4;

    if (posSlope && !flipedCoords) {
      setlastSpot({
        ...pack,
      });
      return true;
    }

    if (!posSlope && flipedCoords) {
      setlastSpot({
        ...pack,
      });
      return true;
    }
  } else if (!slant && bothEven) {
    const xIs4 = xsmooth % 4 == 0;
    const zIs4 = zsmooth % 4 == 0;

    const bothIs4 = xIs4 && zIs4;
    const bothIsNot4 = !xIs4 && !zIs4;

    if (bothIs4 || bothIsNot4) {
      setlastSpot({
        ...pack,
      });
      return true;
    }
  }
};

export const getDragRotation = (planeIntersect, bone) => {
  const { position } = bone;

  const xDiff = position.x - planeIntersect.x;
  const zDiff = position.z - planeIntersect.z;

  var rad = Math.atan2(xDiff, zDiff);

  const finalRad = rad;
  return finalRad;
};

export const vectorDiffRotation = (basePoint, movingPoint) => {
  const xDiff = basePoint.x - movingPoint.x;
  const zDiff = basePoint.z - movingPoint.z;

  var rad = Math.atan2(xDiff, zDiff);

  const finalRad = rad;
  return finalRad;
};

const stringToThing = (strang) => {
  const [key, points] = strang.replace(".png", "").split("-");

  const thang = {
    key,
    points: parseFloat(points),
    strang,
  };

  return thang;
};

export const initRandomBones = () => {
  const combos = getBoneCombos();

  const bones = Object.keys(combos)
    .map((combo) => {
      const [h1, h2] = combo.split("+");
      const thang1 = stringToThing(h1);
      const thang2 = stringToThing(h2);
      const boneKey = `${thang1.key}-${thang2.key}`;
      return {
        combo,
        thang1: {
          ...thang1,
          boneKey,
        },
        thang2: {
          ...thang2,
          boneKey,
        },
        boneKey,
      };
    })
    .sort(() => Math.random() - 0.5);

  return bones;
};

export const calcGridROtaion = (rotation) => {
  const radcoord = rotation / (60 * (Math.PI / 180));
  const radsmooth = Math.round(radcoord);
  const radGridPoint = radsmooth * (60 * (Math.PI / 180));
  return radGridPoint;
};

// const glyphPositions = getNeighborCells({ xsmooth: 0, zsmooth: 0 }).map(
//   (spot) => {

//     const { xpoint = 0, zpoint = 0 } = spot;

//     const xspot = xpoint * subxGrid;
//     const zspot = zpoint * subyGrid;

//     return {
//       ...spot,
//       xspot,
//       zspot,
//     };
//   }
// );

// console.log("🚀 ~ file: getNeighborCells.js:9 ~ glyphPositions:", glyphPositions)

// const pos2 = glyphs.map((x,i)=>{

//   const deg = (i*60) *(Math.PI/180);
//   // console.log("🚀 ~ file: index.js:29 ~ pos2 ~ deg:", deg)

//   const point = {
//     x:  gridUnitHeight * Math.sin(1 * deg),
//     y:  1 * gridUnitHeight * Math.cos(deg),
//   };
//   console.log("🚀 ~ file: getNeighborCells.js:34 ~ pos2 ~ point:", point)

//   return {
//     deg,
//     point
//   }
// })
