import { useState } from "react";
import { playAudio } from "./contentAudio";

export const MythCollapse = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <p className="key-menu-item fw-bolder">
        <img
          onClick={() => {
            setOpen(!open);
          }}
          data-bs-toggle="collapse"
          data-bs-target=".myth-collapse"
          aria-expanded="false"
          aria-controls="myth-collapse"
          src="images/closemenuarrow.png"
          style={{
            position: "relative",
            width: 25,
            marginRight: 6,
            transition: "rotate .5s ease",
            rotate: `${open ? "180deg" : "0deg"}`,
          }}
        />

        <span
          onClick={() => {
            setOpen(!open);
          }}
          data-bs-toggle="collapse"
          data-bs-target=".myth-collapse"
          aria-expanded="false"
          aria-controls="myth-collapse"
        >
          The Myth of Cohado
        </span>
        <img
          onClick={() => {
            playAudio("/audio/the_myth_of_cohado_final.mp3");
          }}
          src="images/speaker_logo.png"
          className="playMenuAudio"
        />
      </p>
      <div
        className="collapse info-collapse myth-collapse sub-collapse"
        id="myth-collapse"
      >
        <div className="card card-body">
          <p>
            As myth would have it, the eight principles of the octave of
            universal peace were aligned to create abundance and sustainability
            — that is the essence of nature. As in the seven notes that form the
            musical scale or octave, each aspect of the octave carries a
            different symbol. These are the symbols and principles of Cohado. As
            in music, these aspects can be combined in infinite patterns to
            create the songs of our work together here on earth.
          </p>
          <p>
            Millennia ago, when the light vanished from the planet, these
            symbols were hidden to protect them for a future that was ready to
            bring back the balance. Each of the seven symbols was placed on one
            of the continents by an ancient force. The pieces were formed in
            glowing crystals, that when brought into the light, shown with the
            seven colors of the spectrum. Each symbol was embedded in a golden
            hexagonal base.
          </p>
          <p>
            The old stories tell us that at the time of the Great Turning, these
            crystals would re-emerge, and be found by carriers of the new way
            who would learn the power embedded within. They would bring the
            pieces together, recognizing their uniqueness and power, and would
            see the connectedness of the 28 pairs. These carriers of the new way
            would work collectively to align the pieces to maximize the assets
            for all of humanity, and eliminate waste, in any form, by leaving
            nothing or no one hanging. They would work together to practice and
            learn the balance, and would teach and share this technology and
            serve as the souldiers, the Cohadi, to help guide the way to the
            other side. These are the ones revealing the Missing Peace.
          </p>
          <p>We are at the precipice of The Great Turning</p>
        </div>
      </div>
    </>
  );
};
