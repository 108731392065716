import { forwardRef } from "react";
import { hexShape, hexShape3 } from "./createHex";
import * as THREE from "three";
import { isValidDrop } from "../BoneMeshSL/getGridCoords";
import { getLastSpot } from "./lastSpot";

const moveHexMaterial = new THREE.MeshBasicMaterial();
moveHexMaterial.color.set(0x4c4c66);

moveHexMaterial.opacity = 0.5;
moveHexMaterial.transparent = true;
const geo = new THREE.ShapeBufferGeometry(hexShape3);

const smMovmat = new THREE.MeshBasicMaterial();
smMovmat.color.set(0x4c4c66);
const smgeo = new THREE.ShapeBufferGeometry(hexShape);

let moveHexesRef = undefined;

export const getMoveHexesRef = () => moveHexesRef;

export const positionMHXR = (position) => {
  moveHexesRef.position.set(position.x, 0.02, position.z);
};

export const rotateMHXR = (yrotation) => {
  moveHexesRef.rotation.y = yrotation;
};

export const showMHXR = () => {
  moveHexMaterial.color.set(0x00ad8e);
  smMovmat.color.set(0x00ad8e);
  moveHexesRef.scale.set(1, 1, 1);
};

export const updateMHXR = (boneRef, update) => {
  const { parent } = boneRef;

  if (parent && parent.name === "board") {
    moveHexesRef.scale.set(1, 1, 1);
    const lastpack = getLastSpot()
    if (lastpack) {
      positionMHXR({ x: lastpack.xGridPoint, z: lastpack.zGridPoint });
    }

    // if (update.position) {
    //   positionMHXR(update.position);
    // }

    if (update.rotation || update.rotation == 0) {
      rotateMHXR(update.rotation);
    }
    const shouldShow = isValidDrop(
      boneRef.position.x,
      boneRef.position.z,
      boneRef.rotation.y
    );
    if (shouldShow) {
      moveHexMaterial.color.set(0x00ad8e);
      smMovmat.color.set(0x00ad8e);
    } 
  } else {
    moveHexesRef.scale.set(0, 0, 0);
  }
};

export const hideMHXR = () => {
  moveHexesRef.scale.set(0, 0, 0);
};

export const MoveHex = forwardRef((props, outref) => {
  const { pos } = props;

  return (
    <mesh
      rotation={[-90 * (Math.PI / 180), 0, 0]}
      position={[pos.x, 0, pos.y]}
      ref={outref}
      geometry={geo}
      material={moveHexMaterial}
    />
  );
});

export const SMMoveHex = forwardRef((props, outref) => {
  const { pos } = props;

  return (
    <mesh
      rotation={[-90 * (Math.PI / 180), 0, 0]}
      position={[pos.x, 0, pos.y]}
      ref={outref}
      geometry={smgeo}
      material={smMovmat}
    />
  );
});

export const MoveHexes = (props) => {
  const { pos = { x: 0, y: 0 } } = props;

  return (
    <mesh
      scale={0}
      ref={(ref) => {
        if (ref) {
          moveHexesRef = ref;
        }
      }}
      position={[pos.x, 0, pos.y]}
    >
      <MoveHex pos={{ x: 0, y: 0.09, z: 0 }} />
      <MoveHex pos={{ x: 0, y: -0.09, z: 0 }} />

      <SMMoveHex pos={{ x: 0, y: 0.09, z: 0 }} />
      <SMMoveHex pos={{ x: 0, y: -0.09, z: 0 }} />
    </mesh>
  );
};
