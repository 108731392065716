import { useMemo, useCallback } from "react";
import { setLastTouched } from "../../ThreeUtills/useTouchHooks";
import { useTexture } from "@react-three/drei";
import { getLastSelectedBone } from "../../ControlPalette/setLastSelectedBone";

const spinArmLength = -4;

const sideLength = 6;

const hideMarker = (markerRef) => {
  const lastSelectedBone = getLastSelectedBone();
  if (!lastSelectedBone) {
    markerRef.material.opacity = 0;
  }
};

export const RotateTarget = (props) => {
  const {
    spin,
    children,
    onRotatePosition,
    parentId,
    onPointerDown: sendPointerDown = () => {},
  } = props;

  const targetProps = useMemo(() => {
    const raidOff = spin ? Math.PI : 0;
    return {
      parentId,
      setPosition: (worldPositionFromEvent) => {
        if (onRotatePosition) {
          onRotatePosition({
            worldPositionFromEvent,
            raidOff,
          });
        }
      },
      setParent: () => {},
      onDrop: () => {
        onRotatePosition({
          drop: true,
        });
      },
    };
  }, [spin, onRotatePosition, parentId]);

  const onPointerDown = useCallback(
    (event) => {
      const lastSelectedBone = getLastSelectedBone();
      if (!lastSelectedBone) {
        event.stopPropagation();
        sendPointerDown();
        setLastTouched(targetProps);
      }

    },
    [targetProps, setLastTouched, sendPointerDown]
  );

  const rotateTexture = useTexture("./images/rotate_right.png");

  return (
    <mesh
      onPointerOver={(event) => {
        const lastSelectedBone = getLastSelectedBone();
        if (!lastSelectedBone) {
            event.eventObject.material.opacity = 1;
        }
      }}
      onPointerLeave={(event) => {
        hideMarker(event.eventObject);
      }}
      onPointerCancel={(event) => {
        hideMarker(event.eventObject);
      }}
      name={`rTaget${ spin ? 1 : -1}${parentId}`}
      position={[0, 2, spinArmLength]}
      onPointerDown={onPointerDown}
    >
      <boxGeometry args={[sideLength, 0.5, sideLength]} />
      <meshBasicMaterial map={rotateTexture} opacity={0} transparent />
      {children}
    </mesh>
  );
};
