import { useTexture } from "@react-three/drei";

const cohadoHeight = 1 * (.13);

const ratio = 305 / 89

const cohadoWidth = cohadoHeight * ratio;

export const TabPane = (props) => {
    const boneBarTexture = useTexture("./images/bonebartab.png");

    return (
        <mesh rotation={[90 * (Math.PI / 180), 0, Math.PI]} name={`tabpane`} position={[0, -.01, .23]}>
            <boxGeometry args={[cohadoWidth, cohadoHeight, .01]} />
            <meshBasicMaterial
                transparent
            map={boneBarTexture} />
        </mesh>
    )
}    