import { RootBonesCollapse } from "./RootBonesCollapse";
import { ReasonCollapse } from "./ReasonCollapse";
import { MythCollapse } from "./MythCollapse";

export const OriginCollapse = (props) => {
  return (
    <>
      <p
        className="key-menu-item "
        data-bs-toggle="collapse"
        data-bs-target=".origin-collapse"
        aria-expanded="false"
        aria-controls="origin-collapse"
      >
        Origin
      </p>

      <div
        className="collapse info-collapse origin-collapse"
        id="origin-collapse"
      >
        <div className="card card-body p-0">
          <MythCollapse />
          <RootBonesCollapse />
          <ReasonCollapse />
          <div className="d-flex justify-content-center">
            <img
              data-bs-toggle="collapse"
              data-bs-target=".origin-collapse"
              aria-expanded="false"
              aria-controls="origin-collapse"
              src="images/closemenuarrow.png"
              style={{
                position: "relative",
                width: 50,
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
