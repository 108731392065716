import { BoneMesh } from "../BoneMeshSL";

const getBoneType = () =>{
    return 'low'
}

const boneType = getBoneType()

export const BoneSwitch = (props) =>{

    switch (boneType) {
    
        default:{
            return <BoneMesh {...props} />
        }
    }

}