import { useForm } from "react-hook-form";
import { useMemo, useCallback } from "react";
import moment from "moment";
import { themeColors } from "../../themeColors";
import { firestore, getCurrentUser } from "../../firebase";
import { getPlayerDisplayColor } from "./getPlayerDisplayColor";
import { getUserCoilfeWithEmail } from "../SignUp/formatFormSubmitEvent";
const mailGameRef = firestore.collection("mail");
const cloudGameRef = firestore.collection("cloudGames");

const pngLinkSrc =
  "https://firebasestorage.googleapis.com/v0/b/big-hado.appspot.com/o/handsbones.png?alt=media&token=1350d619-dca2-464d-8791-58aa6de84eed";

export const GamePill = (props) => {
  const { game, onDeleteClick = () => {} } = props;

  const user = getCurrentUser();

  const { register, setValue, handleSubmit, watch } = useForm();
  const wacthData = watch();
  const createdString = useMemo(() => {
    const { createdStamp } = game;
    return createdStamp ? moment(createdStamp).format("LLL") : "N/A";
  }, [game]);

  const turn = useMemo(() => {
    const { turn = {} } = game || {};
    return turn;
  }, [game]);

  const players = useMemo(() => {
    // const spots = Object.keys(game).map((key) => game[key]);

    // const inGamePlayers = spots.filter((spot) => spot.isPlayerData);

    // const invitedPlayers = spots
    //   .filter((spot) => spot.isInvitedData)
    //   .filter(
    //     (spot) => !inGamePlayers.find((inSpot) => inSpot.email === spot.email)
    //   );

    // const allPlayers = [...inGamePlayers, ...invitedPlayers].sort(
    //   (a, b) => a.stamp - b.stamp
    // );

    const { allPlayers = [] } = game;

    return allPlayers;
  }, [game]);

  const onAddPlayer = useCallback(async () => {
    // const { formData } = props;
    const { invitedEmail } = wacthData;

    const formated = invitedEmail.toLowerCase().trim();

    if (formated && formated.indexOf(",") === -1 && players.length < 7) {
      const gameLink = `${window.location.origin}/three?gameId=${game.id}`;

      const { allPlayers = [], invitedPlayers = [] } = game;

      const invitedsCofile = await getUserCoilfeWithEmail({ email: formated });

      const screenName =
        invitedsCofile && invitedsCofile.screenName
          ? invitedsCofile.screenName
          : formated;

      try {
        const playaPac = {
          isInvitedData: true,
          stamp: new Date().getTime(),
          invitedBy: user.id,
          email: formated,
          screenName,
        };
        allPlayers.push(playaPac);
        invitedPlayers.push(formated);

        const allEmails = allPlayers.map((player) => {
          return player.email;
        });

        await cloudGameRef.doc(game.id).update({
          [formated.split(".").join("-")]: {
            ...playaPac,
          },
          allPlayers,
          invitedPlayers,
          allEmails,
          activeEmails: [...allEmails],
        });
        await mailGameRef.add({
          // ...formData,
          to: [formated],
          message: {
            subject: `Cohado Invite from ${user.email}`,
            html: `
                  <div style="background-color:#2a2c2c;padding:6px;border-radius:20px">
                      <p style="font-family:lucida sans unicode;font-size:36px;color:#feb867;font-weight:bold;text-align:center"> ¡¡Welcome!! </p>
                      <p style="font-family:lucida sans unicode;font-size:36px;color:#feb867;font-weight:bold;text-align:center"> You have been invited to play Cohado by: </p>
                      <p style="font-family:lucida sans unicode;font-size:36px;color:white;font-weight:bold;text-align:center"> <a style="color:white;text-decoration:none" href="mailto:${user.email}" target="_blank">${user.email}</a> </p>
                      <p style="margin:0;font-family:lucida sans unicode;font-size:36px;color:#feb867;font-weight:bold;text-align:center"> Ready to join your coponents? Press the button! </p>
                      <a style="text-decoration:none;text-align:center;margin:0;border-radius:10px;font-size:36px;color:transparent;text-align:center;" href="${gameLink}">
                          <p style="text-align:center;margin:0">
                             <span style="height: 131px;width: 172px;background-color:transparent;display: inline-block;margin:0;background-image:url('https://firebasestorage.googleapis.com/v0/b/big-hado.appspot.com/o/play_button.png?alt=media&token=257914d6-7613-41f4-a8ca-6a142ce06755');background-repeat:no-repeat;background-position:center;background-size:cover"></span>             
                          </p>
                      </a>
                      <p style="text-align:center;margin:0">
                        <span style="height: 200px;width: 300px;background-color:transparent;display: inline-block;margin:0;background-image:url('https://firebasestorage.googleapis.com/v0/b/big-hado.appspot.com/o/cohado_logo_transparent.png?alt=media&token=61f7fb61-81b7-4485-9ce5-453fe8382429');background-repeat:no-repeat;background-position:center;background-size:contain"></span>             
                      </p>
                  </div>
            `,
          },
        });
      } catch (error) {
        console.log("saveContactForm -> error", error);
      }
      setValue("invitedEmail", "");
    }
  }, [game, players, wacthData]);

  const { pointSections = {}, coe = 0, rounds = [] } = game;

  const {
    assets = { total: 0 },
    waste = { total: 0 },
    una = { total: 0 },
  } = pointSections;

  const liveRounds = useMemo(() => [...rounds, { coe }], [rounds, coe]);
  const avgCoe = useMemo(() => {
    if (!rounds.length) {
      return coe;
    }

    const sum = rounds.reduce((sum, roundData) => {
      return sum + roundData.coe;
    }, 0);
    const avg = sum / rounds.length;
    return Math.round(avg);
  }, [rounds]);

  const iAmhost =
    user && game && game.creator ? game.creator.id == user.id : false;

  const showInvite = useMemo(() => {
    return !game.playerOrderList && iAmhost;
  }, [game, iAmhost]);

  const showArchiveButton = useMemo(() => {
    return true;
  });
  return (
    <div
      style={{
        backgroundImage: "url(/images/01_game_background.png)",
        backgroundColor: "transparent",
        backgroundSize: "100% 100%",
      }}
      className="game-pill d-flex"
    >
      <div
        style={{
          marginRight: 32,
        }}
        className="d-flex flex-column justify-content-center align-items-center"
      >
        <a
          style={{
            height: 177,
            width: 225,
          }}
          href={`/three?gameId=${game.id}`}
        >
          <div
            style={{
              position: "relative",
              width: "100%",
              height: "100%",
              borderRadius: "16%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              overflow: "hidden",
            }}
          >
            <img
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                top: 0,
                left: 0,
                zIndex: 1,
              }}
              src="./images/02_solid_color.png"
              alt=""
            />
            <img
              style={{
                position: "relative",
                width: "100%",
                height: "100%",
                zIndex: 5,
              }}
              src={game.lastPic64}
              alt=""
            />
            <img
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                border: "3px solid black",
                top: 0,
                left: 0,
                zIndex: 10,
              }}
              src="./images/03_gamespace_mask.png"
              alt=""
            />
          </div>
        </a>
        <a
          style={{
            margin: 12,
          }}
          href={`/three?gameId=${game.id}`}
        >
          <img
            style={{
              width: 150,
            }}
            src="/images/playbuttonblue.png"
            alt=""
          />
        </a>
      </div>
      <div className="flex-1 d-flex flex-column">
        {showInvite && (
          <div
            className="d-flex my-2"
            style={{
              backgroundImage: "url(/images/invite_players.png)",
              backgroundColor: "transparent",
              backgroundSize: "100% 100%",
              border: 0,
            }}
          >
            <div
              className="d-flex"
              style={{
                width: "18%",
                position: "relative",
              }}
            >
              <button
                onClick={() => {
                  onAddPlayer();
                }}
                style={{
                  left: "23%",
                  flexGrow: 0,
                  position: "relative",
                  backgroundImage: "url(/images/lildashbutton.png)",
                  backgroundColor: "transparent",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100% 100%",
                  border: 0,
                  textAlign: "center",
                  padding: "3px 12px",
                }}
              >
                Invite
              </button>
            </div>

            <input
              {...register("invitedEmail")}
              style={{
                backgroundColor: "transparent",
                border: 0,
                outline: 0,
                boxShadow: "none",
                fontSize: 18,
                marginRight: "5%",
              }}
              type="text"
              className="form-control form-control-sm flex-1"
            />
          </div>
        )}

        <p className="mt-2 mb-0 text-danger">Players:</p>

        <div className="d-flex mb-2 flex-wrap">
          {players.map((player) => {
            const { email, screenName } = player;
            return (
              <p
                onDoubleClick={() => {
                  if (showInvite && props.onPlayerDoubleClick) {
                    props.onPlayerDoubleClick({ game, player });
                  }
                }}
                key={email}
                className="m-1"
                style={{
                  color: getPlayerDisplayColor({ player }),
                  textDecoration:
                    turn && turn.id && turn.id === player.id
                      ? "underline"
                      : "unset",
                }}
              >
                {screenName || email},
              </p>
            );
          })}
        </div>

        <div className="flex-1"></div>

        <div>
          <p>
            <span className="text-danger me-1">Created: </span>
            <span className="greh-lite">{createdString}</span>
          </p>
        </div>
        <p>
          <span className="text-danger me-1">CODE: </span>
          <span className="greh-lite">{game.id}</span>
        </p>

        <div
          style={{ overflow: "hidden" }}
          className="d-flex mb-2 w-100 align-items-end"
        >
          <div style={{ overflow: "scroll" }} className="d-flex flex-1">
            <div
              className="m-1 ps-2"
              style={{
                color: `#${themeColors.goldhado}`,
                borderLeft: `2px solid #${themeColors.greh}`,
              }}
            >
              <p className="m-0">AVG</p>
              <p className="m-0"> COE: {avgCoe} </p>
            </div>
            {liveRounds.map((player, i) => {
              const { coe } = player;

              return (
                <div
                  key={i}
                  className="m-1 ps-2"
                  style={{
                    color: `#${themeColors.goldhado}`,
                    borderLeft: `2px solid #${themeColors.greh}`,
                    borderBottom:
                      i == rounds.length
                        ? `2px solid $${themeColors.goldhado}`
                        : "",
                  }}
                >
                  <p className="m-0"> COE: {coe} </p>
                  <p className="m-0">ROUND {i + 1}</p>
                </div>
              );
            })}
          </div>

          <div className="d-flex flex-column">
            {/*
                {showArchiveButton && (
            <button
              style={{
                backgroundColor: "white",
                border: 0,
                padding: 0,
                height: 40,
              }}
              onClick={async () => {
                
              }}
            >
              archive
            </button>
          )} */}

            {iAmhost && (
              <button
                style={{
                  backgroundColor: "transparent",
                  border: 0,
                  padding: 0,
                  height: 40,
                }}
                onClick={async () => {
                  onDeleteClick(game);
                  const myModal = new window.bootstrap.Modal(
                    document.getElementById("deleteGame"),
                    {
                      keyboard: false,
                    }
                  );
                  myModal.show();
                }}
              >
                <img
                  style={{
                    backgroundColor: "transparent",
                    height: 40,
                    margin: 0,
                  }}
                  src="/images/deletebutton.png"
                  alt=""
                />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
