import { getGridCoords } from "./getGridCoords";

// const height = (Math.tan(60 * (Math.PI / 180)) / 4) / 10;
// const glyphOffSet = Math.tan(60 * (Math.PI / 180)) / 2 / 10;
export const getGlyphPositions = (props) => {

  const { bonePosition, boneRotation, thang1, thang2 } = props;

  if (bonePosition) {
    const direction = -1;

    const gt = {
      x: bonePosition.x + 0.09 * Math.sin(direction * boneRotation),
      y: bonePosition.z + direction * 0.09 * Math.cos(boneRotation),
    };

    const gb = {
      x: bonePosition.x + 0.09 * Math.sin(-direction * boneRotation),
      y: bonePosition.z + -direction * 0.09 * Math.cos(boneRotation),
    };

    // const x = glyphOffSet * Math.sin(direction * bone.rotation.y)
    // const y = direction * -1 * glyphOffSet * Math.cos(bone.rotation.y)
    const glyphs = [
      {
        ...thang1,
        ...getGridCoords(gt.x, gt.y),
      },
      {
        ...thang2,
        ...getGridCoords(gb.x, gb.y),
      },
    ];
    return glyphs

  }
  return []
};
