export const openCohadoMap = () => {
  const myModal = new window.bootstrap.Modal(
    document.getElementById("showmap"),
    {
      keyboard: false,
    }
  );
  myModal.show();

  const cohadomap = document.getElementById("cohadomap");

  cohadomap.style.transform = "translate(0px,0px)";
  // cohadomap.style.height = "70vh";
  cohadomap.style.width = "80vw";
  cohadomap.style.height = "fit-content";
  const mapImage = document.getElementById('mapImage')

  mapImage.style.width = "100%";
  mapImage.style.height = "unset";

  cohadomap.setAttribute("data-x", 0);
  cohadomap.setAttribute("data-y", 0);
};
