import { useState } from "react";

import { getCurrentUser, gamesCollectionRef } from "../../firebase";

export const useUserGames = () => {
  const [pagedGames, setPagedGames] = useState([]);

  const getUserGames = ({ startStamp ,archived =false,showMyHostedGames = false }) => {
    const user = getCurrentUser();
    // const cloudGameRef = gamesCollectionRef.where(user.id, "!=", "null");
    const cloudGameRef = gamesCollectionRef
      // .where('archived','==',archived)
        // .where('creator.id','==',"YfuOe0ipkBYAIAsUcSZcwyrXZOr2")

        // we need to use and creat an "active emails" list so that users can remove themselfs , from the game showing up for them if they want
        // .where("activeEmails", "array-contains", user.email)

       .where("allEmails", "array-contains", user.email)
       .orderBy("createdStamp", "desc")
       .startAt(startStamp)
      .limit(5);

    const unsubscribe = cloudGameRef.onSnapshot({
      next: (snapshot) => {
        const { docs } = snapshot;
        const gamesResults = docs.map((doc) => {
          const data = doc.data();
          const lastPic64 = data.lastPic64 || "/images/start.png";

          return {
            ...data,
            lastPic64,
            id: doc.id,
          };
        });

        setPagedGames(gamesResults);
        document.getElementById("gamepillscroll").scrollTop = 0;
      },
      error: (error) => {
        console.log("watchCloudGame -> error", error);
      },
    });

    return unsubscribe;
  };


  return { getUserGames, pagedGames, getUserGames };
};
