import './beatmodal.css'


const cohadoHeight = 329;

const ratio = 790 / 329;

const cohadoWidth = cohadoHeight * ratio;

export const BeatModal = (props) => {
  const {
    setunlockShowWell,
    callNextTurn,
    canShowPickOnBeatAlert,
    nextActive,
  } = props;

  return (
    <div
      className="modal fade"
      id="beatMoveModal"
      data-bs-keyboard="true"
      tabIndex="-1"
      aria-labelledby="beatMoveModal"
      aria-hidden="true"
    >
      <div

        style={
          {
            minWidth: cohadoWidth
          }
        }
        className="modal-dialog modal-dialog-centered modal-dialog-scrollable beat-modal">
        <div className="modal-content summary-drag"

          style={{
            backgroundImage: 'url(/images/takeabeat.png)',
            backgroundSize: '100% 100%',
            height: cohadoHeight,
            width: cohadoWidth,
            minWidth: cohadoWidth,
            backgroundColor: 'transparent',
            paddingBottom: 50,
            paddingLeft: 50,
            paddingRight: 50,
            paddingTop: 30,
          }}

        >
          <div className="modal-header d-flex justify-content-center m-o p-0 border-0"
          
            style={{
              height:'21%'
            }}
          >
            <h5 className="modal-title">
              Take A Beat?
            </h5>
            <img
              className="mx-3"
              style={{
                height: cohadoHeight / 10
              }}
              src="/images/CohadoX.png"
              type="button"
              data-bs-dismiss="modal"
            />
          </div>
          <div className="modal-body"
          
          style={{
            position:'relative',
            height:'60%',
            flexShrink:0
          }}
          
          
          >
            You have not place a bone yet, if you proceed to the next turn the
            community will take a beat
          </div>
          <div className="modal-footer justify-content-between py-0 border-0"
          
          // style={{
          //   position:'relative',
          //   height:'21%',
          //   flexShrink:0
          // }}
          
          >
            <button

              style={{
                backgroundImage: 'url(/images/longbuttons.png)',
                backgroundSize: '100% 100%',
                backgroundColor: 'transparent',
                padding: "6px 12px",
                border: 0,
                textAlign: "center",
                padding: "6px 18px",
                textDecoration: "none",
                margin: 12,
              }}

              type="button"
              data-bs-dismiss="modal"
            >
              Close / Place Bone
            </button>
            {canShowPickOnBeatAlert && (
              <button

                style={{
                  backgroundImage: 'url(/images/longbuttons.png)',
                  backgroundSize: '100% 100%',
                  backgroundColor: 'transparent',
                  padding: "6px 12px",
                  border: 0,
                  textAlign: "center",
                  padding: "6px 18px",
                  textDecoration: "none",
                  margin: 12,
                }}

                onClick={() => {
                  setunlockShowWell(true);
                }}
                type="button"
                data-bs-dismiss="modal"
              >
                Pick Bone
              </button>
            )}
            <button

              style={{
                backgroundImage: 'url(/images/longbuttons.png)',
                backgroundSize: '100% 100%',
                backgroundColor: 'transparent',
                padding: "6px 12px",
                border: 0,
                textAlign: "center",
                padding: "6px 18px",
                textDecoration: "none",
                margin: 12,
              }}

              type="button"
              data-bs-dismiss="modal"
              onClick={() => {
                if (nextActive) {
                  callNextTurn();
                }
              }}
            >
              Next / Take Beat
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
