import moment from "moment/moment";
import { getBoardCanvasMiniCloneImageData } from "../BoardImgControl/canvasImage";
import { summaryCardCollectionRef } from "../firebase";
import { sleep } from "./sleep";

export const buildGameCardData = async (props) => {
  const { cloudGame, setHidePlatforms = () => {} } = props;
  let { cloneDataUrl } = props;

  const {
    coe,
    beats = 0,
    id,
    allEmails = [],
    allPlayers = [],
    pointSections = {},
    playerOrderList = [],
    createdStamp,
    gameName = "",
  } = cloudGame;

  try {
    const { docs = [] } = await summaryCardCollectionRef
      .where("gameId", "==", id)
      .get();

    if (!cloneDataUrl) {
      setHidePlatforms(true);
      await sleep(2000);
      const imageData = await getBoardCanvasMiniCloneImageData();
      const { cloneDataUrl: newDataUrl } = imageData;
      setHidePlatforms(false);
      cloneDataUrl = newDataUrl;
    }

    const stamp = moment();
    const stampMil = stamp.valueOf();
    const stampFormat = stamp.format("LLL");

    const summaryPack = {
      gameId: id,
      createdStamp,
      gameName,
      coe,
      beats,
      allEmails,
      stampMil,
      stampFormat,
      cloneDataUrl,
      allPlayers,
      pointSections,
      playerOrderList,
    };

    if (!docs.length) {
      await summaryCardCollectionRef.add({
        ...summaryPack,
      });
    } else {
      await summaryCardCollectionRef.doc(docs[0].id).update({
        ...summaryPack,
      });
    }

    callSummaryImageService({ gameId: id });

    const myModal = new window.bootstrap.Modal(
      document.getElementById("confirmModal"),
      {
        keyboard: false,
      }
    );
    myModal.show();

    // initial summary card serivice
  } catch (error) {
    console.log(
      "🚀 ~ file: buildGameCardData.js:38 ~ buildGameCardData ~ error:",
      error
    );
  }
};

// const summaryImageServiceUrlBase = "http://localhost:8080";

const summaryImageServiceUrlBase = "https://buildsummarycard-yydrqfodxq-uc.a.run.app"

const callSummaryImageService = async (props) => {
  try {
    const { gameId } = props;

    const summaryImageServiceUrl = `${summaryImageServiceUrlBase}?gameid=${gameId}`;

    await fetch(summaryImageServiceUrl);
  } catch (error) {
    console.log(
      "🚀 ~ file: buildGameCardData.js:91 ~ callSummaryImageService ~ error:",
      error
    );
  }
};
