import interact from "interactjs";
let lastTargetRec ={
  height:100,
  width:150,
}
let mapPinchScale = 1;

let baseArea = 100 * 150;

export const getLastRec = () =>{
  return lastTargetRec
}

export const initPlayerWindowDrag = () => {
  interact(".playerwindow-drag").resizable({
    // playerwindow from all edges and corners
    edges: { left: true, right: true, bottom: true, top: true },
    listeners: {
      end(event) {
        var target = event.target;

        const lastrec = event.rect;

        document
          .getElementsByClassName("streams-scroll")
          .item(0).style.overflow = "scroll";
        target.parentNode.parentNode.style.transform = `scale(1)`;

        baseArea =  lastrec.width * 100/150 * lastrec.width;

        setAllRects({
          width: lastrec.width + "px",
          height: (lastrec.width * 100/150)  + "px",
        });
      },
      move(event) {
        var target = event.target;

        document
          .getElementsByClassName("streams-scroll")
          .item(0).style.overflow = "visible";

        lastTargetRec = {
          ...event.rect,
        };

        const newArea = event.rect.width * event.rect.height;

        const newScale = newArea / baseArea;

        target.parentNode.parentNode.style.zIndex = 50;

        target.parentNode.parentNode.style.transform = `scale(${newScale})`;
      },
      start(event){
        console.log("🚀 ~ file: usePlayerWindowDrag.js:65 ~ start ~ event:", event);
    
          [...document.getElementsByClassName("streamer-vid")].forEach(
            (playerWindow) => {
              // playerWindow.style.opacity = 0;
              playerWindow.parentNode.parentNode.style.opacity = 0;
            }
          );
    
          event.target.parentNode.parentNode.style.opacity = 1;
    
        },
    },

    modifiers: [
      interact.modifiers.restrictSize({
        min: { width: 100, height: 66 },
      }),
    ],
    inertia: false,
  });
};

const setAllRects = ({ height, width }) => {
  [...document.getElementsByClassName("streamer-vid")].forEach(
    (playerWindow) => {
      playerWindow.style.width = width;
      playerWindow.style.height = height;
      playerWindow.parentNode.parentNode.style.opacity = 1;
      playerWindow.parentNode.parentNode.style.zIndex = 1;
    }
  );
};

const widthMode = () => {
  // const targetRec = lastTargetRec
  //   ? lastTargetRec
  //   : target.getBoundingClientRect();
  // const dw = Math.abs(event.rect.width - targetRec.width);
  // const dh = Math.abs(event.rect.height - targetRec.height);
  // const widthMove = dw >= dh;
  // if (widthMove) {
  //   // target.style.width = event.rect.width + "px";
  //   // target.style.height = (event.rect.width * 100) / 150 + "px";
  //   setAllRects({
  //     width: event.rect.width + "px",
  //     height: (event.rect.width * 100) / 150 + "px",
  //   });
  // } else {
  //   // target.style.width = (event.rect.height * 150) / 100 + "px";
  //   // target.style.height = event.rect.height + "px";
  //   setAllRects({
  //     width: (event.rect.height * 150) / 100 + "px",
  //     height: event.rect.height + "px",
  //   });
  // }
  // lastTargetRec = {
  //   ...event.rect,
  // };
};

const grabWholeFram = () => {
  const listeners = {
    end(event) {
      var target = event.target;

      const lastrec = [
        ...target.getElementsByClassName("streamer-vid"),
      ][0].getBoundingClientRect();
      target.style.transform = `scale(1)`;

      document.getElementsByClassName("streams-scroll")
      .item(0).style.overflow = "scroll";

      baseArea = lastrec.height * lastrec.width;

      setAllRects({
        width: lastrec.width + "px",
        height: lastrec.height + "px",
      });
    },
    move(event) {
      var target = event.target;

      document.getElementsByClassName("streams-scroll")
      .item(0).style.overflow = "visible";

      lastTargetRec = {
        ...event.rect,
      };

      const newArea = event.rect.width * event.rect.height;


      const newScale = newArea / baseArea;

      target.style.zIndex = 50;

      target.style.transform = `scale(${newScale})`;
    },
  };
};
