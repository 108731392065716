import { useState } from "react";
import { playAudio } from "./contentAudio";

export const ReasonCollapse = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <p className="key-menu-item fw-bolder">
        <img
          onClick={() => {
            setOpen(!open);
          }}
          data-bs-toggle="collapse"
          data-bs-target=".reason-collapse"
          aria-expanded="false"
          aria-controls="reason-collapse"
          src="images/closemenuarrow.png"
          style={{
            position: "relative",
            width: 25,
            marginRight: 6,
            transition: "rotate .5s ease",
            rotate: `${open ? "180deg" : "0deg"}`,
          }}
        />
        <span
          onClick={() => {
            setOpen(!open);
          }}
          data-bs-toggle="collapse"
          data-bs-target=".reason-collapse"
          aria-expanded="false"
          aria-controls="reason-collapse"
        >
          The Reason of Cohado
        </span>
        <img
          onClick={() => {
            playAudio("/audio/the_reason_final_.mp3");
          }}
          src="images/speaker_logo.png"
          className="playMenuAudio"
        />
      </p>
      <div
        className="collapse info-collapse reason-collapse sub-collapse"
        id="reason-collapse"
      >
        <div className="card card-body">
          <p>
            Cohado is a powerful tool to support movement to a world that works
            for all people. Originally engineered as a collaborative puzzle,
            made of 28 bones, each composed of two linked hexagons bearing two
            of the seven symbols of Cohado. These 28 pieces form the underlying
            architecture of the Cohado platform. Every permutation of the
            possible pairs make up the Cohado set.
          </p>
          <p>
            In virtually all indigenous cultures, there is a Cohadic value
            underlying sustainability for the community, tribe, nation, planet.
            This value had at its root, honoring, respecting and caring for the
            earth – the universe, in-so-doing, manifesting this value through
            every decision’s impact on life and the future generations. The
            Iroquois, the Haudenosaunee nation, was said to consider seven
            generations in making such important decisions. As a result of this
            philosophical stance, it was thought disrespectful to discard any
            part of the animal that was taken to feed and clothe the people.
            Throughout the indigenous peoples of the planet, often the bones of
            the animal were used as a tool to communicate with nature. The Bones
            were thrown in order to read the chaotic patterns of nature and
            life, to provide a window into our own existence. These bones
            evolved into simple tiles with symbols carved into them – the early
            form of dominos, then into dice, then the cards and games of modern
            day. Volume raise
          </p>
          <p>
            Cohado was derived from the Zulu people of South Africa. It is said
            that they used four bones of varying sizes to perform their readings
            – and the Cohado bone was the longest bone that indicated that which
            does not waver – in our words, “The Long, Sustainable View”. In
            every culture there are operating systems, sets of rules that govern
            the behaviors or norms of the culture. We learn these operating
            systems through play - experiencing and testing our relationships to
            these norms with others in simulated contexts. These games help us
            to learn to play by the rules governing the culture we adapt to, and
            collectively create. The rules that have governed most of western
            culture are based upon individual gain as measured against the
            others playing. We win relative to the loss of others. This leaves
            incredible waste on the table, and metaphorically, the planet. This
            is fundamentally unsustainable for communities and for the planet.
            Cohado offers a new operating system, a new way to play, one that is
            about us learning to bring our individual and unique assets to the
            table so that we can collaboratively align them to yield a whole
            that is greater than the sum of our individual parts.
          </p>
          <p>This Is Cohado</p>
        </div>
      </div>
    </>
  );
};
