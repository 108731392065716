import { getLastSelectedBone } from "../ControlPalette/setLastSelectedBone";
import { setPointPosition } from "../PlatformControl/useDragPlatBlock";
import { useTouchHooks } from "../ThreeUtills/useTouchHooks";

let mainScene = null;
export const getMainScene = () => {
  return mainScene;
};

export const CohadoBoardMesh = (props) => {
  const { children, boardRotation, togglePlatformControl } = props;

  const { ref, onPointerDown, onPointerMove, onPointerUp } = useTouchHooks();

  return (
    <mesh
      name="board"
      position={[0, 0, 0]}
      onDoubleClick={(event) => {
        if (!getLastSelectedBone()) {
          setPointPosition(event);
          togglePlatformControl();
        }
      }}
      onPointerDown={(event) => onPointerDown(event, true)}
      onPointerMove={(event) => onPointerMove(event, true)}
      onPointerUp={onPointerUp}
      ref={(eventRef) => {
        if (eventRef && eventRef.parent) {
          mainScene = eventRef.parent;
          ref.current = eventRef;
        }
      }}
      rotation={[0, boardRotation, 0]}
    >
      <boxGeometry
        args={[
          8, // 8.1, // 5.7,
          0,
          6, //5.1 // 6.3
        ]}
      />
      <meshBasicMaterial
        opacity={0}
        transparent
        // color="#bfbfbf"
      />
      {children}
    </mesh>
  );
};
