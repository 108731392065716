import interact from 'interactjs';


export const initActionBarVertSlide = () => {
  // target elements with the "draggable" class
  interact('.actionslide')
    .draggable({
      // enable inertial throwing
      inertia: true,
      // keep the element within the area of it's parent
      modifiers: [
        // interact.modifiers.restrictRect({
        //   restriction: 'parent',
        //   endOnly: true
        // })
      ],
      // enable autoScroll
      autoScroll: true,

      listeners: {
        // call this function on every dragmove event
        move: dragMoveListener,

        // call this function on every dragend event

      }
    })
};





function dragMoveListener(event) {
  var target = event.target
  // keep the dragged position in the data-x/data-y attributes
  var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx
  var y = (parseFloat(target.getAttribute('data-y')) || 0) //+ event.dy

  const rect = target.getBoundingClientRect()

  const bottomCutOff = -(rect.height - 20)

  const hitBottom = y < bottomCutOff
  if (hitBottom) {
    y = bottomCutOff
  }

  if (y > 0) {

    y = 0
  }

  if (y < -30) {

    x = x - event.dx
  }


  const cutOff = -(rect.width - (rect.width / 8))

  if (x < cutOff) {

    x = cutOff
  }

  if (x > (window.innerWidth - (window.innerWidth / 8))) {

    x = (window.innerWidth - (window.innerWidth / 8))
  }


  // translate the element
  target.style.transform = 'translate(' + x + 'px, ' + y + 'px)'

  // update the posiion attributes
  target.setAttribute('data-x', x)
  target.setAttribute('data-y', y)
}

