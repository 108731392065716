import { useEffect, useRef, useState, useMemo, useCallback } from "react";
import * as THREE from "three";
import { getLastSpot } from "../HexGrid/lastSpot";
import { pickMeshIntersect } from "../ThreeUtills/grabPlaneIntect";
import { RotateTarget } from "./RotateTarget";
import { updateParent } from "./updateParent";
import { GlyphModel } from "./GlyphModel";
import { BaseModel } from "./BaseModel";
import { useFrame } from "@react-three/fiber";
import { setLastTouched } from "../ThreeUtills/useTouchHooks";
import { updateMHXR, hideMHXR } from "../HexGrid/MoveHex";
import { getLastDropStates } from "../useThreehadoStates/calculateBoneStateNumbers";
import { getGlyphPositions } from "./getGlyphPositions";
import { AltAxisMeshWrapper } from "./AltAxisMeshWrapper";
import {
  getLastSelectedBone,
  hideJoyStick,
  setJoyStickBone,
  setLastSelectedBone,
} from "../ControlPalette/setLastSelectedBone";
import { altAxisRotation } from "./altAxisRotation";
import { vectorDiffRotation, calcGridROtaion } from "./getGridCoords";
import { resetAltAxis } from "./AltAxisMeshWrapper/rotateAltAxis";
import { getLocalFeature } from "../../KeyMenu/localFeatureConfig";
// import { nextFlash } from "../ActionBar";

export const BoneMesh = (props) => {
  const {
    mode,
    bone,
    onDrop: sendDropState = () => {},
    active = true,
    onPick = () => {},
    setShowPalette,
  } = props;

  // const neRef = useMemo(() => new THREE.Group(), [bone])

  const [neRef, setNeref] = useState();

  const ref = useRef();
  useEffect(() => {
    if (ref && ref.current) {
      setNeref(ref.current);
    }
  }, [ref]);

  const { thang1, thang2 } = bone;

  const rotateBone = useCallback(
    (rotationPointEventWithFinalRad) => {
      const { finalRad } = rotationPointEventWithFinalRad;
      neRef.rotation.y = finalRad;
    },
    [neRef]
  );

  const resetPosition = useCallback(() => {
    if (bone && neRef) {
      const firstX = bone.initPosition.xGridPoint; //+ .078;
      const firstz = bone.initPosition.zGridPoint; // - .223;

      updateParent(neRef, bone.parentId);

      neRef.position.set(firstX, neRef.position.y, firstz);
      rotateBone({ finalRad: bone.initPosition.rotation });
    }
  }, [neRef, bone, rotateBone]);

  useEffect(() => {
    resetPosition();
  }, [resetPosition]);

  // sharebone pick
  // useFrame(() => {
  //   if (active && neRef) {
  //     const shouldTurn = (neRef.rotation.z > Math.PI / 6 && (mode === 'firstPick' || mode === 'canPick'));//
  //     if (shouldTurn) {
  //       neRef.rotation.z = neRef.rotation.z - Math.PI / 6;
  //     }
  //     if (!shouldTurn && neRef.rotation.z !== 0 && mode !== 'firstPicWait') {
  //       neRef.rotation.z = 0;
  //       neRef.position.y = 0;
  //     }
  //     if (neRef.scale.x > 0 && mode === 'firstPicWait') {
  //       neRef.scale.set(0, 0, 0)
  //     }
  //   }
  //   if (neRef && !active && neRef.rotation.z !== Math.PI) {
  //     neRef.rotation.z = Math.PI;
  //     neRef.position.y = 0.05;
  //   }
  // });

  useFrame(() => {
    if (active && neRef) {
      const shouldTurn = neRef.rotation.z > Math.PI / 6; //
      if (shouldTurn) {
        neRef.rotation.z = neRef.rotation.z - Math.PI / 6;
      }
      if (!shouldTurn && neRef.rotation.z !== 0) {
        neRef.rotation.z = 0;
        neRef.position.y = 0;
      }
    }
    if (neRef && !active && neRef.rotation.z !== Math.PI) {
      neRef.rotation.z = Math.PI;
      neRef.position.y = 0.05;
    }
  });

  useEffect(() => {
    if (neRef) {
      neRef.active = active;
    }
  }, [active, neRef]);

  const getScaledFLooredPosition = useCallback(
    (worldPositionFromEvent) => {
      const me = neRef;
      const { parent } = me;
      if (parent) {
        const mat = new THREE.Matrix4();
        mat.makeRotationY(-parent.rotation.y);
        worldPositionFromEvent.applyMatrix4(mat);
        const { x, z } = worldPositionFromEvent;

        const xScaleFactor = parent.scale.x || 1;
        const shiftedX = x - parent.position.x;
        const scaledX = shiftedX / xScaleFactor;

        const zScaleFactor = parent.scale.z || 1;
        const shiftedZ = z - parent.position.z;
        const scaledZ = shiftedZ / zScaleFactor;
        const scaledPoistion = new THREE.Vector3(scaledX, 0, scaledZ);
        return scaledPoistion;
      }
      const scaledPoistion = new THREE.Vector3(0, 0, 0);
      return scaledPoistion;
    },
    [neRef]
  );

  const getPositionUpdate = useCallback(() => {
    if (neRef && bone.boneKey) {
      const { position, rotation } = neRef;

      const { x, y, z } = position;
      const update = {
        boneKey: bone.boneKey,
        position: {
          x,
          y: 0,
          z,
        },
        rotation: {
          _y: rotation.y,
        },
        boneListItem: bone,
      };

      return update;
    }
  }, [neRef, bone]);

  const onDropPosition = useCallback(
    (position, rotation, isDragging, shouldDrop) => {
      if (isDragging) {
        neRef.position.set(
          position.x,
          shouldDrop ? 0 : neRef.position.y,
          position.z
        );
      }
      neRef.rotation.y = rotation;
      hideMHXR();
    },
    [neRef]
  );

  const setPosition = useCallback(
    (worldPositionFromEvent) => {
      if (ref.current && ref.current.isDragging) {
        const me = neRef;
        const { x, y, z } = getScaledFLooredPosition(worldPositionFromEvent);
        me.position.set(x, 0.25, z);


        if(getLocalFeature("arrangeBones")){

        const parent = ref.current.parent.name;

        if (parent === "platform") {

          const handList = ref.current.parent.children

          const sortedHand = handList.sort(
            (a, b) => a.position.x - b.position.x
          );

          sortedHand.forEach((sBone, i) => {
            if (sBone.name !== ref.current.name) {
              const x = i * 0.22 - (1.45 - 0.11 * (14 - handList.length));
              const off = Math.abs(sBone.position.x - x);
              if (off > 0) {
                sBone.position.set(x, 0, 0);
              }
            }
          });

        }
      }
        updateMHXR(neRef, {
          rotation: neRef.rotation.y,
          position: neRef.position,
        });
      }
    },
    [neRef, ref, getScaledFLooredPosition]
  );

  const onDrop = useCallback(
    (props = {}) => {
      const { isSpinDrop } = props;

      const me = neRef;
      const { parent = {} } = me;

      const { parentId } = bone;

      if (parent !== null && ref.current) {
        ref.current.isDragging = false;

        switch (parent.name) {
          case "board": {
            const lastpack = getLastSpot();
            const gridRotation = calcGridROtaion(neRef.rotation.y);

            const dropPosition = {
              x:
                lastpack.xGridPoint || lastpack.xGridPoint === 0
                  ? lastpack.xGridPoint
                  : neRef.position.x,
              z:
                lastpack.zGridPoint || lastpack.zGridPoint === 0
                  ? lastpack.zGridPoint
                  : neRef.position.z,
            };

            const glyphs = getGlyphPositions({
              bonePosition: dropPosition,
              boneRotation: gridRotation,
              thang1,
              thang2,
            });
            const lastDropStates = getLastDropStates();

            const conflicts = glyphs.filter(
              (glyph) =>
                lastDropStates[glyph.dropstateKey] &&
                lastDropStates[glyph.dropstateKey].boneKey != glyph.boneKey
            );

            if (conflicts.length) {
              return;
            }

            onDropPosition(dropPosition, gridRotation, !isSpinDrop, true);
            // hideJoyStick();
            setLastSelectedBone(undefined);
            const dropPack = {
              parentId: "board",
              stateParentId: bone.parentId,
              boneKey: bone.boneKey,
              glyphs,
              getPositionUpdate,
              resetPosition,
              setPosition,
              boneListItem: bone,
            };
            sendDropState(dropPack);
            setShowPalette(false);

            return;
          }
          case "platform": {
            neRef.position.set(neRef.position.x, 0, neRef.position.z);
            const dropPack = {
              platform: true,
              parentId: "platform",
              stateParentId: bone.parentId,
              boneKey: bone.boneKey,
              glyphs,
              getPositionUpdate,
              setPosition,
              boneListItem: bone,
              resetPosition,
            };
            sendDropState(dropPack);
            return;
          }

          default:
            break;
        }
      }
    },
    [
      bone,
      neRef,
      getPositionUpdate,
      setPosition,
      resetPosition,
      thang1,
      thang2,
      ref,
      sendDropState,
      setShowPalette,
    ]
  );

  const rotateOnDragPosition = useCallback(
    (rotationPointEvent) => {
      const { worldPositionFromEvent, raidOff, drop } = rotationPointEvent;

      if (drop) {
        if (neRef.parent.name === "board") {
          resetAltAxis({
            neRef,
            boneKey: bone.boneKey,
          });
        }

        onDrop({ isSpinDrop: true });

        if (neRef.parent.name !== "board") {
          // if (false) {
          const gridRotation = calcGridROtaion(neRef.rotation.y);
          neRef.rotation.y = gridRotation;
        }
      } else if (
        ref.current &&
        ref.current !== null &&
        ref.current.isDragging
      ) {
        ref.current.isDragging = true;

        if (neRef.parent.name === "board") {
          // if (false) {
          altAxisRotation({ neRef, bone, worldPositionFromEvent, raidOff });
        } else {
          const scaledPoistion = getScaledFLooredPosition(
            worldPositionFromEvent
          );
          const freeRad = vectorDiffRotation(neRef.position, scaledPoistion); //   getDragRotation(scaledPoistion, neRef);
          const finalRad = freeRad + raidOff;
          updateMHXR(neRef, { rotation: finalRad, position: neRef.position });
          rotateBone({ finalRad });
        }
      }
    },
    [neRef, onDrop, ref, getScaledFLooredPosition, rotateBone, bone]
  );

  const touchPack = useMemo(() => {
    const setParent = (event) => {
      const me = neRef;
      const { isDragging } = ref.current || {};
      if (isDragging) {
        const pick = pickMeshIntersect(event, [
          "board",
          "platform",
          "wellPlatform",
        ]);
        const oldParentRotation = me.parent.rotation.y || 0;
        if (pick) {
          const nextParent = updateParent(me, pick.object.name);
          if (nextParent) {
            me.rotation.y =
              me.rotation.y - pick.object.rotation.y + oldParentRotation;
          }
        }
      }
    };

    return {
      boneKey: bone.boneKey,
      setParent,
      onDrop,
      setPosition,
    };
  }, [neRef, ref.current, setPosition, onDrop, bone]);

  const onPointerDown = useCallback(() => {
    if (active) {
      ref.current.isDragging = true;
      setLastTouched(touchPack);
    }
  }, [active, touchPack, setLastTouched, ref]);

  const onBoneDoubleClick = useCallback(
    (glyphData) => {
      if (active) {
        const dubpac = { touchPack, neRef, glyphData };
        setJoyStickBone({ ...dubpac, setShowPalette });
      }
    },
    [active, touchPack, neRef, setShowPalette]
  );

  const glyphs = useMemo(() => {
    return [
      {
        glyphKey: `glyph${bone.thang2.points}`,
      },

      {
        glyphKey: `glyph${bone.thang1.points}`,
      },
    ];
  });

  const enableRotateMarkers = useMemo(
    () => mode !== "firstPick" && mode !== "firstPickWait",
    [mode]
  );

  //share alt const canPick = useMemo(() => mode === "canPick" || mode == "firstPick", [mode])

  return (
    <group
      ref={ref}
      key={`boneMesh-${bone.boneKey}`}
      scale={0.016}
      name={`boneMesh-${bone.boneKey}`}
      onPointerDown={(event) => {
        event.stopPropagation();

        const lastSelectedBone = getLastSelectedBone();

        if (lastSelectedBone) {
          return;
        }

        if (active) {
          if (mode !== "firstPick") {
            onPointerDown();
          }
        } else {
          onPick();
        }

        // share bone
        // if (active && mode !== "firstPick") {
        //   onPointerDown();
        // } else if (canPick) {
        //   onPick();
        // }
      }}
    >
      <AltAxisMeshWrapper bone={bone}>
        {glyphs.map((glyph, i) => {
          const glyphSpin = i ? -1 : 1;

          return (
            <GlyphModel
              boneKey={bone.boneKey}
              onPointerDown={(event) => {
                // nextFlash.fire()
                const lastSelectedBone = getLastSelectedBone();

                if (lastSelectedBone) {
                  event.stopPropagation();
                  onBoneDoubleClick({
                    glyphSpin,
                    glyph,
                    single: true,
                    ref: event.eventObject,
                  });
                }
              }}
              onDoubleClick={(event) => {
                onBoneDoubleClick({ glyphSpin, glyph, ref: event.eventObject });
              }}
              key={i}
              glyph={glyph}
              glyphSpin={glyphSpin}
            >
              {enableRotateMarkers ? (
                <RotateTarget
                  spin={i ? 0 : 1}
                  parentId={bone.boneKey}
                  onRotatePosition={rotateOnDragPosition}
                  onPointerDown={() => {
                    if (ref && ref.current) {
                      ref.current.isDragging = true;
                    }
                  }}
                />
              ) : (
                <></>
              )}
            </GlyphModel>
          );
        })}
        <BaseModel boneKey={bone.boneKey} />
      </AltAxisMeshWrapper>
    </group>
  );
};

// const calcGridROtaion = (bone) => {
//   const radcoord = bone.rotation.y / (60 * (Math.PI / 180));
//   const radsmooth = Math.round(radcoord);
//   const radGridPoint = radsmooth * (60 * (Math.PI / 180));
//   return radGridPoint;
// };
