import firebase from "firebase";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCwOAS5wWTVAlHNikoTK-vfvUTfO-7fg0w",
  authDomain: "big-hado.firebaseapp.com",
  projectId: "big-hado",
  storageBucket: "big-hado.appspot.com",
  messagingSenderId: "508572604537",
  appId: "1:508572604537:web:8c726d15b663907de6dca8",
  measurementId: "G-T1N459BFPT",
};

firebase.initializeApp(firebaseConfig);

const firestore = firebase.firestore();
const settings = {
  timestampsInSnapshots: true,
};
firestore.settings(settings);
const gamesCollectionRef = firestore.collection("cloudGames");
const auth = firebase.auth();

const getCurrentUser = () => {
  try {
    const user = auth.currentUser.toJSON();

    const createdAt = user.createdAt;

    if (user && user != null) {
      const { uid, email } = user;
      const userPack = {
        email,
        id: uid,
        createdAt,
      };

      return userPack;
    }
  } catch (error) {
    console.log("🚀 ~ file: index.js:49 ~ getCurrentUser ~ error:", error);
  }
};

export { firebase, auth, firestore, getCurrentUser, gamesCollectionRef };

export default firebase;