import { hideCursor, moveCursor } from "../../Cursors";
import { getLiveCursorConfig } from "../../ThreeUtills/getLiveCursorConfig";
import { getCurrentUser } from "../../firebase";
import { sendRTMmessage } from "./initAgoraRTM";

const glyphPlayerIndex = {};

export const setGlyphPlayerIndex = (list) =>
  list.forEach((player, i) => (glyphPlayerIndex[player.id] = i + 1));

export const onLiveCursorMessage = (liveCursorMessage) => {
  if (getLiveCursorConfig().showOtherCursors) {
    const { scaledX, scaledZ, user } = liveCursorMessage;

    const glyphNumber = glyphPlayerIndex[user];
    moveCursor({
      glyphNumber,
      x: scaledX,
      z: scaledZ,
    });
  }
};

export const sendHideCursor = () => {
  const { id } = getCurrentUser();
  const glyphNumber = glyphPlayerIndex[id];

  hideCursor({glyphNumber})

  sendRTMmessage({ msg: JSON.stringify({ type: "hideCursor", glyphNumber }) });
};

export const hideAllCursors = () => {
  new Array(7).fill({}).forEach((a, i) => {
    hideCursor({ glyphNumber: i + 1 });
  });
};

const myCursorMoves = [];

const mover = () => {

  setTimeout(() => {
    if (myCursorMoves.length) {
      moveCursor({
        ...myCursorMoves.shift(),
      });
      mover();
    }
  }, 2)

}


export const moveMyCursor = (props) => {

  const { scaledX, scaledZ, user } = props;

  const glyphNumber = glyphPlayerIndex[user];

  moveCursor({
    glyphNumber,
    x: scaledX,
    z: scaledZ
  })

};