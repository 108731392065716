import { quotes } from "../ActionBar/quotes";
import { calculateBoneStateNumbers } from "./calculateBoneStateNumbers";
const getMatchBros = (props) => {
  const { glyph, addTo, dropStates, group } = props;

  const { touchMatches = [] } = glyph;
  touchMatches.forEach((element) => {
    const inGroup = group.matches.find(
      (mt) => mt.dropstateKey === element.dropstateKey
    );

    if (!inGroup) {
      const fullWithTOuchMatches = dropStates[element.dropstateKey];

      addTo(element);

      getMatchBros({
        glyph: fullWithTOuchMatches,
        dropStates,
        group,
        addTo,
      });
    }
  });
};

export const addCreatedAssets = (props) => {
  let { glyph, matchGroups = {}, dropStates = {}, glyphToGroup = {} } = props;
  const { key, boneKey, dropstateKey, points } = glyph;
  const glyphId = `${key}-${dropstateKey}_${boneKey}`;

  const group = {
    glyphId,
    matches: [{ key, boneKey, dropstateKey, points }],
    points,
  };
  glyphToGroup[glyphId] = glyphId;
  const addTo = (match) => {
    const tmId = `${match.key}-${match.dropstateKey}_${match.boneKey}`;
    delete matchGroups[tmId];
    glyphToGroup[tmId] = glyphId;
    group.matches.push(match);
  };

  getMatchBros({
    glyph,
    dropStates,
    group,
    addTo,
  });
  matchGroups = {
    ...matchGroups,
    [glyphId]: group,
  };

  return {
    matchGroups,
    glyphToGroup: {
      ...glyphToGroup,
    },
  };
};

export const isCohadoDrop = (props) => {
  const { glyphToGroup, dropStates, boneStates = []} = props;

  const bonelist = Object.keys(boneStates).map(
    (boneKey) => boneStates[boneKey]
  );

  const latestDropedBone = bonelist.reduce(
    (lastDroped, boneState) => {
      if (
        (boneState.injectionNumber || 0) > (lastDroped.injectionNumber || 0)
      ) {
        return boneState;
      }
      return lastDroped;
    },
    { glyphs: [], injectionNumber: 0 }
  );

  const glyphsWithDropState = latestDropedBone
    ? latestDropedBone.glyphs.map((glyph) => dropStates[glyph.dropstateKey])
    : [];


  if (
    latestDropedBone &&
    bonelist.length > 2
  ) {

    const pastState = {
      ...boneStates
    };

    delete pastState[latestDropedBone.boneKey];

    const pastStateNumber = calculateBoneStateNumbers(pastState);

    const localGroups = glyphsWithDropState.reduce(
      (locals, glyphWithDropState) => {
        const { touchMatches = [] } = glyphWithDropState;

        const outTouches = touchMatches.filter(
          (touchMatch) => touchMatch.boneKey !== latestDropedBone.boneKey
        );

        const touchLocals = outTouches.reduce(
          (glocals, touchMatch) => {
            const tmId = `${touchMatch.key}-${touchMatch.dropstateKey}_${touchMatch.boneKey}`;

            const touchMatchMatchGroup = glyphToGroup
              ? glyphToGroup[tmId]
              : undefined; // glyphToGroup[tmId];

            const glocal = glocals[touchMatchMatchGroup] || {
              id: touchMatchMatchGroup,
              tms: {},
            };

            glocal.tms[tmId] = touchMatch;

            return {
              ...glocals,
              [touchMatchMatchGroup]: {
                ...glocal,
              },
            };
          },
          {
            ...locals,
          }
        );

        return {
          ...locals,
          ...touchLocals,
        };
      },
      {}
    );
    console.log(
      "🚀 ~ file: addCreatedAssets.js:125 ~ localGroups ~ localGroups",
      localGroups
    );

    const localList = Object.keys(localGroups).map((key) => localGroups[key]);

    const allPastGroups = localList.reduce((pastMatchGroups, localGroup) => {
      const { tms } = localGroup;

      const pastGroups = Object.keys(tms)
        .map((key) => tms[key])
        .reduce(
          (ptouchgroups, touch) => {
            const tmId = `${touch.key}-${touch.dropstateKey}_${touch.boneKey}`;

            const pastGroup = pastStateNumber.glyphToGroup[tmId];

            return ptouchgroups.includes(pastGroup)
              ? ptouchgroups
              : [...ptouchgroups, pastGroup];
          },
          [...pastMatchGroups]
        );
      return [...pastGroups];
    }, []);

    console.log(
      "🚀 ~ file: addCreatedAssets.js:155 ~ allPastGroups ~ allPastGroups",
      allPastGroups
    );
    return allPastGroups.length > 1;
  }
};

export const getGroupList = ({ matchGroups }) =>
  Object.keys(matchGroups).map((gid) => matchGroups[gid]);
