import moment from "moment";
import { gamesCollectionRef, roundSetsCollectionRef } from "../firebase";
import { createNewGame } from "./createNewGame";

export const handleNextRound = async (props) => {
  const { refGame = {} } = props;

  const { roundData = {} } = refGame;
  const { roundSetId: refGameRoundSetId, nextGameId } = roundData;

  if (nextGameId) {
    return goToNextGame({ nextGameId });
  }

  const roundSetUpdate = await addGameToRoundSet({ refGame });
  const { rounds = [], id: roundSetId } = roundSetUpdate;
  const roundIndex = rounds.length;

  const nextRoundData = {
    roundSetId,
    roundIndex,
  };

  const { allPlayers = [] } = refGame;

  const roundPlayers = [...allPlayers];
  const nextCloudGame = await createNewGame({ nextRoundData, roundPlayers });

  const gameRoundDataUpdate = !refGameRoundSetId
    ? {
        roundSetId,
        roundIndex: 0,
      }
    : roundData;

  await gamesCollectionRef.doc(refGame.id).update({
    roundData: {
      ...gameRoundDataUpdate,
      nextGameId: nextCloudGame.id,
    },
  });

  goToNextGame({ nextGameId: nextCloudGame.id });

};

const goToNextGame = (props) => {
  const {nextGameId} = props;
  const nextGameLink = `${window.location.origin}?gameId=${nextGameId}`;
  window.location.href = nextGameLink;
};

const buildRoundSummary = (props) => {
  const { refGame = {} } = props;

  const {
    cohadoStates = {},
    playerHands,
    pointSections,
    initialHands,
    coe = 0,
    beats = 0,
    turns = [],
  } = refGame;

  const { boneStates } = cohadoStates;

  const {
    assets = { total: 0 },
    waste = { total: 0 },
    una = { total: 0 },
  } = pointSections;

  const thisStoredRound = {
    playerHands,
    pointSections,
    boneStates,
    initialHands,
    coe,
    sectionSummary: {
      assets: assets.total,
      waste: waste.total,
      una: una.total,
    },
    beats,
    turns,
  };

  return { thisStoredRound };
};

const getGameRoundSet = async ({ roundSetId }) => {
  if (roundSetId) {
    const doc = await roundSetsCollectionRef.doc(roundSetId).get();

    const data = doc.data();

    const roundSetData = {
      ...data,
      id: doc.id,
    };
    return roundSetData;
  } else {
    const newRoundSet = await createNewRoundSet();

    return newRoundSet;
  }
};

const addGameToRoundSet = async (props) => {
  try {
    const { refGame = {} } = props;

    const thisStoredRound = buildRoundSummary({ refGame });

    const { roundData = {} } = refGame;

    const { roundSetId } = roundData;

    const roundSetData = await getGameRoundSet({ roundSetId });

    const { rounds = [] } = roundSetData;

    const roundIndex = rounds.length;
    thisStoredRound.roundIndex = roundIndex;
    const roundsUpdate = [...rounds, thisStoredRound];

    const roundSetUpdate = {
      ...roundSetData,
      rounds: roundsUpdate,
    };

    await saveRoundUpdate({ roundSetUpdate });

    return roundSetUpdate;
  } catch (error) {
    console.log(
      "🚀 ~ file: handleNextRound.js:97 ~ addGameToRoundSet ~ error:",
      error
    );
  }
};

const createNewRoundSet = async () => {
  try {
    const stamp = moment();

    const stampMill = stamp.valueOf();
    const stampDate = stamp.format("lll");
    const roundSetData = {
      rounds: [],
      stampMill,
      stampDate,
    };
    const doc = await roundSetsCollectionRef.add(roundSetData);
    return {
      ...roundSetData,
      id: doc.id,
    };
  } catch (error) {
    console.log(
      "🚀 ~ file: handleNextRound.js:117 ~ createNewRoundSet ~ error:",
      error
    );
  }
};

const saveRoundUpdate = async (props) => {
  try {
    const { roundSetUpdate } = props;

    const { id, ...data } = roundSetUpdate;

    await roundSetsCollectionRef.doc(roundSetUpdate.id).update({
      ...data,
    });
  } catch (error) {
    console.log(
      "🚀 ~ file: handleNextRound.js:127 ~ saveRoundUpdate ~ error:",
      error
    );
  }
};
