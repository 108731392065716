import { blobPromise } from "./blobPromise";

export const getBoardCanvasMiniClone = async (props = {}) => {
  try {
    const { height = 500, width = 500 } = props;
    const canvas = document.getElementById("canvas").children[0].children[0];

    const clone = copyToCanvasTosize({ canvas, height, width });

    return clone;
  } catch (error) {
    console.log(
      "🚀 ~ file: canvasImage.js:23 ~ getBoardCanvasMiniClone ~ error:",
      error
    );
  }
};

const copyToCanvasTosize = (props) => {
  try {
    const { height = 500, width = 500, canvas } = props;

    const cloneCanvas = document.createElement("canvas");
    cloneCanvas.height = height;
    cloneCanvas.width = width;
    const ctx = cloneCanvas.getContext("2d");
    ctx.drawImage(canvas, 0, 0, 500, 500);
    return cloneCanvas;
  } catch (error) {
    console.log(
      "🚀 ~ file: canvasImage.js:36 ~ copyToCanvasTosize ~ error:",
      error
    );
  }
};

export const getBoardCanvasMiniCloneImageData = async (props = {}) => {
  const { height = 500, width = 500 } = props;

  try {
    const clone = await getBoardCanvasMiniClone({ height, width });

    const cloneImgBlob = await blobPromise(clone);
    const cloneImagObjectUrl = URL.createObjectURL(cloneImgBlob);

    const cloneDataUrl = clone.toDataURL();
    return {
      clone,
      cloneImgBlob,
      cloneImagObjectUrl,
      cloneDataUrl,
    };
  } catch (error) {
    console.log(
      "🚀 ~ file: canvasImage.js:23 ~ getBoardCanvasMiniCloneImageData ~ error:",
      error
    );
  }
};

export const getBoardCanvasImageData = async () => {
  try {
    const canvas = document.getElementById("canvas").children[0].children[0];

    const boardCanvasImgBlob = await blobPromise(canvas);
    const boardImgObjectUrl = URL.createObjectURL(boardCanvasImgBlob);

    const boardCanvasDataUrl = canvas.toDataURL();
    return {
      canvas,
      boardCanvasImgBlob,
      boardImgObjectUrl,
      boardCanvasDataUrl,
    };
  } catch (error) {
    console.log(
      "🚀 ~ file: canvasImage.js:23 ~ getBoardCanvasImageData ~ error:",
      error
    );
  }
};
