const glyphs = [
    'revealed-7.png',
    'eye-1.png',
    'relationship-2.png',
    'thewomb-3.png',
    'structures-4.png',
    'manifesting-5.png',
    'community-6.png',
    // 'revealed-7.png',
]

export const convertElementToHalfBones = (domElement) => {

    const glyphsDoms = [...domElement.getElementsByClassName('hexo')];
    const glyphs = glyphsDoms.map(glyphDom => {
        const [color] = [...glyphDom.parentNode.getElementsByClassName('half-bone')];

        const { src } = color;
        const [key, points] = src
            .split('/')
            .reverse()[0]
            .split('.')[0]
            .split('-');
        return {
            glyphDom,
            src,
            key,
            points: parseInt(points),
        };
    });

    return glyphs
}

export const getBoneCombos = () => {

    const combos = glyphs.reduce((combos, half, top, all) => {

        const localCombos = all.map((inhalf) => [inhalf, half]
            .sort())
            .map((raybone) => {
                const boneKey = raybone.join('+')
                return {
                    [boneKey]: raybone
                }
            })
            .reduce((localCombos, lc) => {
                return {
                    ...localCombos,
                    ...lc,
                }
            })

        return {
            ...combos,
            ...localCombos
        }

    }, {})

    return combos
}

export const getElementCenter = (bonedom) => {
    const bonerect = bonedom.getBoundingClientRect();
    // may need to detech rotation here
    //  console.log("getElementCenter -> bonedom", [...bonedom.getElementsByClassName('bone')][0].style.transform)
    const { x, y, height, width } = bonerect

    const center = {
        x: x + (width / 2),
        y: y + (height / 2),
    }


    return {
        ...center
    }
}


export const addRawCoordinate = (glyph) => {

    const { glyphDom, ...data } = glyph
    const currentTargetRect = glyphDom.getBoundingClientRect();

    const { x, y, height, width } = currentTargetRect

    const r1x = x + (width / 2)
    const r1y = y + (height / 2)


    return {
        ...data,
        rawX: r1x,
        rawY: r1y,
        height,
        width,
        glyphDom,

    }
}

export const geHalfBoneCoordinates = (glyph, mainCoordinate) => {

    const { rawX, rawY, glyphDom } = glyph

    const height = glyphDom.parentElement.parentElement.parentElement.getBoundingClientRect().height / 2

    const xDiff = rawX - mainCoordinate.x;
    const yDiff = rawY - mainCoordinate.y;

    const xGrid = (height / 2) * Math.sin(60 * (Math.PI / 180))

    const xCoord = xDiff / (xGrid * 2)

    const yCoord = (yDiff / height) * 2

    const xsmooth = Math.round(xCoord)

    const ysmooth = Math.round(yCoord)

    const dropstateKey = `${xsmooth}-${ysmooth}`;

    return {
        ...glyph,
        dropstateKey,
        xCoord,
        yCoord,
        xsmooth,
        ysmooth,
    };
};
