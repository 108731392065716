import { getNeighborCells } from "./getNeighborCells";
export const addtouchMatches = (glyphs, dropStates) => {

    return glyphs.map((half) => {
        const { xsmooth, zsmooth, dropstateKey, key, boneKey: thisBoneKey } = half;

        const allNe = getNeighborCells({ xsmooth, zsmooth });
        const netouches = allNe.filter(nepoint => {
            const { dropstateKey } = nepoint;
            return dropStates[dropstateKey];
        });


        const touchKeys = netouches.map(netouch => {
            const { dropstateKey } = netouch;
            const netouchdropState = dropStates[dropstateKey]

            return {
                key: netouchdropState.key,
                dropstateKey,
                boneKey: netouchdropState.boneKey,
                points: netouchdropState.points
            };
        })


        const badTouches = touchKeys
            .filter(nedropstate => {
                const isMatch = (nedropstate.key === key);
                const boneMatch = thisBoneKey === nedropstate.boneKey;
                const badTouch = !isMatch && !boneMatch
                return badTouch;
            });


        const touchMatches = touchKeys
            .filter(nedropstate => {
                const isMatch = (nedropstate.key === key);
                return isMatch;
            });

        const newDropState = { half, allNe, key, boneKey: thisBoneKey, touchMatches, points: half.points };
        dropStates[dropstateKey] = { ...newDropState };
        return {
            ...half,
            allNe,
            touchMatches,
            badTouches,
        };
    });
};

export const purgeBoneFromState = (boneState, stateUpdate = {}) => {
    if (boneState && boneState.glyphs) {
        boneState.glyphs
            .map((stateDrop) => {
                const oldDropState = stateUpdate[stateDrop.dropstateKey];
                return oldDropState;
            })
            .filter((oldDropState) => oldDropState)
            .map((oldDropState) => {
                const { touchMatches: myTouchMatches = [] } = oldDropState;
                myTouchMatches.map((myTouchMatch) => {
                    const oldState = stateUpdate[myTouchMatch.dropstateKey];
                    if (oldState) {
                        stateUpdate[myTouchMatch.dropstateKey] = {
                            ...oldState,
                            touchMatches: (oldState.touchMatches || [])
                                .filter(touch => touch.dropstateKey !== oldDropState.dropstateKey)
                        };
                    }
                });
                delete stateUpdate[oldDropState.dropstateKey];
                return oldDropState;
            });
    }
    return stateUpdate;
};
