import { useEffect } from 'react';
import interact from 'interactjs';

let currentPlatform;

const platforms = {}

export const setCurrentPlatform = (key) => {
    if (!currentPlatform) {
        currentPlatform = platforms[key]
    } else {
        const nextPlatform = platforms[key] || {}
        if (currentPlatform.uuid !== nextPlatform.uuid) {

            if (currentPlatform.name === nextPlatform.name) {

                const oldProps = {
                    ...currentPlatform.userData
                }

                currentPlatform = platforms[key];

                currentPlatform.userData.selectedX = oldProps.selectedX
                currentPlatform.userData.selectedY = oldProps.selectedY

                updateCurrentPlatformZoom(oldProps.selectedScale || 0)
            } else {
                currentPlatform = platforms[key];
            }
        }

    }

}


export const registerPlatform = (key, platform) => {
    platforms[key] = platform

}


export const setPointPosition = (event) => {

    // const mouse = {};
    // mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    // mouse.y = - (event.clientY / window.innerHeight) * 2 + 1;

    const [one] = document.getElementsByClassName('plat-block')
    if (one) {
        const oneRect = one.getBoundingClientRect()
        var x = (parseFloat(one.getAttribute('data-x')) || 0) + (event.clientX - oneRect.left) - (oneRect.width/2)
        var y = (parseFloat(one.getAttribute('data-y')) || 0) + (event.clientY - oneRect.top) - (oneRect.height/2)

        // translate the element
        one.style.transform = 'translate(' + x + 'px, ' + y + 'px)'
        console.log("🚀 ~ file: useDragPlatBlock.js:58 ~ setPointPosition ~ one", one)

        // update the posiion attributes
        one.setAttribute('data-x', x)
        one.setAttribute('data-y', y)


    }
    // updateCurrentPlatform(props)
}

const updateCurrentPlatform = (props) => {

    const { x, y } = props;
    const unitX = x / (window.innerWidth / 2)
    const unitY = y / (window.innerHeight / 2)

    if (currentPlatform) {
        currentPlatform.userData.selectedX = 6 * (unitX / 10);
        currentPlatform.userData.selectedY = -6 * (unitY / 10);
    }
}

export const updateCurrentPlatformZoom = (scaleUpdate) => {
    if (currentPlatform) {
        currentPlatform.userData.selectedScale = (currentPlatform.userData.selectedScale || 0) + scaleUpdate
        currentPlatform.scale.set(currentPlatform.scale.x + scaleUpdate, currentPlatform.scale.y + scaleUpdate, currentPlatform.scale.z + scaleUpdate)
    }
}

export const useDragPlatBlock = (props) => {

    useEffect(() => {

        interact('.plat-block')
            .draggable({
                listeners: {
                    move: dragMoveListener,
                },
                inertia: true,
            })

        return () => {
            interact('.plat-block').unset();
        };
    }, []);

};

function dragMoveListener(event) {
    var target = event.target
   
    const movebar = event.target.className.indexOf('movebar')>-1

    if(movebar){
        target = target.parentNode
    }

    if(!movebar){
    // keep the dragged position in the data-x/data-y attributes
    var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx
    var y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy


    var x2 = (parseFloat(target.getAttribute('data-x2')) || 0) + event.dx
    var y2 = (parseFloat(target.getAttribute('data-y2')) || 0) + event.dy

    // translate the element
    target.style.transform = 'translate(' + x + 'px, ' + y + 'px)'

    // update the posiion attributes
    target.setAttribute('data-x', x)
    target.setAttribute('data-y', y)


    target.setAttribute('data-x2', x2)
    target.setAttribute('data-y2', y2)

        updateCurrentPlatform({
            eventRect: event.rect,
            x:x2,
            y:y2,
        })

    }else{
        setPointPosition(event)
    }

}

