
import { useObservableState } from 'observable-hooks'
import { ReplaySubject } from 'rxjs'

export const animationState$ = new ReplaySubject()

export const useAnimationState = () => {
    const animationState = useObservableState(animationState$, undefined)
    return animationState
};

export const updateAnimationState = (animationState) => {
    animationState$.next(animationState)
}

