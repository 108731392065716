import interact from 'interactjs';


export const initInterActConfig = () => {
  interact('.resize-drag')
    .resizable({
      // resize from all edges and corners
      edges: { left: true, right: true, bottom: true, top: true },
      listeners: {
        move(event) {
          var target = event.target;
          var x = (parseFloat(target.getAttribute('data-x')) || 0);
          var y = (parseFloat(target.getAttribute('data-y')) || 0);
          // update the element's style
          target.style.width = event.rect.width + 'px';
          target.style.height = event.rect.height + 'px';

try {
  

          const [streamScroll] = target.getElementsByClassName('streams-scroll')

          const streamShape = streamScroll ?  streamScroll.className.indexOf('vert-shape') > -1 ? 'vert' : 'wide' : ''

          if (event.rect.width > event.rect.height && streamShape ==='vert' ) {

            streamScroll.className = streamScroll.className.replace('vert-shape','')
          } else if( event.rect.width < event.rect.height && streamShape ==='wide' ) {

            streamScroll.className = 'vert-shape '+ streamScroll.className

          }
        } catch (error) {
  
        }
          // translate when resizing from top or left edges
          x += event.deltaRect.left;
          y += event.deltaRect.top;
          target.style.transform = 'translate(' + x + 'px,' + y + 'px)';
          target.setAttribute('data-x', x);
          target.setAttribute('data-y', y);
        }
      },
      modifiers: [
        // keep the edges inside the parent
        interact.modifiers.restrictEdges({
          outer: 'parent'
        }),
        // minimum size
        interact.modifiers.restrictSize({
          min: { width: 100, height: 50 }
        })
      ],
      inertia: true
    })
    .draggable({
      listeners: { move: window.dragMoveListener },
      inertia: true,
      modifiers: [
        // interact.modifiers.restrictRect({
        //   restriction: 'parent',
        //   endOnly: true
        // })
      ]
    });
};
