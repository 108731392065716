import React from "react";
import { useLoadWholeHex } from "./useLoadWholeHex";
import { dubYOffset, diameter } from "../hexContants";

export const WholeHexModel = (props) => {
  const wholeHexglb = useLoadWholeHex();
  const {
    pos,
    // dropGrid 
  } = props;

  return (
    <primitive
      scale={0.2}
      position={[
        diameter,
        0,     // dropGrid ? -0.1 : 0,
        -dubYOffset / 2
      ]}
      rotation={[0, -90 * (Math.PI / 180), 0]}
      object={wholeHexglb}
    />
  );
};
