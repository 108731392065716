
export const getDeviceList = async () =>{

    //  const devices = await AgoraRTC.getCameras()
    const devices = await navigator.mediaDevices.enumerateDevices();

    const videoDevices = devices.filter(
      (device) => device.kind === "videoinput"
    );

    return videoDevices
}