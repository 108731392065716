
const glyphs = [
  'revealed-7.png',
  'eye-1.png',
  'relationship-2.png',
  'thewomb-3.png',
  'structures-4.png',
  'manifesting-5.png',
  'community-6.png',
];

export const getBoneCombos = () => {
  const combos = glyphs.reduce((combos, half, top, all) => {
    const localCombos = all.map((inhalf) => [inhalf, half]
      .sort())
      .map((raybone) => {
        const boneKey = raybone.join('+');
        return {
          [boneKey]: raybone
        };
      })
      .reduce((localCombos, lc) => {
        return {
          ...localCombos,
          ...lc,
        };
      });
    return {
      ...combos,
      ...localCombos
    };
  }, {});
  return combos;
};

const stringToThing = (strang) => {
  const [key, points] = strang
    .replace('.png', '')
    .split('-');
  const thang = {
    key,
    points: parseFloat(points),
    strang
  };
  return thang;
};

export const initRandomBones = () => {
  const combos = getBoneCombos();
  const bones = Object.keys(combos).map(combo => {
    const [h1, h2] = combo.split('+');
    const thang1 = stringToThing(h1);
    const thang2 = stringToThing(h2);
    const boneKey = `${thang1.key}-${thang2.key}`;
    return {
      combo,
      thang1: {
        ...thang1,
        boneKey
      },
      thang2: {
        ...thang2,
        boneKey
      },
      boneKey
    };
  }).sort(() => Math.random() - 0.5);
  return bones;
};