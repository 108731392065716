import { firestore } from "./";

export const getLiveQuotes = async () => {

    const snaps = await firestore.collection('communityQuotes').get();

    const docs = snaps.docs;

    const mapped = docs.map((doc) => {

        const data = doc.data()
        const { quoteAuthor: author, quoteText: text } =data

        return {
            ...data,
            id: doc.id,
            author,
            text,
        }
    })

    return [...mapped]
};
