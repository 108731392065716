import { useSummaryCardData } from "../ThreeHado/firebase/getSummaryCardData";
import "../DashBoard/style.css";
import "./cardPage.css";
import { BoardImgContainer } from "./BoardImgContainer";
import { SummaryScoreBoard } from "./SummaryScoreBoard";
import { themeColors } from "../../themeColors";

export function CardPage() {
  const { summaryCardData = {} } = useSummaryCardData();

  return summaryCardData === null ? (
    <> Loading</>
  ) : (
    <div
      style={{
        position: "relative",
      }}
      className="h-100 card-page w-100 d-flex flex-column align-items-center"
    >
      <div
        style={{
          zIndex: 1,
        }}
        className="card-frame"
      >
        <img
          className="summary-card-back-img h-100 m-0"
          src="/images/card_background_1.png"
          alt=""
        />
        <div className="card-content h-100 w-100 d-flex flex-column align-items-center">
          <BoardImgContainer boardImage={summaryCardData.cloneDataUrl} />
          <SummaryScoreBoard summaryCardData={summaryCardData} />

          <div className="players-section">
            <p className="mt-2 mb-0 text-danger">Players:</p>

            <div className="d-flex mb-2 flex-wrap players-bay">
              {summaryCardData.allPlayers.map((player,i) => {
                const { email } = player;
                return (
                  <p
                    key={i}
                    className="mx-1"
                    style={{
                      color: `#${themeColors.lightTan}`,
                    }}
                  >
                    {email},
                  </p>
                );
              })}
            </div>
          </div>
          <div className="flex-1"/>

          <div className="players-section">
            <div>
              <p>
                <span className="text-danger me-1">Created: </span>
                <span className="greh-lite">{summaryCardData.stampFormat}</span>
              </p>
            </div>
            <p>
              <span className="text-danger me-1">CODE: </span>
              <span className="greh-lite">{summaryCardData.gameId}</span>
            </p>
          </div>

          <div className="flex-1"/>
          <img
            style={{
              height: 100,
              position: "relative",
            }}
            src="/images/card_cohado_label.png"
            alt=""
          />
        </div>
      </div>
    </div>
  );
}
