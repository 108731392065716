import "../MainPage/style.css";
import { useEffect, useState, useMemo } from "react";
import moment from "moment";
import { useGetFeedback } from "./useGetFeedback";
import { useHistory, Link } from "react-router-dom";
import { CalendarModal, todayStart, todayEnd } from "./CalendarModal";

export function Feedback() {
  const {
    comFeedback = [],
    setFeedbackForGameId,
    getFeedBackbyDateRange = () => { },
  } = useGetFeedback();

  const history = useHistory();

  const gameId = useMemo(
    () => !!new URLSearchParams(history.location.search).get("gameId"),
    [history]
  );

  useEffect(() => {
    if (gameId) {
      setFeedbackForGameId(gameId);
    }
  }, [gameId]);

  const [dateRange, setDateRange] = useState({
    start: todayStart,
    end: todayEnd,
  });

  const updateDateRange = (range) => {
    setDateRange(range);

    if (window.location.search) {
      window.location.search = "";
    }
  };

  useEffect(() => {
    if (!gameId) {
      getFeedBackbyDateRange(dateRange);
    }
  }, [dateRange, gameId]);

  return (
    <div
      style={{
        backgroundColor: "black",
      }}
      className="h-100 main-page"
    >
      <div className="topbar d-flex align-items-center">
        <button
          onClick={() => {
            const myModal = new window.bootstrap.Modal(
              document.getElementById("calendarmodal"),
              {
                keyboard: true,
              }
            );
            myModal.show();
          }}
          className="m-1 btn btn-light"
        >
          search date range
        </button>

        <p

          style={{ fontSize: 24, color: "white" }}

        >
          {moment(dateRange.start).format("LL")} -{" "}
          {moment(dateRange.end).format("LL")}
        </p>
      </div>

      <div
        className="flex-1"
        style={{
          overflow: "scroll",
        }}
      >
        {comFeedback.map((feedback) => {
          return (
            <div
              className="border-bottom p-3"
              style={{ fontSize: 24, color: "white" }}
              key={feedback.id}
            >
              <p>{feedback.feedBackText}</p>
              <p>{feedback.email}</p>
              <p>{feedback.date}</p>
            </div>
          );
        })}
      </div>
      <CalendarModal onRangeUpdate={updateDateRange} />
    </div>
  );
}
