import { useState } from "react";

export const ZeroLevelCollapse = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <p className="key-menu-item fw-bolder">
        {/* Play */}

        <img
          onClick={() => {
            setOpen(!open);
          }}
          data-bs-toggle="collapse"
          data-bs-target=".play-collapse"
          aria-expanded="false"
          aria-controls="play-collapse"
          src="images/closemenuarrow.png"
          style={{
            position: "relative",
            width: 25,
            marginRight: 6,
            transition: "rotate .5s ease",
            rotate: `${open ? "180deg" : "0deg"}`,
          }}
        />
        <span
          onClick={() => {
            setOpen(!open);
          }}
          data-bs-toggle="collapse"
          data-bs-target=".play-collapse"
          aria-expanded="false"
          aria-controls="play-collapse"
        >
          The Level Zero Game
        </span>
      </p>
      <div
        className="collapse info-collapse play-collapse sub-collapse"
        id="play-collapse"
      >
        <div className="card card-body">
          {/* <h1></h1> */}
          <p>
            The puzzle that is Cohado is very simple. Work together to match up
            all of the symbols. It's picture matching. You can only connect like
            symbols. Three or more of the same symbols touching adds value,
            leaving any symbol hanging alone creates waste. If only two symbols
            are touching, their power has not been activated, and thus does not
            contribute to the score. The basic level learning game in Cohado is
            a Zero-Sum game, meaning the whole is equal to the sum of the parts.
            If we add all of the weights of 28 pieces in the Level Zero game,
            the sum is 224.
          </p>

          <p>
            In this first level of the game, players work together to get as
            close to that sum as possible by contributing their pieces to the
            whole, paying attention to what others contribute, and aligning the
            pieces to ensure that every cluster of symbols has at least three of
            the same symbol touching. If there are only two touching, they have
            not been activated, and thus do not contribute to the score or COE,
            they are not waste, just potential. The COE represents how
            coefficiently the players have worked together with the resources
            they have been given.
          </p>

          <p>
            The value of leaving no one hanging is powerfully represented by
            what are called Beats in Cohado. If the community does not ensure
            that each player can play in each turn, the COMMUNITY catches what
            is called a Beat. This represents a missed turn, meaning the game
            was not completed in 28 moves. At the end of the game the weight of
            any symbols left hanging alone, untouched by another like symbol,
            are multiplied by the Beats, and added to the waste. This can have
            an exponentially negative impact on the COE.
          </p>

          <p>Leave no one, or nothing hanging!</p>
        </div>
      </div>
    </>
  );
};
