import { vectorDiffRotation } from "./getGridCoords";
import { rotateAltAxis } from "./AltAxisMeshWrapper/rotateAltAxis";
import { Vector3 } from "three";

export const altAxisRotation = (props) => {
  const { neRef, worldPositionFromEvent, bone, raidOff } = props;
  const altAxis = neRef.children.find((item) => {
    return item.name == `${bone.boneKey}-alt-axis`;
  });

  const axis = raidOff === 0 ? 1 : -1;

  let rotationPoint = new Vector3();

  altAxis.getWorldPosition(rotationPoint);

  const freeRad = vectorDiffRotation(
    rotationPoint,
    worldPositionFromEvent
  );

  const finalRad = freeRad + raidOff - neRef.rotation.y;

  rotateAltAxis({
    neRef,
    radians: finalRad,
    axis,
    boneKey: bone.boneKey,
  });

  //  resetAltAxis({ neRef, boneKey });
};
