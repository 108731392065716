import React, { useEffect, useRef, useState, useMemo } from "react";
import { Bone } from "./Bone";
import { initPlayerWindowDrag,getLastRec } from "./usePlayerWindowDrag";

 initPlayerWindowDrag()


export const AgoraStreamerWindow = (props) => {
  const {
    playerNumber = 7,
    playerStream = {},
    localHandBones = [],
    email = "",
    showHand,
    setShowHand = () => { },
    isPlayersTurn,
    screenName ='',
    isMyStream,
    turnOnCam = () => { },
    turnOnMic = () => { },
  } = props;

  const [muted, setMuted] = useState(false);

  const videoRef = useRef();

  const { videoTrack, audioTrack } = playerStream;

  const videoTrackStamp = useMemo(() => {
    return videoTrack ? videoTrack.stamp : undefined;
  }, [playerStream]);

  useEffect(() => {
    console.log(
      "🚀 ~ file: AgoraStreamerWindow.js:21 ~ useEffect ~ videoTrack",
      videoTrack
    );
    console.log(
      "🚀 ~ file: AgoraStreamerWindow.js:21 ~ useEffect ~ videoRef",
      videoRef
    );
    if (videoRef && videoTrack) {
      try {
        videoTrack.play(videoRef.current);
      } catch (error) {
        console.log(
          "🚀 ~ file: AgoraStreamerWindow.js ~ ~ useEffect  videoTrack.play ~ error",
          error
        );
      }
    }
  }, [videoTrackStamp, videoRef]);

  useEffect(() => { 
    console.log(
      "🚀 ~ file: AgoraStreamerWindow.js:36 ~ useEffect ~ audioTrack",
      audioTrack
    );

    if (audioTrack && !isMyStream) {
      try {
        audioTrack.play();
      } catch (error) {
        console.log(
          "🚀 ~ file: AgoraStreamerWindow.js ~ ~ useEffect    audioTrack.play() ~ error",
          error
        );
      }
    }
  }, [audioTrack, isMyStream]);

  useEffect(() => {
    if (audioTrack) {
      audioTrack.setVolume(muted ? 0 : 100);
    }
  }, [audioTrack, muted]);

  const onVideoClick = () => {
    setMuted(!muted);
  };

  return (
    <div className="streamer-window overflow-hidden  ">
      <div
        style={{
          backgroundImage: isMyStream
            ? "url(/images/goldtrap.png)"
            : "url(/images/silvertrap.png)",
          backgroundSize: "100% 100%",
          backgroundColor: "transparent",
          width: "100%",
          maxWidth: "100%",
          overflow: "hidden",
          height: 30,
          flex: 'none'
        }}
      >
        <div
          style={{
            position: "absolute",
            backgroundColor: "transparent",
            width: "100%",
            height: "100%",
          }}
        >
          <p
            style={{
              position: "relative",
              backgroundColor: "transparent",
              overflow: "hidden",
              textDecoration: "ellipsis",
              margin: "auto",
              width: "80%",
            }}
          >
            {screenName || email}
          </p>
        </div>
      </div>
      {/*
       <div
        style={{
          backgroundImage: "url(/images/lilbonebar.png)",
          backgroundSize: "100% 100%",
        }}
        onClick={() => {
          setShowHand(!showHand);
        }}
        className="playerhand"
      >
        <div
          onClick={() => {
            setShowHand(!showHand);
          }}
          style={{
            backgroundImage: `url(/images/${playerNumber}s.png)`,
            backgroundSize: "40%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundColor: "tranparent",
            backgroundColor: "white",
            opacity: showHand ? 0.1 : 0.3,
          }}
          className="h-100 w-100 position-absolute"
        />
        {(showHand || isMyStream) && (
          <>
            {localHandBones.map((bone) => {
              return <Bone grey={isMyStream && !showHand} key={bone.combo} bone={bone} />;
            })}
          </>
        )}
      </div> 
      */}

      <div
        style={{
          flex: 1,
          position: "relative",
          minHeight: 0
        }}
      >
        {/* <div
          className="h-100 w-100 position-absolute"
          style={{
            top: 0,
            left: 0,
            backgroundImage: `url(/images/${playerNumber}s.png)`,
            backgroundSize: "40%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundColor: "#2a2c2c",
          }}
        /> */}
        <div
          style={{
            backgroundSize:'contain',
            backgroundPosition:'center',
            backgroundRepeat:'no-repeat',
            backgroundImage: `url(/images/${playerNumber}s.png)`,
            backgroundColor: "#2a2c2c",
            // opacity: muted ? 0.6 : 1,
            height:getLastRec().height,
            width:getLastRec().width,
            position: "relative",
          }}
          // onClick={onVideoClick}
          // autoPlay
          ref={videoRef}
          className="streamer-vid playerwindow-drag"
        />
      </div>

      <div
        style={{
          position:'relative',
          flex:'none',
          backgroundImage: `url(/images/${playerNumber}_little_colour_bar.png)`,
          backgroundSize: "100% 100%",
          width: "100%",
          height: 40,
        }}
        className="playerActions d-flex justify-content-between align-items-center"
      >
        {isMyStream && (
          <img
            onClick={turnOnCam}
            className="position-relative"
            src={
              videoTrack
                ? "images/videotv_icon_on.png"
                : "images/videotv_icon_off.png"
            }
            style={{
              margin: "auto",
              height: 25,
            }}
            alt=""
          />
        )}

        {/* {isMyStream && (
          <img
            className="position-relative"
            src="images/videotv_icon_notworking.png"
            style={{
              margin: "auto",
              height: 25,
            }}
            alt=""
          />
        )} */}

        {/* <img
          className="position-relative"
          src={
            isPlayersTurn ? "images/button_on_g.png" : "images/button_off.png"
          }
          style={{
            margin: "auto",
            top: isPlayersTurn ? -7 : 0,
            height: isPlayersTurn ? 50 : 30,
          }}
          alt=""
        /> */}
{/* 
        {isMyStream && (
          <div
            style={{
              width: 30,
              margin: "auto",
            }}
          />
        )} */}
        
        {isMyStream && (
          <img
            onClick={turnOnMic}
            className="position-relative"
            src={
              audioTrack
                ? "images/microphon_on.png"
                : "images/microphon_off.png"
            }
            style={{
              margin: "auto",
              height:  audioTrack
              ? 35
              : 25
            }}
            alt=""
          />
        )}
      </div>
    </div>
  );
};
