const cohadoHeight = 300;

const ratio = 1152 / 648;

const cohadoWidth = cohadoHeight * ratio;

export const CohadoAlert = () => {
  return (
    <div
      className="modal fade"
      id="cohadoAlert"
      data-bs-keyboard="true"
      tabIndex="-1"
      aria-labelledby="cohadoAlertTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable cohado-alert">
        <div
          className="modal-content"
          style={{
            backgroundImage: "url(/images/CohadoCelebration2b.gif)",
            backgroundSize: "100% 100%",
            height: cohadoHeight,
            width: cohadoWidth,
          }}
        >
          <div
            style={{
              height: 66,
            }}
            className="modal-header"
          >
            {/* <h5 className="modal-title" id="cohadoAlertTitle">
              !!COHADO!!
            </h5> */}
          </div>
          <div
            className="modal-body d-flex flex-column align-items-center">
            <p
              style={{
                fontFamily: "oatmilk",
                fontSize: 30,
                textAlign: "center",
                lineHeight: '30px'
              }}
              id="cohadobridgequote"
            >
              {/* thats alot of words
              thats alot of words
              thats alot of words
              thats alot of words
              thats alot of words
              thats alot of words
              thats alot of words
              thats alot of words
              thats alot of words
              thats alot of words
              thats alot of words
              thats alot of words */}
            </p>
            <p
              style={{
                fontFamily: "oatmilk",
                fontSize: 30,
                textAlign: "center",
              }}
            // id="cohadobridgequote"
            >
              -  <i id="cohadobridgeauthor"></i> -
            </p>
          </div>
          <div
            style={{
              marginTop: 12
            }}
            className="d-flex justify-content-center">
            <img
              style={{
                margin: cohadoHeight / 25,
                height: cohadoHeight / 10,
              }}
              src="/images/CohadoX.png"
              type="button"
              data-bs-dismiss="modal"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
