import React, { useState } from "react";
import moment from "moment";
import "react-calendar/dist/Calendar.css";

import Calendar from "react-calendar";
export const todayStart = moment()
  .startOf("day")
  .subtract(1, "week")
  .valueOf();
export const todayEnd = moment().endOf("day").valueOf();

export const CalendarModal = (props) => {
  const [start, setStart] = useState(todayStart);

  const [end, setEnd] = useState(todayEnd);

  const { onRangeUpdate = () => {} } = props;

  return (
    <div
      className="modal fade"
      id="calendarmodal"
      aria-labelledby="calendarmodalLabel"
      aria-hidden="true"
    >
      <div
        className="modal-dialog"
        style={{
          maxWidth: 600,
        }}
      >
        <div className="modal-content">
          <div className="modal-header"></div>
          <div className="modal-body">
            <div className="d-flex">
              <Calendar
                onChange={(value) => {
                  setStart(moment(value).startOf("day").valueOf());
                }}
                value={new Date(start)}
              />
              <Calendar
                value={new Date(end)}
                onChange={(value) => {
                  setEnd(moment(value).endOf("day").valueOf());
                }}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              data-dismiss="modal"
              type="button"
              className="btn btn-primary"
              onClick={() => {
                if (start < end) {
                  onRangeUpdate({ start, end });
                }
              }}
            >
              set dates
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
