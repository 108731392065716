const lastPoints = { }

export const setLastPoint = (props) => {

    const { glyphNumber, x, z } = props
    lastPoints[glyphNumber] = { x, z }
}

export const getPointerRad = (props) => {
    const { glyphNumber, x, z } = props
    const lastPoint = lastPoints[glyphNumber];

    if (lastPoint) {

        try {

            const xdiff = ((lastPoint.x / 1) - (x / 1))

            const zdiff = ((lastPoint.z / 1) - (z / 1))

            const out = Math.PI - Math.atan2(-zdiff, -xdiff)

            return { out }
        } catch (error) {
            console.log("🚀 ~ file: getPointerRad.js:51  ~ error:", error)
        }

    }


    return { out: 0 }
}