import { useState, useMemo } from 'react'
import './scoreBoard.css'
const pointLabelMap = [
  "SEVEN: Realized",
  "SIX: Community",
  "FIVE: Manifesting",
  "FOUR: Structure",
  "THREE: Innovation",
  "TWO: Relationship",
  "ONE: The Individual",
  // 'ZERO: Pure Spirit'
];

export const ScoreBoardWrapper = (props) => {
  const { children = <></> } = props;

  const [open, setOpen] = useState(null);

  const doorsClass = useMemo(() => {

    switch (open) {
      case true:
        return ' open-door'
      case false:
        return ' close-door'
      default:
        return ''
    }


  }, [open])

  return (
    <div
      onClick={() => {
        setOpen(!open)
      }}
      onDoubleClick={() => {
        if (open) {
          const myModal = new window.bootstrap.Modal(document.getElementById('scoreSummary'), {
            keyboard: false
          });
          myModal.show()
        }
      }}
      className="point-section-summary mb-2 position-relative unselectable"
      style={{ overflow: "visible", marginRight: 24, top: 6 }}
    >
      <div className="score-container" style={{ overflow: "visible", zIndex: 10 }}>
        {children}
      </div>

      <div
        className="position-absolute h-100 w-100 d-flex justify-content-center align-items-center"
        style={{ left: 0, top: 0, zIndex: 100, overflow: "visible" }}
      >
        <img
          src="./images/scoreDoors.png"
          style={{
            position: "relative",
            height: "137%",
            width: "105%",
          }}
        />
      </div>

      <div
        className="position-absolute w-100 d-flex"
        style={{ height:'102%', left: 0, top: 0, zIndex: 200, overflow: "hidden" }}
      >
        <img
          className={`score-door-a${doorsClass}`}
          src="./images/door1.png"
          style={{
            position: "relative",
            top: "0%",
            height: "100%",
            width: "50%",
            marginLeft: 1
          }}
        />

        <img
          className={`score-door-b${doorsClass}`}
          src="./images/door2.png"
          style={{
            position: "absolute",
            top: "0%",
            height: "100%",
            width: "50%",
          }}
        />
      </div>

    </div>
  );
};
