import { themeColors } from "../../themeColors";

export const getPlayerDisplayColor = (props) => {

  const { player } = props;

  const { isInvitedData, isWatcher } = player;

  if (isWatcher) {
    return `#${themeColors.greh}`
  }

  return isInvitedData ? `#${themeColors.tan}` : `#${themeColors.lightTan}`;
};
