import { useState } from "react";
import {
  useDragPlatBlock,
  updateCurrentPlatformZoom,
} from "./useDragPlatBlock";

export const PlatformControl = (props) => {
  useDragPlatBlock();
  const { showPlatformControl, togglePlatformControl = () => {} } = props;

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      style={{
        zIndex: showPlatformControl ? 989 : 0,
        backgroundImage: "url(/images/bonebar_popup_3.png)",
        backgroundColor: "transparent",
        backgroundSize: "100% 100%",
        // zIndex: 10,
        // backgroundColor: "black",
        height: 100,
        width: 300,
        position: "absolute",
      }}
      className="plat-block d-flex justify-content-between"
    >
      <div
        style={{
          top: -5,
        }}
        className="h-25 movebar position-absolute w-100 plat-block"
      >
        <div
          style={{
            backgroundColor: "transparent",
            margin: "auto",
          }}
          className="h-100 w-50"
        ></div>
      </div>
      <div
        onClick={() => {
          updateCurrentPlatformZoom(0.01);
        }}
        style={{ width: 70, fontSize: 48 }}
        className="btn m-0 btn-sm text-light d-flex justify-content-center align-items-center"
      >
        {/* + */}
      </div>
      <div
        onClick={() => {
          togglePlatformControl();
        }}
        className="closecontrol"
        style={{
          position: "relative",
          // border: "2px solid black",
          height: 30,
          width: 30,
          top: "70%",
        }}
      ></div>
      <div
        onClick={() => {
          updateCurrentPlatformZoom(-0.01);
        }}
        style={{ width: 70, fontSize: 48 }}
        className="btn m-0 btn-sm text-light d-flex justify-content-center align-items-center"
      >
        {/* - */}
      </div>

      {/* <div
        style={{
          bottom: -5,
        }}
        className="h-25 movebar position-absolute w-100 plat-block"
      >
        <div
          style={{
            backgroundColor: "#2a2c2c",
            margin: "auto",
          }}
          className="h-100 w-50"
        ></div>
      </div> */}
    </div>
  );
};
