export const BoardImgContainer = (props) => {
  const { boardImage } = props;

  return (
    <div className="boardimgcontainer">
      <div
        style={{
          position: "relative",
          height: 401,
          width: 574,
          display: "flex",
          justifyContent: "center",
          overflow: "visible",
        }}
      >
        <img
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            zIndex: 1,
          }}
          src="./images/02_solid_color.png"
          alt=""
        />
        <div
          style={{
            borderRadius: "16%",
            position: "relative",
            width: "100%",
            height: "100%",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            style={{
              position: "relative",
              width: "99%",
              height: "100%",
              zIndex: 5,
            }}
            src={boardImage}
            alt=""
          />
        </div>

        <img
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            zIndex: 10,
          }}
          src="./images/card_frame.png"
          alt=""
        />
      </div>
    </div>
  );
};
