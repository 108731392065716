export let lastSpot = {};

export const getLastSpot = () => {
  return lastSpot;
};

export const setlastSpot = (update) => {
  if(update){
   lastSpot ={
    ...update
   }
  }else{
    lastSpot = {}
  }
};



