import { gamesCollectionRef } from "../../firebase";

export const makeWatcher = async (clickedPlayerData) => {
  const { game, player } = clickedPlayerData;
  const { allPlayers = [] } = game;
  try {
    const inviteKey = player.email.split(".").join("-");
    const invitedData = game[inviteKey];
    const playerData = game[player.id];

    if (player.isInvitedData) {
      const playaPac = {
        ...(invitedData || {}),
        isWatcher: true,
      };

      const allPlayersUpdate = allPlayers.map((playa) => {
        if (playa.email === player.email) {
          return playaPac;
        }

        return playa;
      });

      const allEmails = allPlayersUpdate.map((player) => {
        return player.email;
      });

      await gamesCollectionRef.doc(game.id).update({
        [inviteKey]: {
          ...playaPac,
        },
        allEmails,
        allPlayers: allPlayersUpdate,
      });
    } else {
      const playaPac = {
        ...playerData,
        isWatcher: true,
      };
      const allPlayersUpdate = allPlayers.map((playa) => {
        if (playa.email === player.email) {
          return playaPac;
        }

        return playa;
      });

      const allEmails = allPlayersUpdate.map((player) => {
        return player.email;
      });

      await gamesCollectionRef.doc(game.id).update({
        [player.id]: {
          ...playaPac,
        },
        allEmails,
        allPlayers: allPlayersUpdate,
      });
    }
  } catch (error) {
    console.log("makeWatcher -> error", error);
  }
};
