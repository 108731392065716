const thankYouHeight = 300;

const thankyouratio = 1152 / 648;

const thankYouWidth = thankYouHeight * thankyouratio;

export const FeedBackThankYouModal = () => {
  return (
    <div
      className="modal fade"
      id="feedBackThankYou"
      data-bs-keyboard="true"
      tabIndex="-1"
      aria-labelledby="feedBackThankYouTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable cohado-alert">
        <div
          className="modal-content"
          style={{
            backgroundImage: "url(/images/CohadoCelebration2b.gif)",
            backgroundSize: "100% 100%",
            height: thankYouHeight,
            width: thankYouWidth,
          }}
        >
          <div
            style={{
              height: 80,
            }}
            className="modal-header d-flex justify-content-center"
          ></div>
          <div className="modal-body d-flex justify-content-center">
            <p
              style={{
                fontFamily: "Inkburrow",
                fontSize: 30,
                textAlign: "center",
              }}
            >
              Thank You for Contributing to the Community!!
            </p>
          </div>
          <div className="d-flex justify-content-center">
            <img
              style={{
                margin: thankYouHeight / 25,
                height: thankYouHeight / 10,
              }}
              src="/images/CohadoX.png"
              type="button"
              data-bs-dismiss="modal"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
