const pointLabelMap = [
  "SEVEN: Realized",
  "SIX: Community",
  "FIVE: Manifesting",
  "FOUR: Structure",
  "THREE: Innovation",
  "TWO: Relationship",
  "ONE: The Individual",
  // 'ZERO: Pure Spirit (For Next Level Game) We will discuss this in version 2.0'
];

export const PointSectionSummary = (props) => {
  const { pointSection } = props;

  const { groups = [], title, total } = pointSection;

  return (
    <div className="point-section-summary mb-2">
      <h5 className="m-0">
        <p className="section-title">{title}:</p>
      </h5>
      {groups
        .sort((a, b) => b.points - a.points)
        .map((group) => {
          const { glyphId = "", matches = [], points = 0 } = group;

          const fileName = `/images/${points}s.png`;

          return (
            <div key={glyphId} className="d-flex no-wrap border-bottom mb-1">
              <div className="glyph-name ">
                <p className="m-0 py-1 align-items-center d-flex">
                  <img
                    className="me-2"
                    style={{ height: 30 }}
                    src={fileName}
                    alt=""
                  />
                  <span className="group-title">
                    {pointLabelMap[7 - points]}
                  </span>
                </p>
              </div>
              <div className="group-count">
                <p> {matches.length}</p>
              </div>
              <div className="group-points">
                <p> {matches.length * points} </p>
              </div>
            </div>
          );
        })}
      <div className="d-flex no-wrap group-total">
        <div className="glyph-name"></div>
        <div className="group-count">
          <p>Total:</p>
        </div>
        <div className="group-points">
          <p className="">{total}</p>
        </div>
      </div>
    </div>
  );
};
