import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Twode from "./components/Two/Twode";
import { MainPage } from "./components/MainPage";
import { DashBoard } from "./components/DashBoard";
import { SignUp } from "./components/SignUp";
import { Cofile2 } from "./components/SignUp/Cofile2";
import "./theme.css";
import { UserRoute } from "./UserRoute";
import { ThreeHado } from "./components/ThreeHado";
import { Feedback } from "./components/Feedback";
import { CardPage } from "./components/CardPage";

// import { GameCursor2d } from "./components/GameCursor2d";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <UserRoute exact component={DashBoard} path={["/dash"]} />
          <UserRoute login exact component={MainPage} path={["/", "/login"]} />
          <UserRoute exact component={ThreeHado} path={["/three"]} />
          <UserRoute exact component={Twode} path={["/two"]} />
          <UserRoute exact component={Feedback} path={["/feedback"]} />
          <Route exact component={SignUp} path={["/sign-up"]} />
          <UserRoute exact component={Cofile2} path={["/cofile"]} />
          <Route exact component={CardPage} path={["/card"]} />
        </Switch>
      </Router>
      {/* <GameCursor2D/>*/}
    </div>
  );
}

export default App;
