import { useEffect } from 'react';
import interact from 'interactjs';

export const usePaletteDrag = (props) => {

    useEffect(() => {

        interact('.bone-move-control')
            .draggable({
                listeners: {
                    move: dragMoveListener,
                },
                inertia: true,
            })

        return () => {
            interact('.bone-move-control').unset();
        };
    }, []);

};

function dragMoveListener(event) {
    var target = event.target
   
    // keep the dragged position in the data-x/data-y attributes
    var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx
    var y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy


    var x2 = (parseFloat(target.getAttribute('data-x2')) || 0) + event.dx
    var y2 = (parseFloat(target.getAttribute('data-y2')) || 0) + event.dy

    // translate the element
    target.style.transform = 'translate(' + x + 'px, ' + y + 'px)'

    // update the posiion attributes
    target.setAttribute('data-x', x)
    target.setAttribute('data-y', y)


    target.setAttribute('data-x2', x2)
    target.setAttribute('data-y2', y2)

}

