import interact from "interactjs";
import { getBoundingClientRect } from "@dnd-kit/core";

let lastTargetRec;

 let mapPinchScale = 1;


export const useCohadoMapDrag = (props) => {
  interact(".map-drag")
    .resizable({
      edges: {
        left: true,
        right: true,
        bottom: true,
        top: true,
      },
      listeners: {
        move(event) {
          var target = event.target;
          var x = parseFloat(target.getAttribute("data-x")) || 0;
          var y = parseFloat(target.getAttribute("data-y")) || 0;
          // update the element's style
          const targetRec =
            lastTargetRec
              ? lastTargetRec
              : getBoundingClientRect(target);
          const dw = Math.abs(event.rect.width - targetRec.width);
          const dh = Math.abs(event.rect.height - targetRec.height);
          const widthMove = dw >= dh;
          const mapImage = document.getElementById("mapImage");
          if (widthMove) {
            mapImage.style.width = "100%";
            mapImage.style.height = "unset";
            target.style.width = event.rect.width + "px";
            target.style.height = "fit-content";
          } else {
            mapImage.style.height = "100%";
            mapImage.style.width = "unset";
            target.style.height = event.rect.height + "px";
            target.style.width = "fit-content";
          }
          lastTargetRec = {
            ...event.rect,
          };
          // x += event.deltaRect.left;
          // y += event.deltaRect.top;
          target.style.transform = "translate(" + x + "px," + y + "px)";
          target.setAttribute("data-x", x);
          target.setAttribute("data-y", y);
        },
      },

    })

    .gesturable({
      listeners: {
        move(event) {
          mapDragMoveListener(event);
        },
        end(event) {
          mapPinchScale = mapPinchScale * event.scale;
        },
      },
    })
    .draggable({
      listeners: { move: mapDragMoveListener },
    });
};

function mapDragMoveListener(event) {
  var target = event.target;

  var currentScale = event.scale
    ? event.scale * mapPinchScale
    : mapPinchScale;

  const scaleTranform = `scale(${currentScale})`;

  var x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx;
  var y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;

  const translate = "translate(" + x + "px, " + y + "px)";

  target.style.transform = `${translate} ${scaleTranform}`;

  target.setAttribute("data-x", x);
  target.setAttribute("data-y", y);
}
