import { useMemo } from 'react';
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { useLoader } from '@react-three/fiber';

const getGlyphAssetForKey = (props) => {
  const { glyphKey } = props;
  return `glbs/${glyphKey.replace('glyph','g')}.glb`
}

export const useLoadGlyph = (props) => {

  const { glyph } = props;

  const { glyphKey } = glyph;

  const glyphUrl = useMemo(() => getGlyphAssetForKey({ glyphKey }), [glyphKey])

  const glyphGlb = useLoader(GLTFLoader, glyphUrl)

  const baseGroup = useMemo(() => {
    const { scene } = glyphGlb
    return opForGlyphKey({ scene, glyphKey })
  }, [glyphGlb])

  return baseGroup;
};


const opForGlyphKey = (props) => {

  const { scene, glyphKey } = props

  const clone = scene.clone()

  switch (glyphKey) {
    default: {
      return clone
    }
  }

}