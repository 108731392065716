import { usePaletteDrag } from "./usePaletteDrag";
import { getGlyphType } from "../getGlyphSourceType";
import { rotate, onGlyphPress } from "./paletteUtils";
import { setJoyStickBone } from "./setLastSelectedBone";

const glyphs = new Array(6).fill(0);

export const ControlPalette = (props) => {
  usePaletteDrag();

  const { showPalette } = props;
  
  return (
    <div
      id="boneJoyStick"
      onClick={(e) => e.stopPropagation()}
      style={{
        zIndex: showPalette ? 989:0,
        backgroundColor: "transparent",
        height: 180,
        overflow: "visible",
        borderRadius: 30,
        width: 180,
        position: "absolute",
      }}
      className="bone-move-control unselectable d-flex justify-content-center align-items-center"
    >
      <div
        style={{
          backgroundColor: "transparent",
          zIndex: 1,
        }}
        className="position-absolute h-100 w-100 d-flex justify-content-center align-items-center"
      >
        <img
          style={{
            position: "relative",
            height: "120%",
            zIndex: 50,
          }}
          src="/images/joystick.png"
        />
      </div>
      <div
        id="joystick_glyph_panel"
        className="d-flex justify-content-center align-items-center"
        style={{
          left: 0,
          position: "absolute",
          height: "100%",
          width: "100%",
          top: 0,
          zIndex: 10,
        }}
      >
        <img
          onClick={() => {
             setJoyStickBone();
          }}
          className="position-absolute"
          style={{
            top: 150,
            left: 110,
            height: 50,
            zIndex: 10,
          }}
          src="/images/joystick_exit_tab.png"
        />
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            overflow: "visible",
            position: "relative",
            zIndex: 11,
          }}
        >
          <div
            style={{
              height: 0,
              width: 0,
              top: 0,
              left: 0,
              overflow: "visible",
            }}
            className="position-relative d-flex justify-content-center align-items-center"
          >
            <img
              onClick={() => {
                rotate(1);
              }}
              style={{
                width: 40,
                opacity: 0.6,
                position: "relative",
                transform: "rotateZ(-90deg)",
              }}
              src={getGlyphType(7, "colorsharp")}
            />

            <div
              style={{
                width: 3,
                flex: "none",
                position: "relative",
              }}
            />
            <img
              onClick={() => {
                rotate(-1);
              }}
              style={{
                width: 40,
                opacity: 0.6,
                position: "relative",
                transform: "rotateZ(90deg)",
              }}
              src={getGlyphType(7, "colorsharp")}
            />
          </div>
          {glyphs.map((glyph, angleIndex) => {
            const deg = angleIndex * 60;
            return (
              <div
              key={deg}
                className="d-flex justify-content-center align-items-center"
                style={{
                  left: 0,
                  overflow: "visible",
                  top: 0,
                  height: 0,
                  width: 0,
                  position: "absolute",
                  rotate: `${deg}deg`,
                }}
              >
                <img
                  onClick={() => {
                    onGlyphPress({ angleIndex });
                  }}
                  style={{
                    cursor: "pointer",
                    width: 50,
                    zIndex: 999,
                    top: 65,
                    position: "relative",
                    left: 0,
                    rotate: `${-deg}deg`,
                  }}
                  src={getGlyphType(angleIndex + 1, "colorsharp")}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
