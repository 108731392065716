import { useEffect, useState } from "react";
import { Route, Redirect, useLocation,/*useHistory*/ } from "react-router-dom";
import { auth, getCurrentUser } from "./firebase";
// import { getUserCoilfe } from "./components/SignUp/formatFormSubmitEvent";
// import moment from "moment";

// const oneDay = 86400000;

// const oneweek = oneDay * 7;

export const UserRoute = (props) => {
  const { login, ...routeProps } = props;
  const [user, setUser] = useState(null);

  // const history = useHistory();
  const location = useLocation();

  const { search } = location;

  useEffect(() => {
    auth.onAuthStateChanged(async () => {
      const user = getCurrentUser();

      if (user) {
        // const cofile = await getUserCoilfe({ userId: user.id });

        // const now = moment().valueOf();

        // const diff = now - user.createdAt;

        // const userIsNew = diff < oneweek;

        // if (!cofile && userIsNew) {
        //   history.push("/cofile");
        // }
      }
      setUser(user);
    });
  }, []);

  if (user === null) {
    return <></>;
  }
  if (user && user.id) {
    if (login) {
      if (search) {
        return <Redirect to={`/three${search}`} />;
      }

      return <Redirect to="/dash" />;
    } else {
      return <Route {...routeProps} />;
    }

    // return login ? < :
  }

  return login ? (
    <Route {...routeProps} />
  ) : (
    <Redirect to={`/login${search}`} />
  );
};
