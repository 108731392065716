import { isEqual } from 'lodash';

export const checkForUpdatedKeys = (old = {}, newData) => {

    const smushed = { ...old, ...newData };

    const updatedKeys = Object.keys(smushed).filter((key) => {

        const oldspot = old[key];

        const newspot = newData[key];

        const changed = !isEqual(oldspot, newspot);

        return changed
    })

    return updatedKeys
}