import "../MainPage/style.css";
import { useForm } from "react-hook-form";
import { useHistory, Link } from "react-router-dom";
import { auth } from "../../firebase";
import { getUserCoilfe } from "./formatFormSubmitEvent";

export function SignUp() {
  const { register, handleSubmit } = useForm();

  const history = useHistory();

  const onSignIn = async (user) => {
    if (user && user.uid) {
      history.push("/cofile");
      // const cofile = await getUserCoilfe({ userId: user.uid });
      // if (cofile) {
      //   history.push("/dash");
      // } else {
      //   history.push("/cofile");
      // }
    }
  };

  const onSubmit = async (data) => {
    const { email, password } = data;
    if (email && password) {
      try {
        const response = await auth.createUserWithEmailAndPassword(
          email,
          password
        );
        console.log("SignIn -> response", response);
        const { user } = response;
        console.log("SignIn -> user", user);
        onSignIn(user);
      } catch (error) {
        console.error("onSubmit -> error", error);
      }
    }
  };

  return (
    <div
      style={{
        backgroundImage: "url(/images/SignUp_Background3_tiled.png)",
        backgroundSize: "100% auto",
      }}
      className="h-100 main-page"
    >
      <div className="w-100 d-flex justify-content-center">
        <img
          style={{
            height: "4vw",
            margin: "50px 0",
          }}
          src="/images/welcometoCohado_text.png"
          alt=""
        />
      </div>

      <div
        style={{
          backgroundImage: "url(/images/paper01.png)",
          backgroundSize: "100% 100%",
          minWidth: 450,
          padding: "20px 40px",
        }}
        className="animate_up animated fadeInUp"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <input
            placeholder="Email"
            {...register("email")}
            type="text"
            className="form-control form-control-lg"
          />
          <input
            placeholder="Password"
            {...register("password")}
            type="password"
            className="form-control form-control-lg"
          />
          <div className="d-flex justify-content-around">
            <button type="submit" className="page-icon-button">
              <img
                className="page-icon-img"
                src="/images/SignUp_Icon.png"
                alt=""
              />
              <p>Sign Up</p>
            </button>

            <Link to="/login" className="page-icon-button">
              <img
                className="page-icon-img"
                src="/images/Back_Icon.png"
                alt=""
              />

              <p>Back</p>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
