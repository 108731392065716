import { useState, useEffect } from "react";
import { getDeviceList } from "./getDeviceList";
import {
  setVideoDeviceId,
  getLocalTrackConfig,
} from "../ThreeHado/ActionBar/VideoChatAgora/localTrackConfig";

export const StreamSettingsCollapse = () => {
  const [deviceList, setDeviceList] = useState([]);

  const [selectedDevice, setSelectedDevice] = useState(
    getLocalTrackConfig().cameraId
  );

  useEffect(() => {
    const go = async () => {
      const list = await getDeviceList();
      setDeviceList(list);
    };
    go();
  }, []);

  return (
    <>
      <p
        data-bs-toggle="collapse"
        data-bs-target=".streamsettings-collapse"
        aria-expanded="false"
        aria-controls="streamsettings-collapse"
        className="key-menu-item"
        onClick={() => { }}
      >
        Cam Settings
      </p>
      <div
        className="collapse info-collapse streamsettings-collapse"
        id="streamsettings-collapse"
      >
        <div className="card card-body">
          {deviceList.map((videoDevice,i) => {
            const { label } = videoDevice;
            return (
              <div
                key={i}
                style={{
                  cursor: 'pointer',
                  backgroundColor:
                    selectedDevice && selectedDevice === videoDevice.deviceId
                      ? "#2b1700"
                      : "transparent",
                }}
                onClick={() => {
                  setSelectedDevice(videoDevice.deviceId);
                  setVideoDeviceId(videoDevice.deviceId);
                }}
              >
                <span
                  style={{
                    color:
                      selectedDevice && selectedDevice === videoDevice.deviceId
                        ? "white"
                        : "#2b1700",
                  }}
                  className="text-start"
                >
                  {label || "Web Cam"}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
