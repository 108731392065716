import React from "react";
import { useLoadBase } from "./useLoadBase";
import { BaseModelLow } from "../BaseModelLow";
import { getLocalFeature } from "../../../KeyMenu/localFeatureConfig";

const lowFiv1 = getLocalFeature("lowFiv1");

export const BaseModelSL = (props) => {
  const baseglb = useLoadBase();
  return <primitive name={props.name} object={baseglb} />;
};

export const BaseModel = (props) => {
  return lowFiv1 ? (
    <BaseModelLow name={`${props.boneKey}-base`} />
  ) : (
    <BaseModelSL name={`${props.boneKey}-base`}  />
  );
};
