import { useState } from "react";
import { playAudio } from "./contentAudio";

const rootBonesParagraphs = [
  "Before there was anything, before Turtle Island/Creation/The Big Bang, there was nothingness, the ZERO. Everything was everything - pure Spirit. And she was content - yet, she could not know herself, as there was nothing else to perceive her - until the first thought-trace emerged, shattering the nothingness of everything into the everything we now call time and space - the universe. The thought that rose in the emptiness was the fundamental question - “What am I?”.",
  "This was the origin of the ONE. the “I”, or Eye - the individual viewpoint. The One suffered the same challenge that the Zero experienced - he could not see himself.",
  "This gave rise to otherness, the TWO - the lightness and dark, feminine and masculine, the inner and the outer, the yin and the yang, the binary.",
  "Yet, wherever the two is present, also present is that which arises from the union - the THREE. This is the WE. It is the womb which gives rise to the creative, conception, inspiration, intuition, the birthplace of all things - inception. These first four levels form the foundation of all that exists. But the Three cannot move - it needs a structure to dance upon.",
  "This is the FOUR - less like the four walls of a room - more like the four chambers of our hearts that move the blood about our body, or the four seasons that move the resources of the earth around the planet creating life, or the four axes of the solar system that move the planets about the sun. The four is the nature of things in movement. From this level we have all we need to create.",
  "The FIVE is similar to our bodies, forming the five boundaries of our reach like Leonardo da Vinci's Vitruvian Man, or like the five fingers of our hand that allow us to manifest.",
  "And like the spider's web, the fabric that weaves the underlying structure of all that is, the SIX is the wireframe of the universe - community, beginning with the elemental family that forms the connectivity of us all. If we weave our communities strong, like the nearsighted spider, we will feel where both the resources and the need reside, and we will be able to move the resources to the need to create healing and wellness for the community.",
  "This leads us to the SEVEN. The reflection of the nothingness of the Zero is the revelation of the Seven, that all of everything is the ONENESS. As a prism refracts the spectral colors of white light into the rainbow, the prism of time and space reveals the infinite viewpoints of what we think of as reality - each calling back to the Zero, “This is what you are!” The Seven is Spirit revealed through the struggle and splendor of existence in relationship to one another. It is also the point of reflection that completes the cycle, leading us back to the beginning - yet from a new vantage point of understanding.",
];

export const RootBonesCollapse = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <p className="key-menu-item fw-bolder">
        <img
          onClick={() => {
            setOpen(!open);
          }}
          data-bs-toggle="collapse"
          data-bs-target=".rootBones-collapse"
          aria-expanded="false"
          aria-controls="rootBones-collapse"
          src="images/closemenuarrow.png"
          style={{
            position: "relative",
            width: 25,
            marginRight: 6,
            transition: "rotate .5s ease",
            rotate: `${open ? "180deg" : "0deg"}`,
          }}
        />
        <span
          onClick={() => {
            setOpen(!open);
          }}
          data-bs-toggle="collapse"
          data-bs-target=".rootBones-collapse"
          aria-expanded="false"
          aria-controls="rootBones-collapse"
        >
          The Root Bones of Cohado
        </span>
        <img
          onClick={() => {
            playAudio("/audio/the_root__bones_final_no_fade.mp3");
          }}
          src="images/speaker_logo.png"
          className="playMenuAudio"
        />
      </p>
      <div
        className="collapse info-collapse rootBones-collapse sub-collapse"
        id="rootBones-collapse"
      >
        <div className="card card-body">
          {rootBonesParagraphs.map((rootBonesParagraphText, i) => {
            const fileName = `/images/${i > 0 ? i : 7}s.png`;

            return (
              <div key={i} className="d-flex">
                <img
                  className="me-2 my-0"
                  style={{
                    height: 36,
                    transform: i === 0 ? "rotate(180deg)" : "unset",
                  }}
                  src={fileName}
                />
                <p className="m-0">{rootBonesParagraphText}</p>
              </div>
            );
          })}
          <p>And thus we begin - again...</p>
        </div>
      </div>
    </>
  );
};
