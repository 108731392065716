
export const sortBoneStates = (boneStates) => {

    const list = Object.keys(boneStates).map((boneKey) => boneStates[boneKey]);

    const sorted = list
        .sort((a, b) => a.boneCount - b.boneCount)// updateTo injecttionNumber
        .map(boneData => {
            const { boneListItem } = boneData;
            return boneListItem
        })
    return sorted
}