
export const getGlyphType = (num,type) =>{

    switch (type) {
        case 'colorsharp':{

            return  `./images/glyphSets/colorSharp/${num}colorsh.png`
        }
        case 'color2':{

            return  `./images/glyphSets/color2/${num}.png`
        }
        default:{
            return  `./images/glyphSets/silverSharp/silversh${num}.png`
        }
    }
}