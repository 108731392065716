import { useEffect, useMemo, useState } from "react";
import { firestore } from "../../firebase";

const adminaccessRef = firestore.collection("adminaccess");
const hasAdminAccess = async (user) => {
  try {
    const { email } = user;

    const found = await adminaccessRef.where("email", "==", email).get();

    const { docs = [] } = found;

    return docs[0];
  } catch (error) {
    console.log(
      "🚀 ~ file: useHasAdminAccess.js:15 ~ hasAdminAccess ~ error:",
      error
    );
  }
};
export const useHasAdminAccess = (props = {}) => {
  const { user  } = props;

  const userId = useMemo(()=>{

    return user ?  user.id:undefined
  },[user])

  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const getAccess = async () => {
      try {
        const hasAccess = await hasAdminAccess(user);
        setIsAdmin(hasAccess);
      } catch (error) {
        console.log(
          "🚀 ~ file: useHasAdminAccess.js:34 ~ getAccess ~ error:",
          error
        );
      }
    };

    if (user && user.email) {
      getAccess();
    }
  }, [userId]);

  return isAdmin;
};
