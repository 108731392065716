import {
  getLiveCursorConfig,
  setLiveCursor,
  setOtherCursor,
} from "../ThreeUtills/getLiveCursorConfig";
import { useCallback, useState, useEffect } from "react";
import {
  sendHideCursor,
  hideAllCursors,
} from "./VideoChatAgora/onLiveCursorMessage";
import { PhaseImg } from "./PhaseImg";

const startConfig = getLiveCursorConfig();

export const CommunityFeedBackButtonContatiner = () => {
  const [myCursorOn, setMyCursorOn] = useState(startConfig.shareCursor);

  const [othersCursorsOn, setOthersCursorsOn] = useState(
    startConfig.showOtherCursors
  );

  const toggleMyCursor = useCallback(async () => {
    if (myCursorOn === true) {
      sendHideCursor();
    }
    setLiveCursor(!myCursorOn);
    setMyCursorOn(!myCursorOn);
  }, [myCursorOn, setMyCursorOn]);

  const toggleOtherCursors = useCallback(async () => {
    if (othersCursorsOn === true) {
      hideAllCursors();
    }

    setOtherCursor(!othersCursorsOn);
    setOthersCursorsOn(!othersCursorsOn);
  }, [othersCursorsOn, setOthersCursorsOn]);

  return (
    <div
      className="CommunityFeedBackButtonContatiner unselectable"
      style={{
        position: "absolute",
        right: 50,
        height: "100vh",
        width: 0,
        overflow: "visible",
        top: 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        zIndex: 50,
      }}
    >
      <PhaseImg
        time={10000}
        phaseSrcs={["/images/FeebackCoinB.gif", "/images/FeebackCoin0.gif"]}
        onClick={() => {
          const myModal = new window.bootstrap.Modal(
            document.getElementById("communityFeedBackPopUp"),
            {
              keyboard: false,
            }
          );
          myModal.show();
        }}
        style={{
          width: 50,
          transform: "rotate(180deg)",
        }}
      />

      <div
        style={{
          position: "relative",
          width: 50,
          height: 174,
        }}
      >
        <img
          style={{
            zIndex: 1,
            top: 0,
            left: 0,
            width: 50,
            height: 174,
            rotate: "180deg",
          }}
          className="position-absolute"
          src="/images/menu.png"
          alt=""
        />
        <div
          style={{
            position: "relative",
            zIndex: 100,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="h-100 w-100"
        >
          <img
            style={{
              width: 50,
            }}
            onClick={() => {
              toggleMyCursor();
            }}
            src={
              myCursorOn ? "/images/cursor_on.png" : "/images/cursor_off.png"
            }
            alt=""
          />
          <img
            style={{
              width: 50,
            }}
            onClick={() => {
              toggleOtherCursors();
            }}
            src={
              othersCursorsOn
                ? "/images/players_cursor_on.png"
                : "/images/players_cursor_off.png"
            }
            alt=""
          />
        </div>
      </div>
    </div>
  );
};
