import { rotateMHXR, positionMHXR, showMHXR } from "../HexGrid/MoveHex";
import {
  resetAltAxis,
  getBoneGlyphMarker,
} from "../BoneMeshSL/AltAxisMeshWrapper/rotateAltAxis";
export let lastSelectedBone;

export const getLastSelectedBone = () => {
  return lastSelectedBone;
};

export const setLastSelectedBone = (boneData) => {
  lastSelectedBone = boneData;
};

const showJoyStick = () => {
  document.getElementById("boneJoyStick").style.zIndex = 989;
};

 export const hideJoyStick = () => {
  document.getElementById("boneJoyStick").style.zIndex = 0;
};

export const setJoyStickBone = (boneData) => {
  const selectedBone = getLastSelectedBone();
  if (!selectedBone && boneData) {
    boneData.neRef.position.y = 0.25;
    if (boneData.neRef.parent.name === "board") {
      positionMHXR(boneData.neRef.position);
      rotateMHXR(boneData.neRef.rotation.y);
      showMHXR();
    }
    getBoneGlyphMarker({
      glyphData: boneData.glyphData,
      boneKey: boneData.touchPack.boneKey,
    }).material.opacity = 1;
    setLastSelectedBone(boneData);
    boneData.setShowPalette(true)
    // showJoyStick();

    return;
  }

  if (selectedBone && boneData) {
    if (
      boneData.glyphData.single &&
      boneData.touchPack.boneKey === selectedBone.touchPack.boneKey
    ) {
      getBoneGlyphMarker({
        glyphData: getLastSelectedBone().glyphData,
        boneKey: boneData.touchPack.boneKey,
      }).material.opacity = 0;

      getBoneGlyphMarker({
        glyphData: boneData.glyphData,
        boneKey: boneData.touchPack.boneKey,
      }).material.opacity = 1;

      if (boneData.neRef.parent.name === "board") {
        positionMHXR(boneData.neRef.position);
        rotateMHXR(boneData.neRef.rotation.y);
        showMHXR();
      }

      setLastSelectedBone(boneData);

      return;
    }
  }
  const touchPic = boneData || selectedBone

  getBoneGlyphMarker({
    glyphData: getLastSelectedBone().glyphData,
    boneKey: touchPic.touchPack.boneKey,
  }).material.opacity = 0;

  if (selectedBone.neRef.parent.name === "board") {
    resetAltAxis({
      neRef: selectedBone.neRef,
      boneKey: selectedBone.touchPack.boneKey,
    });
    selectedBone.touchPack.onDrop();
  } else {
    selectedBone.neRef.position.y = 0;
  }

  // setLastSelectedBone(undefined);
  // hideJoyStick();
};
