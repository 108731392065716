import { useState, useEffect } from "react";
import { getLocalFeatureConfig, setLocalFeatures } from "./localFeatureConfig";

export const FeatureSettingsCollapse = () => {
  const [featureList, setFeatureList] = useState([]);

  useEffect(() => {
    const features = getLocalFeatureConfig();

    setFeatureList(
      Object.keys(features).map((key) => ({
        key,
        on: features[key],
      }))
    );
  }, []);

  const updateFeature = (key, update) => {
    setLocalFeatures({[key]:update});

    const listUpdate = featureList.map((feature) => {
      if (feature.key === key) {
        return {
          ...feature,
          on: update,
        };
      }

      return feature
    });

    setFeatureList(listUpdate);
  };

  return (
    <>
      <p
        data-bs-toggle="collapse"
        data-bs-target=".featureSettings-collapse"
        aria-expanded="false"
        aria-controls="featureSettings-collapse"
        className="key-menu-item"
        onClick={() => {}}
      >
        Feature Settings
      </p>
      <div
        className="collapse info-collapse featureSettings-collapse"
        id="featureSettings-collapse"
      >
        <div className="card card-body">
          {featureList.map((feature) => {
            const { key, on } = feature;
            return (
              <div
                key={key}
                style={{
                  cursor: "pointer",
                  backgroundColor: on ? "#2b1700" : "transparent",
                }}
                onClick={() => {
                  updateFeature(key, !on);
                }}
              >
                <span
                  style={{
                    color: on ? "white" : "#2b1700",
                  }}
                  className="text-start"
                >
                  {key}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
