import { Vector3, Raycaster,Quaternion } from "three";

export const getWorldPosition = (object) => {
  const worldPosition = new Vector3();
  object.getWorldPosition(worldPosition);
  return worldPosition;
};

export const getWorldQ = (object) => {
  const worldQ = new Quaternion();
  object.getWorldQuaternion(worldQ);
  return worldQ;
};

export const getDownVectorWorld = (worldQ) => {
  const downVector = new Vector3(0, -1, 0);
  downVector.applyQuaternion(worldQ);
  return downVector;
};

export const getDownRayCaster = (worldPosition, downVector) => {
  const raycaster = new Raycaster();
  raycaster.set(worldPosition, downVector);
  return raycaster;
};

export const pickFloorMesh = (object) => {
  const worldPosition = getWorldPosition(object);

  const worldQ = getWorldQ(object);

  const downVectorWorld = getDownVectorWorld(worldQ);

  const raycaster = getDownRayCaster(worldPosition, downVectorWorld);

  const children = object.parent.parent.children;
  const intersects = raycaster.intersectObjects([...children]);

  const [one] = intersects.filter((intersect) =>
    ["board", "platform", "wellPlatform"].includes(intersect.object.name)
  );

  return one
};
