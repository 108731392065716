import { gamesCollectionRef, getCurrentUser } from ".";
import moment from "moment";
import { getUserCoilfe } from "../../SignUp/formatFormSubmitEvent";

export const createNewGame = async (props = {}) => {
  try {
    const { nextRoundData = {}, roundPlayers = [] } = props;

    const roundsPlayersUpate = {};
    const user = getCurrentUser();
    const createdStamp = moment().valueOf();

    const cofile = await getUserCoilfe({ userId: user.id });
    const { cofileData = [], screenName = "" } = cofile || {};
    // const { value: screenName } = cofileData[6] || {};

    const playaPac = {
      ...user,
      isPlayerData: true,
      stamp: createdStamp,
      screenName,
      email: user.email,
    };

    const cloudGameData = {
      creator: user,
      createdStamp,
      allPlayers: [playaPac],
      allEmails: [user.email],
      activeEmails: [user.email],
      [user.id]: {
        ...playaPac,
      },
      roundData: nextRoundData,
      ...roundsPlayersUpate,
    };

    const cloudGameResult = await gamesCollectionRef.add({
      ...cloudGameData,
    });
    cloudGameData.id = cloudGameResult.id;
    return cloudGameData;
  } catch (error) {
    console.log("createNewGame -> error", error);
  }
};
