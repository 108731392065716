import { useMemo } from "react";
import { useLoader } from "@react-three/fiber";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
const defaultBoneMeshObjFile = "objs/tanhexcube.obj";

export const BaseModelLow = (props) => {
  const obj = useLoader(OBJLoader, defaultBoneMeshObjFile);
  const core = useMemo(() => obj.clone().children[0], [obj]);

  return (
    <primitive scale={0.58} name={props.name} object={core}>
      <meshBasicMaterial color={false ? 0x00ad8e : 0x4c4c66} />
    </primitive>
  );
};
