import { useMemo } from 'react';
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { useLoader } from '@react-three/fiber';
import { getPointerRad, setLastPoint } from './lastPoints';

const cohadoHeight = 0.1;

const ratio = 1;

const cohadoWidth = cohadoHeight * ratio;

const cursorScale = .1;

const cursorRefs = {};

const cursors = new Array(7).fill({});

export const CursorMesh = (props) => {
  const { glyphNumber } = props;

  const baselglb = useLoader(GLTFLoader, `/glbs/cursor${glyphNumber}.glb`)

  const baseGroup = useMemo(() => {
    const { scene } = baselglb
    const clone = scene.clone()
    return clone
  }, [baselglb])

  return (
    <mesh
      // id={`cursor-${glyphNumber}`}
      rotation={[
        0,
        //   0,
        -45 / (180 / Math.PI),
        0
      ]}
      scale={0}
      ref={(ref) => {
        if (ref) {
          cursorRefs[glyphNumber] = ref;
        }
      }}
      position={[0, -1, 0]}
    >
      <primitive
        object={baseGroup}
        position={[.9, 0, .35]}
      />
    </mesh>
  );
};

export const Cursors = () => {
  return (
    <>
      <mesh>
        {cursors.map((a, b) => {
          return (
            <mesh key={b}>
              <CursorMesh glyphNumber={b + 1} />
            </mesh>
          );
        })}
      </mesh>
    </>
  );
};


const lastPoints = {


}

const radToDeg = (rad) => { return rad * (180 / Math.PI); }

const getvectorAngleToCenterRadRotaion = (x, z) => {
  const mag = Math.sqrt(Math.pow(x, 2) + Math.pow(z, 2))

  const xu = x / mag;
  const zu = z / mag;

  const rad = Math.PI - Math.atan2(-zu, - xu)

  return rad
}

export const moveCursor = (props) => {
  const { x, z, glyphNumber } = props;

  const foundRef = cursorRefs[glyphNumber];

  //  const rad = getvectorAngleToCenterRadRotaion(x, z)

  if (foundRef) {

    // try {

    //   const { out } = getPointerRad({ glyphNumber, x, z })

    //   foundRef.rotation.set(0, out, 0);

    // } catch (error) {
    //   console.log("🚀 ~ file: Cursors.js:119 ~ moveCursor ~ error:", error)
    // }

    foundRef.position.set(x, 0.1, z);

    // setLastPoint({ glyphNumber, x, z })

    if (!foundRef.scale.x) {
      foundRef.scale.set(cursorScale, cursorScale, cursorScale);
    }
  }
};

export const hideCursor = (props) => {
  const { glyphNumber } = props;
  const foundRef = cursorRefs[glyphNumber];
  if (foundRef) {
    foundRef.scale.set(0, 0, 0);
    foundRef.position.set(0, -1, 0);
  }
};

export const hideCursors = () => {
  cursors.forEach((a, b) => {
    hideCursor({ glyphNumber: b + 1 });
  });
};
