
export const blobPromise = (canvas) => {

  return new Promise((resolve, reject) => {

    try {

      canvas.toBlob(function (blob) {
        resolve(blob);
      });

    } catch (error) {
      reject(error);
    }

  });
};
