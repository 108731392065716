import { useCallback, useEffect, useState } from "react";
import { summaryCardCollectionRef } from ".";

export const getSummaryCardData = async (props) => {
  const { gameId } = props;
  try {
    if (gameId) {
      const { docs = [] } = await summaryCardCollectionRef
        .where("gameId", "==", gameId)
        .get();

      if (docs.length) {
        const [doc] = docs;
        const data = {
          ...doc.data(),
          id: doc.id,
        };

        return data;
      }
    } else {
      throw "no summary car data";
    }
  } catch (error) {
    console.log(
      "🚀 ~ file: buildGameCardData.js:38 ~ buildGameCardData ~ error:",
      error
    );
  }
};

const getGameId = () => {
  const pageQuery = window.location.href.split("?")[1];

  const params = new URLSearchParams(pageQuery);

  const gameId = params.get("gameId");

  return gameId;
};

export const useSummaryCardData = () => {
  const [summaryCardData, setSummaryCardData] = useState(null);

  const onSummaryPage = useCallback(async () => {
    const gameId = getGameId();

    const summaryData = await getSummaryCardData({ gameId });
    const { pointSections = {}, beats = 0 } = summaryData;

    const {
      assets = { total: 0 },
      waste = { total: 0 },
      una = { total: 0 },
    } = pointSections;

    setSummaryCardData({
      ...summaryData,
      assets: assets.total,
      waste: waste.total,
      una: una.total,
      beats,
    });
  }, [setSummaryCardData]);

  useEffect(() => {
    onSummaryPage();
  }, [onSummaryPage]);

  return { summaryCardData };
};
