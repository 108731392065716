import { BehaviorSubject } from 'rxjs'
import { useObservableState } from 'observable-hooks'
import { isEqual } from 'lodash'

export const orbitalRef$ = new BehaviorSubject(null)

export const registerOrbitalRef = () => {

    return {
        ref: (ref) => {
            if (ref) {
                if (!isEqual(ref, orbitalRef$.getValue())) {
                    orbitalRef$.next(ref);
                }
            }
        }
    }
}

export const useOrbitalRef = () => {
    const orbitalRef = useObservableState(orbitalRef$, {});
    const setOrbitalRef = (nextOrbitalRef) => {
        if (!isEqual(nextOrbitalRef, orbitalRef)) {
            orbitalRef$.next(nextOrbitalRef);
        }
    };
    return {
        orbitalRef,
        setOrbitalRef
    };
}
