export const ScoreDigit = (props) => {
  const { scoreDigit } = props;

  const { digitChar = "0" } = scoreDigit;

  return (
    <div
      className="scoredigit"
      style={{
        position: "relative",
        margin: `0 ${digitChar !== "0" ? "4px" : "0"}`,
      }}
    >
      <div
        className="h-100 w-100"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          backgroundColor: "transparent",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img className="scoredigitback" src="/images/scoredigitback.jpg" />
      </div>
      <div
        className="h-100 w-100"
        style={{
          position: "relative",
          left: 0,
          backgroundColor: "transparent",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <span
          className="digit-span"
          style={{
            zIndex: 10,
            fontFamily: "Barlow Semi Condensed",
            color: "#ff9933",
            lineHeight: 0.9,
            fontWeight: 100,
          }}
        >
          {digitChar}
        </span>
      </div>
      <div
        className="h-100 w-100 scorecage-container"
        style={{
          position: "absolute",
          top: 0,
          zIndex: 10,
          left: 0,
          backgroundColor: "transparent",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img className="scorecage" src="/images/scorecage.png" />
      </div>
    </div>
  );
};
