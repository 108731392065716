import * as THREE from 'three'

const createVert = function (i, size) {
    var angle = ((Math.PI * 2) / 6) * i;
    return new THREE.Vector3((size * Math.cos(angle)), (size * Math.sin(angle)), 0);
};
const createVerts = (size) => {
    var i, verts = [];
    // create the skeleton of the hex
    for (i = 0; i < 6; i++) {
        verts.push(createVert(i, size));
    }
    return verts;
};
const size = .95;

const scale = .1;


const createHex = (size) => {
    const verts = createVerts(size);
    const shape = new THREE.Shape();
    shape.moveTo(verts[0].x, verts[0].y);
    for (let i = 1; i < 6; i++) {
        shape.lineTo(verts[i].x, verts[i].y);
    }
    shape.lineTo(verts[0].x, verts[0].y);
    shape.autoClose = true;
    return shape;
};


const createPanel = (size) => {
    const verts = createVerts(size);
    const shape = new THREE.Shape();

    shape.moveTo(0,0);
    for (let i = 0; i < 3; i++) {
        shape.lineTo(verts[i].x, verts[i].y);
    }
        shape.lineTo(0,0);

    shape.lineTo(verts[0].x, verts[0].y);
    shape.autoClose = true;
    return shape;
};


export const panelShape = createPanel( (size * scale)-.002 );

export const hexShapeHalf = createHex(.5 * scale);

export const hexShape = createHex(size * scale);

export const hexShape2 = createHex(1 * scale);


export const hexShape3 = createHex(3 * scale);
