import { useState, useMemo, useEffect, useCallback } from "react";
import { PointSectionSummary } from "./PointSectionSummary";
import "./scoreSummary.css";
import { useSummaryDrag } from "./useSummaryDrag";
import { useAnimationState } from "./PlayerOrder/useAnimationState";
import { sendRTMmessage } from "./VideoChatAgora/initAgoraRTM";

const cohadoHeight = 700;

const ratio = 1014 / 1871;

const bigHeight = 1000;

const bigratio = 1583 / 1000;

const bigwidth = bigHeight * bigratio;

const cohadoWidth = cohadoHeight * ratio;

export const ScoreSummaryModal = (props) => {
  const {
    onClickGetSummaryCard = () => {},
    allBonesOnBoard,
    liveBoneCount = 0,
    pointSections = {},
    coe = 0,
    beats = 0,
    nextRound,
    iAmhost,
    isMyTurn,
    roundIndex,
  } = props;
  const {
    assets = { total: 0 },
    waste = { total: 0 },
    una = { total: 0 },
  } = pointSections;
  // useSummaryDrag();

  const [endGame, setEndGame] = useState(false);

  const goEndAnimation = useCallback(() => {
    setEndGame(true);
  }, [setEndGame]);

  const remoteAnimationState = useAnimationState();

  useEffect(() => {
    if (remoteAnimationState && !iAmhost) {
      const { phase } = remoteAnimationState;

      if (phase && phase === "goEnd" && !endGame) {
        goEndAnimation();
      }
    }
  }, [remoteAnimationState, iAmhost, endGame, goEndAnimation]);

  const onClickEndGame = useCallback(() => {
    goEndAnimation();
    if (iAmhost) {
      sendRTMmessage({
        msg: JSON.stringify({
          type: "animationState",
          phase: "goEnd",
        }),
      });
    }
  }, [goEndAnimation, iAmhost]);

  return (
    <div
      className="modal scoreSummary-modal"
      id="scoreSummary"
      data-bs-keyboard="true"
      tabIndex="-1"
      aria-labelledby="scoreSummaryTitle"
      aria-hidden="true"
    >
      <img
        className="here to preload the end gif"
        style={{
          height: 0,
          width: 0,
          position: "absolute",
        }}
        src="/images/endgameshort.gif"
        alt=""
      />
      {allBonesOnBoard && (
        <div className="position-absolute h-100 w-100  d-flex justify-content-center">
          <img
            style={{
              position: "relative",
              height: "16vw",
              top: "16vh",
            }}
            src="/images/cohado_glow_logo.png"
            alt=""
          />
          <div
            className="position-absolute h-100 w-100"
            style={{
              opacity: 0.5,
              backgroundColor: "#2a2c2c",
            }}
          />
        </div>
      )}
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div
          className="modal-content"
          style={{
            height: cohadoHeight,
            width: cohadoWidth,
            backgroundColor: "transparent",
            overflow: allBonesOnBoard ? "visible" : "hidden",
          }}
        >
          <div
            // className="summary-drag"
            style={{
              zIndex: endGame ? 10 : 1,
              height: 1015.58,
              width: 1465.91,
              flex: "none",
              top: -160,
              left: -569,
              position: "absolute",
            }}
          >
            <div
              style={{
                left: "40%",
                top: "50%",
                width: 180,
                position: "absolute",
                zIndex: 90,
                zIndex: endGame ? 10 : -1,
                opacity: endGame ? 1 : 0,
                transition: "opacity 4s ease",
                transitionDelay: "8s",
                // border: "1px solid black",
              }}
            >
              <p
                style={{
                  fontFamily: "isocpeur",
                  fontSize: 24,
                  textAlign: "center",
                  color: "#ff9933",
                }}
              >
                Touch key to go to dashboard
              </p>
              <img
                className="touch-sign"
                style={{
                  height: 25,
                  rotate: "180deg",
                }}
                src="/images/arrow.png"
                alt=""
              />
            </div>
            <div
              onClick={(e) => {
                e.stopPropagation();
                window.location.href = "/dash";
              }}
              style={{
                display: endGame ? "block" : "none",
                cursor: "pointer",
                left: "40%",
                top: "59%",
                position: "absolute",
                zIndex: 10,
                height: 270,
                width: 150,
                // border: "1px solid black",
              }}
            />
            <img
              id="animationbook"
              style={{
                height: "100%",
                width: "100%",
                left: 0,
                opacity: 1,
                top: 0,
                zIndex: 5,
                display: "unset",
                position: "absolute",
              }}
              src={
                endGame ? "/images/endgameshort.gif" : "/images/endStart.png"
              }
              alt=""
            />
          </div>

          <div
            style={{
              zIndex: endGame ? 1 : 10,
              opacity: 1,
              position: "relative",
              height: cohadoHeight,
              width: cohadoWidth,
              backgroundColor: "transparent",
              transition: "opacity 1s ease",
              overflow: "scroll",
              opacity: endGame ? 0 : 1,
            }}
            className="d-flex flex-column scoreSummary"
          >
            <div
              className="modal-header d-flex justify-content-around"
              style={{
                border: 0,
                zIndex: 10,
              }}
            >
              <img
                onClick={() => {
                  onClickEndGame();
                }}
                style={{
                  height: 55,
                }}
                src="/images/accountingtext.png"
                alt=""
              />
            </div>
            <div className="modal-header border-0">
              <div className="d-flex no-wrap table-head">
                <div className="glyph-name">
                  <p>WEIGHT/PRINCIPAL</p>
                </div>
                <div className="group-count">
                  <p>COUNT</p>
                </div>
                <div className="group-points">
                  <p>SCORE</p>
                </div>
              </div>
            </div>
            <div className="modal-body score-summary-body border-0">
              <PointSectionSummary pointSection={assets} />
              <PointSectionSummary pointSection={waste} />
              <PointSectionSummary pointSection={una} />
            </div>
            <div className="modal-footer bone-count w-100 justify-content-start">
              Total Bone Count: {liveBoneCount}
            </div>

            <div className="modal-footer">
              <div className="coe-table m-0 flex-1">
                <div className="row gx-0">
                  <div className="col">
                    <h5 className="m-0">
                      <p className="section-title">
                        COE: Resulting Coefficient Achieved:
                      </p>
                    </h5>
                  </div>
                </div>
                <div className="row gx-0" style={{ paddingLeft: 15 }}>
                  <div className="col-2">Assets</div>-{!!beats && <> (</>}
                  <div className="col-2">Waste</div>
                  {!!beats && (
                    <>
                      x<div className="col-2">Beats</div>)
                    </>
                  )}
                  <> -</>
                  <div className="col-2">UNA</div>=
                  <div className="col-2">COE</div>
                </div>
                <div className="row gx-0" style={{ paddingLeft: 15 }}>
                  <div className="col-2">{assets.total}</div>-
                  {!!beats && <> (</>}
                  <div className="col-2">{waste.total}</div>
                  {!!beats && (
                    <>
                      x<div className="col-2">{beats}</div>)
                    </>
                  )}
                  <> -</>
                  <div className="col-2">{una.total}</div>=
                  <div className="col-2">{coe}</div>
                </div>
              </div>
              <img
                style={{
                  height: 25,
                }}
                src="/images/CohadoX.png"
                type="button"
                data-bs-dismiss="modal"
              />
            </div>

            {
              iAmhost &&
               allBonesOnBoard && (
                <div className="modal-footer w-100 justify-content-start">
                  <button
                    style={{
                      backgroundImage: "url(/images/paperforbutton3.png)",
                      backgroundSize: "100% 100%",
                      backgroundColor: "transparent",
                      padding: "6px 12px",
                      border: 0,
                    }}
                    onClick={() => {
                      onClickEndGame();
                    }}
                  >
                    End Game
                  </button>
                  <button
                    type="button"
                    data-bs-dismiss="modal"
                    style={{
                      backgroundImage: "url(/images/paperforbutton3.png)",
                      backgroundSize: "100% 100%",
                      backgroundColor: "transparent",
                      padding: "6px 12px",
                      border: 0,
                    }}
                    onClick={() => {
                      onClickGetSummaryCard();
                    }}
                  >
                    Summary Card
                  </button>
                  {/* <button
                  style={{
                    backgroundImage: "url(/images/paperforbutton3.png)",
                    backgroundSize: "100% 100%",
                    backgroundColor: "transparent",
                    padding: "6px 12px",
                    border: 0,
                  }}
                  onClick={() => {
                    nextRound();
                  }}
                >
                  ({roundIndex}) Next Round
                </button> */}
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
};
