import * as THREE from 'three';
import { useMemo } from "react";
import { useFrame } from '@react-three/fiber';
import { useTexture } from '@react-three/drei';
import { useTouchHooks } from '../ThreeUtills/useTouchHooks';
import { registerPlatform } from "../PlatformControl/useDragPlatBlock";

export const WellPlatform = (props) => {
    const { showWell } = props
    const {
        ref: platform,
        onPointerDown,
        onPointerMove,
        onPointerUp,
    } = useTouchHooks()

    useFrame(({ camera }) => {
        if (platform && platform.current) {//
            const { userData } = platform.current;
            const { selectedZ = 0, selectedY = 0, selectedX = 0 } = userData;
            platform.current.position.copy(camera.position);
            platform.current.quaternion.copy(camera.quaternion);
            platform.current.translateZ(selectedZ + -0.2);
            platform.current.translateY(selectedY)//+ -0.1);
            platform.current.translateX(selectedX);
            platform.current.rotateX(90 * (Math.PI / 180));
        }
    })

    const scale = useMemo(() => {
        const { userData = {} } = platform.current || {};
        const { selectedScale = 0 } = userData;
        return showWell ? (0.13 + selectedScale) : 0
    }, [platform, showWell]);
    const boneBarTexture = useTexture('./images/welltray.png');

    return (
        <mesh
            scale={scale}
            name='wellPlatform'
            onPointerDown={onPointerDown}
            onPointerMove={onPointerMove}
            onPointerUp={onPointerUp}
            ref={(ref) => {
                if (ref) {
                    platform.current = ref
                    registerPlatform('well', ref)
                }
            }}
        >
            <boxGeometry
                args={[1.6, 0, 1.6]}
            />
            <meshBasicMaterial
                attach="material"
                map={boneBarTexture}
                transparent
            />

        </mesh>
    );
}


/* 
<mesh
    rotation={[-90 * (Math.PI / 180), 0, 0]}
>
    <planeBufferGeometry 
    attach="geometry"
     args={[3.2, .4]}
     />
    <meshBasicMaterial
        blending={THREE.NormalBlending}
        blendSrc={THREE.SrcColorFactor}
        blendDst={THREE.ZeroFactor}
        attach="material"
        map={boneBarTexture}

    />
</mesh>
*/