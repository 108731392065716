import {
  formatFormSubmitEvent,
  getUserCoilfe,
  setCofileData,
} from "./formatFormSubmitEvent";
import "./cofile2.css";
import { onFileUpload } from "./formatFormSubmitEvent";
import { auth, getCurrentUser } from "../../firebase";
import { useEffect, useState } from "react";

const fields = [
  { text: "First Name", keyName: "firstName" },
  { text: "Last Name", keyName: "lastName" },
  {
    text: "Date of Birth [mm/dd/yyyy] (You must be 13 or older to register)",
    keyName: "dob",
  },
  //  { text: "Email", canSend: false },
  {
    text: "Subscribe and stay up to date on Cohado® developments!",
    type: "checkbox",
    keyName: "sub",
  },
  {
    text: "Cell Phone: For Text Notifications (Include + Country Code [+7 777.777.7777] )",
    keyName: "phoneNumber",
  },
  {
    text: "Virtual Cohado® Screen Name (This will be seen by your co-ponents)",
    keyName: "screenName",
  },
  {
    text: "Describe Who You Are in 7 Words",
    type: "textarea",
    keyName: "words",
  },
  {
    type: "radio",
    text: "Depth of Cohado Experience",
    keyName: "depth",
    fieldValues: [
      { label: "Neo (Under 10)", checked: false },
      { label: "Playa (11-50)", checked: false },
      { label: "Cohadi (51+)", checked: false },
    ],
  },
  {
    text: "What makes your Soul Sing?",
    value: "",
    type: "textarea",
    keyName: "sing",
  },
  {
    text: "Picture",
    type: "upload",
    keyName: "image",
  },
];

export function Cofile2() {
  const [savedCofile, setSavedCofile] = useState();
  useEffect(() => {
    const fetchCofile = async () => {
      const user = getCurrentUser();

      if (user) {
        const cofile = await getUserCoilfe({ userId: user.id });
        if (cofile) {
          setSavedCofile({ ...cofile });

          const { cofileData = [] } = cofile;
          const noEmail = cofileData.filter((feild) => feild.text !== "Email:");

          setCofileData({ ...cofile, cofileData: noEmail });
        }
      }
    };

    fetchCofile();
  }, []);

  return (
    <div
      className="h-100 cofile"
      style={{ overflow: "scroll", backgroundColor: "#2a2c2c" }}
    >
      <div
        className="main"
        style={{
          top: 0,
          left: 0,
          width: "100%",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          zIndex: 1,
        }}
      >
        <div
          style={{
            position: "absolute",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            backgroundColor: "transparent",
          }}
        >
          <img
            style={{
              position: "relative",
              width: "100%",
              flex: "none",
            }}
            src="/images/form_background_1.png"
            alt=""
          />
        </div>

        <div
          className="bigcontainer"
          style={{
            margin: "28px 0",
            flex: "none",
            position: "relative",
            backgroundColor: "transparent",
          }}
        >
          <div
            style={{
              position: "absolute",
              width: "100%",
              backgroundColor: "transparent",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "100%",
                flex: "none",
              }}
              src="/images/form_ackground2b.png"
              alt=""
            />
          </div>

          <img
            style={{
              position: "relative",
              zIndex: 1,
              width: "30%",
              marginTop: "5%",
            }}
            src="./images/cohado_key_logo.png"
          />
          <br />
          <p
            style={{
              flex: "none",
              position: "relative",
              fontFamily: "Inkburrow",
              fontSize: "6.5vw",
              textAlign: "center",
              color: "#2c2a2a",
              zIndex: 100,
            }}
          >
            Your Cohado Cofile
          </p>

          <div
            className="form-container"
            style={{
              top: 0,
              left: 0,
              width: "100%",
              position: "relative",
              zIndex: 1,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <form
              id="cofileform"
              style={{
                position: "relative",
                width: "95%",
                padding: 0,
              }}
              onSubmit={(formSubmitEvent) => {
                formSubmitEvent.preventDefault();
                formatFormSubmitEvent({ formSubmitEvent, savedCofile });
              }}
            >
              <ul
                style={{
                  position: "relative",
                  width: "100%",
                  padding: 0,
                }}
              >
                {fields.map((field, index) => {
                  const { text, type, fieldValues = [], keyName } = field;

                  const eIndex = text.indexOf("(");

                  const extra =
                    eIndex > -1
                      ? text.substring(eIndex - 1, text.indexOf(")") + 1)
                      : "";

                  const label = text.replace(extra, "");
                  const id = `cofile-li-${keyName}`;
                  return (
                    <li
                      key={id}
                      id={id}
                      style={{
                        position: "relative",
                        width: "100%",
                        listStyle: "none",
                        marginBottom: 24,
                      }}
                    >
                      <p
                        style={{
                          margin: 0,
                          color: "#2a2c2c",
                          fontSize: 24,
                          fontFamily: "creamfont",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {type === "checkbox" ? (
                          <img
                            id="check-img"
                            className="check-img"
                            style={{
                              height: 50,
                              cursor: "pointer",
                              marginRight: 9,
                            }}
                            src="images/switch_off.png"
                            onClick={(event) => {
                              if (event.target.src.indexOf("on") > -1) {
                                event.target.src = "images/switch_off_02.png";
                              } else {
                                event.target.src = "images/switch_on_02.png";
                              }
                            }}
                          />
                        ) : (
                          <></>
                        )}
                        <span> {label}:</span>
                      </p>

                      {extra ? (
                        <p
                          style={{
                            margin: 0,
                            color: "#2a2c2c",
                            fontSize: 18,
                            fontFamily: "creamfont",
                          }}
                        >
                          {extra}
                        </p>
                      ) : (
                        <></>
                      )}

                      {/* {type !== "checkbox" ? <br /> : <></>} */}

                      {!type ? (
                        <input
                          style={{
                            backgroundColor: "transparent",
                            border: 0,
                            outline: 0,
                            margin: 0,
                            boxShadow: "none",
                            fontSize: 24,
                            lineHeight: "24px",
                            fontFamily: "orange_typewriter",
                            borderBottom: "1px solid white",
                            borderTop: "1px solid white",
                          }}
                          className="form-control text-put"
                          type="text"
                        />
                      ) : (
                        <></>
                      )}
                      {type === "upload" ? (
                        <div>
                          <img
                            className="border"
                            id="preview"
                            style={{
                              height: 200,
                              minWidth: 200,
                              borderRadius: 20,
                            }}
                            alt=""
                          />
                          <input
                            onChange={(event) => {
                              onFileUpload({ event });
                            }}
                            style={{
                              backgroundColor: "transparent",
                              border: 0,
                              outline: 0,
                              margin: 0,
                              fontSize: 24,
                              fontFamily: "creamfont",
                              boxShadow: "none",
                              borderBottom: "1px solid white",
                              borderTop: "1px solid white",
                            }}
                            className="form-control"
                            type="file"
                          />
                        </div>
                      ) : (
                        <></>
                      )}

                      {type === "textarea" ? (
                        <textarea
                          style={{
                            backgroundColor: "transparent",
                            border: 0,
                            outline: 0,
                            margin: 0,
                            boxShadow: "none",
                            border: "1px solid white",
                          }}
                          className="form-control"
                        />
                      ) : (
                        <></>
                      )}

                      {type === "radio" ? (
                        <fieldset className="d-flex flex-column align-items-start">
                          {fieldValues.map((feild) => {
                            return (
                              <label key={field.label}>
                                <input className="me-2" type="radio" />
                                <span>{feild.label}</span>
                              </label>
                            );
                          })}
                        </fieldset>
                      ) : (
                        <></>
                      )}
                    </li>
                  );
                })}
              </ul>
              <button
                type="submit"
                style={{
                  backgroundImage: "url(/images/form_button.png)",
                  backgroundSize: "100% 100%",
                  backgroundColor: "transparent",
                  padding: "6px 12px",
                  border: 0,
                  textAlign: "center",
                  padding: 12,
                  textDecoration: "none",
                  color: "black",
                }}
              >
                submit
              </button>
              <button
                onClick={(event) => {
                  event.preventDefault();
                  auth.signOut();
                }}
                style={{
                  // backgroundImage: "url(/images/form_button.png)",
                  // backgroundSize: "100% 100%",
                  backgroundColor: "coral",
                  borderRadius: "30%",
                  padding: "6px 12px",
                  border: 0,
                  marginLeft: 24,
                  textAlign: "center",
                  padding: 12,
                  textDecoration: "none",
                  color: "black",
                }}
              >
                logout
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
