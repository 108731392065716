import { useState, useRef } from 'react';

export const Bone = (props) => {

  const caseRef = useRef()
  const { bone } = props;

  const [focused, setFocused] = useState(false);

  const [rotation, setRotation] = useState(0);

  const { thang1, thang2, combo } = bone;
  return (
    <div
      ref={caseRef}
      // onContextMenu={(event) => {
      //   event.preventDefault()
      //   setFocused(!focused)
      // }}
      onMouseOver={(event) => {
        event.preventDefault()
        setFocused(true)
      }}
      onMouseLeave={(event) => {
        event.preventDefault()
        setFocused(false)
      }}
      key={combo}
      className="bonecase draggable"
    >
      {focused &&
        <div className="rotates">
          <div
            onClick={() => {
              if (caseRef && caseRef.current) {
                const parentNode = caseRef.current.parentNode
                const className = parentNode.className
                const isSet = className.indexOf('main-set') > -1
                if (isSet) {
                  setRotation(rotation + 60)
                }
              }
            }}
            className="ro-control">
            <img src="/images/rotate_right.png" alt="" />
          </div>
          <div
            onClick={() => {
              if (caseRef && caseRef.current) {
                const parentNode = caseRef.current.parentNode
                const className = parentNode.className
                const isSet = className.indexOf('main-set') > -1
                if (isSet) {
                  setRotation(rotation - 60)
                }
              }
            }}
            className="ro-control"><img src="/images/rotate_left.png" alt="" /></div>
        </div>
      }
      <div
        className="d-flex flex-column bone "
        style={{
          transform: `rotate(${rotation}deg)`
        }}
      >
        <div className="half-hold">
          <img src="/images/50hexo.png" alt="" className="hexo" />

          <div className="marrow">
            <img className='half-bone' src={`/images/${thang1.strang}`} alt="" />
          </div>

        </div>
        <div className="half-hold">
          <img src="/images/50hexo.png" alt="" className="hexo" />

          <div className="marrow">
            <img style={{transform:'rotate(180deg)'}} className='half-bone' src={`/images/${thang2.strang}`} alt="" />
          </div>

        </div>
      </div>
    </div>
  )
}