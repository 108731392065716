import { getBoneCombos } from './placeReport';
import { Bone } from './Bone'
import { useCohadoStates } from './useCohadoStates';

const feildImageUrl = '/images/50evenset.png';

const stringToThing = (strang) => {
  const [key, points] = strang.split('-')
  const thang = { key, points, strang }
  return thang
}

function Twode() {
  const combos = getBoneCombos()
  const bones = Object.keys(combos).map(combo => {
    const [h1, h2] = combo.split('+')
    const thang1 = stringToThing(h1)
    const thang2 = stringToThing(h2)
    return {
      combo,
      thang1,
      thang2
    }
  })

  const { wasteScore, mainCoord } = useCohadoStates()

  return (
    <div className="game-container">

      <div className="score-card">

        <h1> wasted  <span id="dang-score"> {wasteScore}</span>  </h1>

      </div>

      <div className={`play-space${mainCoord ? ' main-set' : ''}`}>
        {bones.map((bone) => {
          return (
            <Bone
              key={bone.combo}
              bone={bone}
            />
          )
        })}
        {/* <img className='feildImage' src={feildImageUrl} alt="" /> */}
      </div>
    </div>
  );
}


export default Twode;



