import "./style.css";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { auth, firebase } from "../../firebase";
import { KeyMenu } from "../KeyMenu";

import { ForgotPassword } from "./ForgotPassword";

export function MainPage() {
  const { register, handleSubmit } = useForm();

  const onSignIn = (user) => {
    if (user && user.uid) {
    }
  };

  const onSubmit = async (data) => {
    const { email, password } = data;
    if (email && password) {
      try {
        const response = await auth.signInWithEmailAndPassword(email, password);
        console.log("SignIn -> response", response);
        const { user } = response;
        console.log("SignIn -> user", user);
        onSignIn(user);
      } catch (error) {
        console.error("onSubmit -> error", error);
      }
    }
  };

  const googleSignIn = async () => {
    var provider = new firebase.auth.GoogleAuthProvider();
    const response = await auth.signInWithPopup(provider);
    console.log("googleSignIn -> response", response);
    const { user } = response;
    console.log("googleSignIn -> user", user);
    onSignIn(user);
  };

  return (
    <div
      style={{
        backgroundImage: "url(/images/LogIn_Background3.png)",
        backgroundSize: "100% auto",
        overflow: "scroll",
        backgroundColor: "#2a2c2c",
      }}
      className="h-100 main-page"
    >
      <div className="w-100 d-flex justify-content-center">
        <img
          style={{
            height: "8vw",
            margin: "50px 0",
          }}
          src="/images/CohadoLogo2.png"
          alt=""
        />
      </div>

      <div
        style={{
          backgroundImage: "url(/images/paper01.png)",
          backgroundSize: "100% 100%",
          minWidth: 450,
          padding: "20px 40px",
        }}
        className="animate_up animated fadeInUp"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <input
            placeholder="Email"
            {...register("email")}
            type="text"
            className="form-control form-control-lg"
          />
          <input
            placeholder="Password"
            {...register("password")}
            type="password"
            className="form-control form-control-lg"
          />
          <div className="d-flex justify-content-around">
            <button type="submit" className="page-icon-button">
              <img
                className="page-icon-img"
                src="/images/LogIn_Icon.png"
                alt=""
              />
              <p>Log In</p>
            </button>
          </div>
        </form>
        <div
          style={{
            margin: "-40px 0px",
            // padding:"0px 40px"
          }}
          className="d-flex justify-content-center"
        >
          <Link to="/sign-up" className="page-icon-button">
            <img
              className="page-icon-img"
              src="/images/SignUp_Icon.png"
              alt=""
            />

            <p>Sign Up</p>
          </Link>
          <div
            style={{
              width: 100,
            }}
          />
          <button
            style={{ lineHeight: 1 }}
            onClick={() => {
              googleSignIn();
            }}
            className="page-icon-button"
          >
            <img
              className="page-icon-img"
              src="/images/GoogleSignIn_Icon.png"
              alt=""
            />
            <p>
              Google <br /> Sign In
            </p>
          </button>
        </div>
        <div className="d-flex justify-content-around">
          <button
            className="page-icon-button"
            onClick={() => {
              const myModal = new window.bootstrap.Modal(
                document.getElementById("keyMenu"),
                {
                  keyboard: false,
                }
              );
              myModal.show();
            }}
          >
            <img
              className="page-icon-img"
              src="/images/MoreInfo_Icon.png"
              alt=""
            />
            <p> Info </p>
          </button>
        </div>
        <div className="d-flex justify-content-end">
          <button
            className="page-icon-button"
            onClick={() => {
              window.forgotpasswordmodal = new window.bootstrap.Modal(
                document.getElementById("forgotpassword"),
                {
                  keyboard: false,
                }
              );
              window.forgotpasswordmodal.show();
            }}
          >
            <p
              style={{
                fontSize:18
              }}
            > Forgot Password? </p>
          </button>
        </div>
      </div>
      <ForgotPassword/>
      <KeyMenu />
    </div>
  );
}
