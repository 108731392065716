import { useState, useEffect, useCallback } from "react";
import AgoraRTC from "agora-rtc-sdk-ng";
import { getCurrentUser } from "../../firebase";
import { useMemo } from "react";
import moment from "moment/moment";
import { initAgoraRTM } from "./initAgoraRTM";
import { setGlyphPlayerIndex } from "./onLiveCursorMessage";
import { getLocalTrackConfig } from "./localTrackConfig";

// const agoraTokenUrl = "http://localhost:5001/big-hado/us-central1/buildAgoraToken"

const agoraTokenUrl =
  "https://buildagoratoken-yydrqfodxq-uc.a.run.app";

const agoraEngine = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

export const useAgoraStreamers = (props) => {
  const [streamers, setStreamers] = useState([]);
  const { cloudGame } = props;
  const [streams, setStreams] = useState({});

  const playerOrderList = useMemo(
    () => (cloudGame ? cloudGame.playerOrderList || [] : []),
    [cloudGame]
  );

  setGlyphPlayerIndex(playerOrderList);

  const gameId = useMemo(
    () => (cloudGame ? cloudGame.id : undefined),
    [cloudGame]
  );
  const startConnect = useCallback(async () => {
    if (cloudGame && cloudGame.id) {
      const user = getCurrentUser();
      try {
        await initAgoraRTM({ user, channelName: cloudGame.id });
        const url = `${agoraTokenUrl}?uid=${user.id}&channel=${cloudGame.id}`;
        const raw = await fetch(url);
        const json = await raw.json();
        const { options } = json;

        await agoraEngine.join(...options);

        agoraEngine.on("user-published", async (user, mediaType) => {
          await agoraEngine.subscribe(user, mediaType);
          setStreams((currentStreams) => {
            console.log(
              "🚀 ~ file: useAgoraStreamers.js:36 ~ setStreams ~ currentStreams",
              currentStreams
            );

            const uid = user.uid.toString();
            const publishedUser = currentStreams[uid] || {};
            console.log(
              "🚀 ~ file: useAgoraStreamers.js:40 ~ setStreams ~ publishedUser",
              publishedUser
            );

            const { videoTrack, audioTrack } = user;
            console.log(
              "🚀 ~ file: useAgoraStreamers.js:43 ~ setStreams ~ user,mediaType",
              user,
              mediaType
            );
            if (videoTrack) {
              videoTrack.stamp = moment().valueOf();
            }
            return {
              ...currentStreams,
              [uid]: {
                ...publishedUser,
                videoTrack,
                audioTrack,
              },
            };
          });
        });

        agoraEngine.on("user-unpublished", async (user, mediaType) => {
          console.log(
            "🚀 ~ file: useAgoraStreamers.js:82 ~ ser-unpublishe .on ~ user, mediaType):",
            user,
            mediaType
          );

          setStreams((currentStreams) => {
            console.log(
              "🚀 ~ file: useAgoraStreamers.js:87 user-unpublished  ~ setStreams ~ currentStreams",
              currentStreams
            );

            const uid = user.uid.toString();
            const publishedUser = currentStreams[uid] || {};
            console.log(
              "🚀 ~ file: useAgoraStreamers.js:95 user-unpublished ~ setStreams ~ publishedUser",
              publishedUser
            );

            console.log(
              "🚀 ~ file: useAgoraStreamers.js:100 user-unpublished ~ setStreams ~ user,mediaType",
              user,
              mediaType
            );
            if (mediaType === "video") {
              return {
                ...currentStreams,
                [uid]: {
                  ...publishedUser,
                  videoTrack: false,
                },
              };
            }

            if (mediaType === "audio") {
              return {
                ...currentStreams,
                [uid]: {
                  ...publishedUser,
                  audioTrack: false,
                },
              };
            }

            return {
              ...currentStreams,
            };
          });
        });
      } catch (error) {
        console.log("startConnect -> error", error);
      }
    }
  }, [setStreams, cloudGame]);

  const setLocalStreams = useCallback(
    async (props) => {
      const user = getCurrentUser();

      const { localAudioTrack, localVideoTrack } = props;
      localVideoTrack.stamp = moment().valueOf();
      setStreams({
        ...streams,
        [user.id]: {
          videoTrack: localVideoTrack,
          audioTrack: localAudioTrack,
        },
      });
    },
    [streams, setStreams]
  );

  const stopStream = useCallback(async () => {
    const user = getCurrentUser();

    const myStreams = streams[user.id] || {};

    const { videoTrack, audioTrack } = myStreams;
    if (videoTrack) {
      videoTrack.stop();
      await agoraEngine.unpublish(videoTrack);
      audioTrack.stop();
      await agoraEngine.unpublish(audioTrack);
    }
  }, [streams]);

  const turnOnCam = useCallback(async () => {
    const user = getCurrentUser();

    const myStreams = streams[user.id] || {};

    const { videoTrack } = myStreams;

    if (videoTrack) {
      setStreams({
        ...streams,
        [user.id]: {
          ...myStreams,
          videoTrack: false,
        },
      });

      videoTrack.stop();
      await agoraEngine.unpublish(videoTrack);
    } else {
      const trackConfig = getLocalTrackConfig();

      const localVideoTrack = await AgoraRTC.createCameraVideoTrack(
        trackConfig
      );

      await agoraEngine.publish([localVideoTrack]);

      localVideoTrack.stamp = moment().valueOf();
      setStreams({
        ...streams,
        [user.id]: {
          ...myStreams,
          videoTrack: localVideoTrack,
        },
      });
    }
  }, [streams, setStreams]);

  const turnOnMic = useCallback(async () => {

    const user = getCurrentUser();

    const myStreams = streams[user.id] || {};

    const { audioTrack } = myStreams;

    if (audioTrack) {

      setStreams({
        ...streams,
        [user.id]: {
          ...myStreams,
          audioTrack: false,
        },
      });

      audioTrack.stop();
      await agoraEngine.unpublish(audioTrack);
    }else{
      const localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();

      await agoraEngine.publish([localAudioTrack]);

      localAudioTrack.stamp = moment().valueOf();
      setStreams({
        ...streams,
        [user.id]: {
          ...myStreams,
          audioTrack: localAudioTrack,
        },
      });


    }


  }, [streams, setStreams]);

  useEffect(() => {
    if (gameId) {
      startConnect({});
    }
  }, [gameId]);

  useEffect(() => {
    if (streams && cloudGame) {
      console.log(
        "🚀 ~ file: useAgoraStreamers.js:164 ~ useEffect ~ cloudGame:",
        cloudGame
      );
      console.log(
        "🚀 ~ file: useAgoraStreamers.js:164 ~ useEffect ~ streams:",
        streams
      );

      const playerList = Object.keys(cloudGame)
        .map((gameKey) => cloudGame[gameKey])
        .filter((gameSlice) => gameSlice.isPlayerData);
      console.log(
        "🚀 ~ file: useAgoraStreamers.js:170 ~ useEffect ~ playerList:",
        playerList
      );
      const streamersUpdate = playerList
        .map((player) => {
          const { id } = player;
          const playerStream = streams[id];
          return {
            ...player,
            playerStream,
          };
        })
        .filter((player) => player.playerStream);
      console.log(
        "🚀 ~ file: useAgoraStreamers.js:180 ~ streamersUpdate ~ streamersUpdate:",
        streamersUpdate
      );
      setStreamers([...streamersUpdate]);
    }
  }, [streams, cloudGame, setStreamers]);
  return {
    turnOnCam,
    turnOnMic,
    streamers,
    stopStream,
  };
};
