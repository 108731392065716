
import * as THREE from 'three'


export const setRayCasterFromEvent = (event) => {

    const mouse = new THREE.Vector2();
    const raycaster = new THREE.Raycaster();

    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = - (event.clientY / window.innerHeight) * 2 + 1;
    raycaster.setFromCamera(mouse, event.camera);

    return raycaster
}

export const grabInterSec = (event) => {
    const raycaster = setRayCasterFromEvent(event)
    const all = raycaster.intersectObjects([...event.eventObject.parent.children])
    const [one] = all
    return one.point
}

export const pickMeshIntersect = (event, picks = []) => {

    const raycaster = setRayCasterFromEvent(event)
    const intersects = raycaster.intersectObjects([...event.eventObject.parent.children])
    const [one] = intersects.filter((intersect) => picks.includes(intersect.object.name))

    return one
}