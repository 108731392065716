const confirmModalHeight = 300;

const confirmModalratio = 1152 / 648;

const confirmModalWidth = confirmModalHeight * confirmModalratio;

export const ConfirmModal = () => {
  return (
    <div
      className="modal fade"
      id="confirmModal"
      data-bs-keyboard="true"
      tabIndex="-1"
      aria-labelledby="confirmModalTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable cohado-alert">
        <div
          className="modal-content"
          style={{
            backgroundImage: "url(/images/CohadoCelebration2b.gif)",
            backgroundSize: "100% 100%",
            height: confirmModalHeight,
            width: confirmModalWidth,
          }}
        >
          <div
            style={{
              height: 80,
            }}
            className="modal-header d-flex justify-content-center"
          ></div>
          <div className="modal-body d-flex justify-content-center">
            <p
              style={{
                fontFamily: "isocpeur",
                fontSize: 30,
                textAlign: "center",
              }}
            >
              Summary Card Sent!
            </p>
          </div>
          <div className="d-flex justify-content-center">
            <img
              onClick={() => {
                const myModal = new window.bootstrap.Modal(
                  document.getElementById("scoreSummary"),
                  {
                    keyboard: false,
                  }
                );
                myModal.show();
              }}
              style={{
                margin: confirmModalHeight / 25,
                height: confirmModalHeight / 10,
              }}
              data-bs-dismiss="modal"
              src="/images/CohadoX.png"
              type="button"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
