import React from "react";
import { useMemo } from "react";
import { useLoadGlyph } from "./useLoadGlyph";
import { GlyphImageMesh } from "../GlyphImageMesh";
import { getLocalFeature } from "../../../KeyMenu/localFeatureConfig";

const lowFiv1 = getLocalFeature("lowFiv1");

const Glyph3Mesh = (props) => {
  const { glyph } = props;
  const glyphGlb = useLoadGlyph({ glyph });

  return <primitive object={glyphGlb} />;
};

export const GlyphModel = (props) => {
  const {
    boneKey,
    glyph,
    glyphSpin = 1,
    children,
    onPointerDown = () => {},
    onDoubleClick = () => {},
  } = props;

  const position = useMemo(
    () => [0, lowFiv1 ? 5.9 : 4.8, glyphSpin * 4.9],
    [glyphSpin]
  );

  const rotation = useMemo(
    () => [0, glyphSpin > 0 ? Math.PI : 0, 0],
    [glyphSpin]
  );

  return (
    <group
      name={`${boneKey}-glyph_${glyphSpin}`}
      onPointerDown={onPointerDown}
      onDoubleClick={(event) => {
        event.stopPropagation();
        onDoubleClick(event);
      }}
      scale={0.94}
      rotation={rotation}
      position={position}
    >
      {lowFiv1 ? <GlyphImageMesh glyph={glyph} /> : <Glyph3Mesh  glyph={glyph} />}
      {children}
    </group>
  );
};
