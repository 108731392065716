import { useMemo } from "react";
import { useTexture } from "@react-three/drei";
import { getGlyphType } from "../../getGlyphSourceType";

const sideLength = 10;

export const GlyphImageMesh = (props) => {
  const { glyph } = props;

  const { glyphKey } = glyph;

  const textureUrl = useMemo(
    () => getGlyphType(glyphKey.replace("glyph", ""), ""),
    [glyphKey]
  );

  const rotateTexture = useTexture(textureUrl);

  return (
    <mesh>
      <boxGeometry args={[sideLength, 0, sideLength]} />
      <meshBasicMaterial
        map={rotateTexture}
        transparent
      />
    </mesh>
  );
};
