import "./beatmodal.css";

const cohadoHeight = 329;

const ratio = 788 / 329;

const cohadoWidth = cohadoHeight * ratio;

export const BeatMsg = () => {
  return (
    <div
      className="modal fade"
      id="beatmsg"
      data-bs-keyboard="true"
      tabIndex="-1"
      aria-labelledby="beatmsg"
      aria-hidden="true"
    >
      <div
        style={{
          minWidth: cohadoWidth,
        }}
        className="modal-dialog modal-dialog-centered modal-dialog-scrollable beat-modal"
      >
        <div
          className="modal-content summary-drag"
          style={{
            backgroundImage: "url(/images/rustplate2.png)",
            backgroundSize: "100% 100%",
            height: cohadoHeight,
            width: cohadoWidth,
            minWidth: cohadoWidth,
            backgroundColor: "transparent",
            paddingBottom: 50,
            paddingLeft: 50,
            paddingRight: 50,
            paddingTop: 30,
          }}
        >
          <div
            className="modal-header d-flex justify-content-center m-o p-0 border-0"
            style={{
              height: "48%",
            }}
          >
            <img
              className="mx-3"
              style={{
                height: cohadoHeight / 10,
              }}
              src="/images/CohadoX.png"
              type="button"
              data-bs-dismiss="modal"
            />
          </div>
          <div
            className="modal-body"
            style={{
              position: "relative",
              height: "60%",
              flexShrink: 0,
            }}
          >
            The community has taken a beat
          </div>
        </div>
      </div>
    </div>
  );
};
