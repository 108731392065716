import { getMainScene } from '../CohadoBoardMesh'

export const updateParent = (boneMeshRef, parentId) => {
  const { parent } = boneMeshRef;
  if (!parent || parent.name !== parentId) {
    const scene = getMainScene()
    const nextParent = scene.children.find(c => c.name === parentId);
    if (nextParent) {
      if (parent) {
        parent.remove(boneMeshRef);
      }
      try {
        nextParent.remove(boneMeshRef);
      } catch (error) {
        console.log("🚀 ~ updateParent ~ error:", error)
      }

      nextParent.add(boneMeshRef);
      return nextParent
    }
  }
};
