import { ScoreDigitGroup } from "../ThreeHado/ActionBar/ScoreDigitGroup";


export const SummaryScoreBoard = (props) => {
  const { summaryCardData = {} } = props;

  const { pointSections = {}, beats = 0, coe = 0 } = summaryCardData;

  const {
    assets = { total: 0 }, waste = { total: 0 }, una = { total: 0 },
  } = pointSections;
  return (
    <div
      className="scoreback-summary-card unselectable"
      style={{
        position: "relative",
        display: "flex",
        margin: 12,
        backgroundImage: "url(/images/card_nixie_tube_background.png)",
        backgroundColor: "transparent",
        backgroundSize: "100% 100%",
      }}
    >
      <ScoreDigitGroup title="ASSETS" number={assets.total} digitCount={3} />
      <ScoreDigitGroup title="WASTE" number={waste.total} digitCount={3} />
      <ScoreDigitGroup title="BEATS" number={beats} digitCount={2} />
      <ScoreDigitGroup title="UNA" number={una.total} digitCount={3} />
      <ScoreDigitGroup title="COE" number={coe} digitCount={3} />
    </div>
  );
};
