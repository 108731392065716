import { useMemo } from "react";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { useLoader } from "@react-three/fiber";

const wholeHexUrl = "/glbs/wholehex.glb";

export const useLoadWholeHex = (props ={}) => {
  const { mode } = props;
  const wholeHexlglb = useLoader(GLTFLoader, wholeHexUrl);

  const wholeHexGroup = useMemo(() => {
    const { scene } = wholeHexlglb;
    const clone = scene.clone();

    if (mode === "low") {
      const grid = clone.children[1];
      grid.material.metalness = 0;
    }

    return clone;
  }, [wholeHexlglb, mode]);

  return wholeHexGroup;
};
