import { useEffect, useState } from 'react';
import interact from 'interactjs';


export const useInteactConfig = (props) => {

    const [onEndState, setOnEndState] = useState(undefined);
    const { className = '.draggable' } = props;
    useEffect(() => {
        interact(className)
            .draggable({
                // enable inertial throwing
                inertia: false,
                // enable autoScroll
                autoScroll: true,
                listeners: {
                    // call this function on eßvery dragmove event
                    move: dragMoveListener,
                    // call this function on every dragend event
                    end: (event) => {
                        setOnEndState(event)
                    }
                }
            });
        return () => {
            interact(className).unset();
        };
    }, []);

    return onEndState
};

function dragMoveListener(event) {
    var target = event.target
    // keep the dragged position in the data-x/data-y attributes
    var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx
    var y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy
  
    // translate the element
    target.style.transform = 'translate(' + x + 'px, ' + y + 'px)'
  
    // update the posiion attributes
    target.setAttribute('data-x', x)
    target.setAttribute('data-y', y)
  }
  
  // this function is used later in the resizing and gesture demos
  window.dragMoveListener = dragMoveListener
  