export const quotes = [
    {
        "text": "Create abundance for the community, and eliminate waste of any kind. Then the world will flourish.",
        "author": "Paulo Gregory"
    },
    {
        "text": "We have a responsibility to utilize our resources, connections, voice, energy, and power to usher in this age of relationship, understanding, and connectedness.",
        "author": "Paulo Gregory"
    },
    {
        "text": "‘Divide and conquer’ in our world must become ‘define and empower.’",
        "author": "Audre Lorde"
    },
    {
        "text": "The world as we have created it is a process of our thinking. It cannot be changed without changing our thinking.",
        "author": "Albert Einstein"
    },
    {
        "text": "Courage may be the most important of all virtues, because  without it one cannot practice any other virtue with consistency.",
        "author": "Maya Angelou"
    },
    {
        "text": "Each of us contains both shadow and light. It is the balance of these aspects of ourselves that make us who we are.",
        "author": "Paulo Gregory"
    },
    {
        "text": "Relationships are how we see ourselves, and the vehicle through which we support and give to others.",
        "author": "Paulo Gregory"
    },
    {
        "text": "We are only as strong as we are united, as weak as we are divided. Differences of habit and language are nothing at all if our aims are identical and our hearts are open.",
        "author": "JK Rowling"
    },
    {
        "text": "It is the womblike darkness of the earth that enables the seed to evolve and bring forth life. It is often that which we hide that has the most power to support the creativity and inspiration to produce fruit, if we have the courage to, embrace it.",
        "author": "Paulo Gregory"
    },
    {
        "text": "The tipping point is that magic moment when an idea, trend, or social behavior crosses a threshold, tips, and spreads like wildfire.",
        "author": "Malcolm Gladwell"
    },
    {
        "text": "Pollution is nothing but the resources we are not harvesting. We allow them to disperse because we’ve been ignorant of their value.",
        "author": "Buckminster Fuller"
    },
    {
        "text": "Work as copponents, not as opponents, to lift the collective assets as high as we can.",
        "author": "Paulo Gregory"
    },
    {
        "text": "It’s easy to create assets in every moment, if we pause, get real, look at the broader context, and act out of a clear values system that sees the world as one living being.",
        "author": "Paulo Gregory"
    },
    {
        "text": "An ally is one with the courage to stand in support of someone, particularly when there is a cost to doing so. We can all be allies when we use our tenuous, yet powerful, footing to build bridges instead of walls.",
        "author": "Paulo Gregory"
    },
    {
        "text": "You never change things by fighting the existing reality. To change something, build a new model that makes the existing model obsolete.",
        "author": "Buckminster Fuller"
    },
    {
        "text": "It is up to us to keep building bridges to bring the world closer together. We can pave new roads towards peace simply by understanding other cultures.",
        "author": "Suzy Kassem"
    },
    {
        "text": "Momentum is the force that carries us to manifestation. Momentum can, at times, be hidden in what we see as our failures. After all, running is actually ‘falling forward’.",
        "author": "Paulo Gregory"
    },
    {
        "text": "Collaboration toward the common good is the definition of sustainability.",
        "author": "Paulo Gregory"
    },
    {
        "text": "We are all connected and responsible for each other’s well being.",
        "author": "Paulo Gregory"
    },
    {
        "text": "We are all part of a larger ecosystem that our movement supports, and supports our movement. We call this Community.",
        "author": "Paulo Gregory"
    },
    {
        "text": "We must begin by transforming our mental models from one of win/lose, to a regenerative system that evolves expanding and sharing resources that distributes wealth and power, ensuring that we manifest Unbuntu.",
        "author": "Paulo Gregory"
    },
    {
        "text": "The entire purpose of why we are all here is to communicate, to learn to see the perspective of another. To have the desire and intention of seeing another’s perspective is love. And love is what and who we are.",
        "author": "Paulo Gregory"
    },
    {
        "text": "There is beauty and power in unity. We must be united in heart and mind. One world, one people. Lailah",
        "author": "Gifty Akita"
    },
    {
        "text": "If everyone helps to hold up the sky, then one person does not become tired. Askhari",
        "author": "Johnson Hodari"
    },
    {
        "text": "To many of us who attain what we may and forget those who help us along the line — we’ve got to remember that there are so many others to pull along the way. The further they go, the further we all go.",
        "author": "Jackie Robinson"
    },
    {
        "text": "All life is interrelated. We are inevitably our brother’s keeper because we are our brother’s brother. Whatever affects one directly affects all indirectly.",
        "author": "Martin Luther King Jr"
    },
    {
        "text": "We’re in this together. If we united and interculturally cooperated, then that might be the key to humanity’s survival.",
        "author": "Jeremy Gilley"
    },
    {
        "text": "Individuals and organizations must function in a mode of inquiry, knowing that nobody knows and everybody can learn continually... this requires communities of commitment where people are continually learning how to learn together.",
        "author": "Peter Senge"
    },
    {
        "text": "The collective WE is as important as the individual me — because it is the same being.",
        "author": "Paulo Gregory"
    },
    {
        "text": "Imagine we were all collectively taught that we are all cells in a huge and wondrous body. And like a body, we work in a synergistic dance to elevate and sustain us all. What would this world look like?",
        "author": "Paulo Gregory"
    }
]