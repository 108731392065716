import { GuidelinesCollapse } from "./GuidelinesCollapse";
import { OriginCollapse } from "./OriginCollapse";
import { StreamSettingsCollapse } from "./StreamSettingsCollapse";
import { FeatureSettingsCollapse } from "./FeatureSettings";

import "./keyMenu.css";

import { auth, getCurrentUser } from "../../firebase";
import { CohadoMapModal } from "./CohadoMapModal";
import { openCohadoMap } from "./CohadoMapModal/openCohadoMap";
import { initKeyMenuDrag } from "./useKeyMenuDrag";

const onSignOut = () => {
  auth.signOut();
};
const keyMenuHeight = 800;

const keymenuRatio = 1080 / 1772;

const keyMenuWidth = keyMenuHeight * keymenuRatio;

initKeyMenuDrag();

export const KeyMenu = (props) => {
  const {
    rotate = () => {},
    inGame,
    callPieceOutForPlayer = () => {},
    soladoMode,
  } = props;

  return (
    <>
      <div
        className="modal fade"
        id="keyMenu"
        data-bs-keyboard="true"
        tabIndex="-1"
        aria-labelledby="keyMenuTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-dialog-scrollable cohado-alert "
          style={{
            touchAction: "none",
          }}
        >
          <div
            className="modal-content keymenu-drag"
            style={{
              height: keyMenuHeight,
              width: keyMenuWidth,
              backgroundColor: "transparent",
              overflow: "visible",
            }}
          >
            <img
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                openCohadoMap();
              }}
              className="backkey"
              style={{
                position: "absolute",
                top: -23,
                width: 500,
                left: 65,
                zIndex: 1,
                // marginBottom:0
              }}
              src="/images/backkeymenu_map.png"
              type="button"
            />

            <div
              className="keyMenuPlatform px-3 d-flex flex-column"
              style={{
                position: "relative",
                zIndex: 20,
                backgroundImage: "url(/images/KeyMenu.png)",
                backgroundSize: "100% 100%",
                height: keyMenuHeight,
                width: keyMenuWidth,
                backgroundColor: "transparent",
                overflow: "scroll",
                paddingBottom: 40,
              }}
            >
              <div className="modal-header d-flex justify-content-center m-0 p-0">
                <img
                  className="keyImage"
                  style={{
                    height: 100,
                  }}
                  src="/images/paperkey.png"
                  type="button"
                  data-bs-dismiss="modal"
                />
              </div>
              <div
                style={{
                  overflow: "scroll",
                }}
                className="modal-body d-flex flex-column pt-0"
              >
                <GuidelinesCollapse />

                <OriginCollapse />

                {inGame && (
                  <p
                    className="key-menu-item"
                    onClick={() => {
                      window.location.href = "/dash";
                    }}
                  >
                    Dashboard
                  </p>
                )}

                {/* {inGame && (
                  <p
                  data-bs-dismiss="modal"

                    className="key-menu-item"
                    onClick={() => {
                      const myModal = new window.bootstrap.Modal(
                        document.getElementById("boardImgModal"),
                        {
                          keyboard: false,
                          backdrop: false
                        }
                      );
                      myModal.show();
                    }}
                  >
                    Board Image
                  </p>
                )} */}

                {/* <p
              className="key-menu-item"
              onClick={() => {
                if (window.canRotate) {
                  window.canRotate = false

                } else {

                  window.canRotate = true

                }
              }}
            >
              Rotate
            </p> */}
                {inGame && (
                  <>
                    <p
                      data-bs-toggle="collapse"
                      data-bs-target=".account-collapse"
                      aria-expanded="false"
                      aria-controls="account-collapse"
                      className="key-menu-item"
                      onClick={() => {}}
                    >
                      Account
                    </p>
                    <div
                      className="collapse info-collapse account-collapse"
                      id="account-collapse"
                    >
                      <div className="card card-body">
                        <p className="text-start m-0">
                          {getCurrentUser().email}
                        </p>
                      </div>
                    </div>
                  </>
                )}

                {inGame && !soladoMode && (
                  <p
                    className="key-menu-item"
                    onClick={() => {
                      const myModal = new window.bootstrap.Modal(
                        document.getElementById("pieceoutcheck"),
                        {
                          keyboard: false,
                        }
                      );
                      myModal.show();
                    }}
                  >
                    Piece Out
                  </p>
                )}

                {inGame && (
                  <p
                    className="key-menu-item"
                    onClick={() => {
                      onSignOut();
                    }}
                  >
                    Log Out
                  </p>
                )}
                <p className="key-menu-item unactive" onClick={() => {}}>
                  Well of Bones
                </p>
                <p className="key-menu-item unactive" onClick={() => {}}>
                  Terms of Use
                </p>
                <StreamSettingsCollapse />
                <FeatureSettingsCollapse />
              </div>
              <div className="d-flex">
                <img
                  style={{
                    margin: keyMenuHeight / 50,
                    height: keyMenuHeight / 25,
                  }}
                  src="/images/CohadoX.png"
                  type="button"
                  data-bs-dismiss="modal"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <BoardImgModal/> */}
      <CohadoMapModal />
      <div
        className="modal fade pieceoutcheck"
        id="pieceoutcheck"
        data-bs-keyboard="true"
        tabIndex="-1"
        aria-labelledby="pieceoutcheck"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div
            className="modal-content"
            style={{
              backgroundImage: "url(/images/paper01.png)",
              backgroundSize: "100% 100%",
              padding: 20,
              backgroundColor: "transparent",
            }}
          >
            <div className="modal-header border-0  ">
              <h5 className="modal-title w-100 text-center">
                peace be with you
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body p-0 d-flex flex-column align-items-center justify-content-center">
              <p>may your well be abundant</p>
            </div>
            <div className="modal-footer border-0 justify-content-between">
              <button
                data-bs-dismiss="modal"
                style={{
                  flexGrow: 0,
                  position: "relative",
                  backgroundImage: "url(/images/paperforbutton1.png)",
                  backgroundColor: "transparent",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100% 100%",
                  border: 0,
                  textAlign: "center",
                  padding: "6px 12px",
                }}
              >
                cancel
              </button>
              <button
                data-bs-dismiss="modal"
                onClick={() => {
                  callPieceOutForPlayer();
                }}
                style={{
                  flexGrow: 0,
                  position: "relative",
                  backgroundImage: "url(/images/paperforbutton2.png)",
                  backgroundColor: "transparent",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100% 100%",
                  border: 0,
                  textAlign: "center",
                  padding: "6px 12px",
                }}
              >
                piece out
              </button>
            </div>
          </div>
        </div>
      </div>
 
    </>
  );
};
