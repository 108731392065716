let audioPlayer;

export const playAudio = (url) => {
  if (audioPlayer) {

    audioPlayer.pause();

    const same = audioPlayer.src.indexOf(url) > -1;

    audioPlayer = undefined;
    if (same) {
      return;
    }
  }

  audioPlayer = new Audio(url);

  audioPlayer.play();

};

export const stopAudio = (url) => {
  if (audioPlayer) {
    audioPlayer.stop();
    audioPlayer = undefined;
  }
};
