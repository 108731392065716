import { createNewGame } from './createNewGame';

export const startNewCloudGame = async (props, nav) => {
    const cloudGameResult = await createNewGame(props);
    const { id } = cloudGameResult;

    if (nav) {
        window.location.href = `/three?gameId=${id}`;
    }
};
