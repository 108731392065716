export const buildDrawBones = (props = {}) => {
  const { well } = props;
  const size = 1;
  const scale = .1;
  const a = Math.PI / 3;
  const hexRadius = size * scale;
  const rToHeightFac = 2 * Math.sin(a);
  const height = hexRadius * rToHeightFac;

  const diameter = hexRadius * 2;
  const next = diameter * 3 / 4;
  const xoffset = 7 * -next / 2
  const yoffset = -7 * (height / 4);

  let runX = 0 //xoffset;
  let runY = 0// yoffset;
  const draws = well.map((bone, i) => {
    const imod7 = i % 4;
    runX = (imod7 * (next * 2)) + xoffset+ .078;;
    runY = (Math.floor(i / 4) * (height)) + yoffset - .223;
    const newDrop = { ...bone, initPosition: { xGridPoint: runX, zGridPoint: runY, rotation: -Math.PI / 3 } };
    return {
      ...newDrop
    };
  });

  return draws
};
