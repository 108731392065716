import { useState } from "react";

export const MetaGameCollapse = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <p className="key-menu-item fw-bolder">
        <img
          onClick={() => {
            setOpen(!open);
          }}
          data-bs-toggle="collapse"
          data-bs-target=".metagame-collapse"
          aria-expanded="false"
          aria-controls="metagame-collapse"
          src="images/closemenuarrow.png"
          style={{
            position: "relative",
            width: 25,
            marginRight: 6,
            transition: "rotate .5s ease",
            rotate: `${open ? "180deg" : "0deg"}`,
          }}
        />
        <span
          onClick={() => {
            setOpen(!open);
          }}
          data-bs-toggle="collapse"
          data-bs-target=".metagame-collapse"
          aria-expanded="false"
          aria-controls="metagame-collapse"
        >
          The METAgame
        </span>
      </p>
      <div
        className="collapse info-collapse metagame-collapse sub-collapse"
        id="metagame-collapse"
      >
        <div className="card card-body">
          <p>
            The game on the table is a tool — a metaphor, the real game occurs
            through how we interact with each other as we play. Since the work
            is to function as a collaborative whole, how we interact is
            critically important, as this is also a representation of how we
            interact in the world. This is the METAgame. As an example, just as
            if we as a culture leave people hanging, unable to thrive, or even
            survive, this impacts the whole culture, socially, economically, and
            spiritually. In an individualist culture, this reality is ignored,
            as it does not fit in the model of the win/lose game. In a Cohadic
            culture, it is essential that we care for each member of the
            community so that they can bring their assets to the whole, and thus
            everyone wins.
          </p>

        </div>
      </div>
    </>
  );
};
