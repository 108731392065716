import { useCallback, useState } from "react";
import { useDragBoardImg } from "./useDragBoardImg";
import { getBoardCanvasMiniCloneImageData } from "./canvasImage";
import { sleep } from "../firebase/sleep";

export const BoardImgControl = (props) => {
  useDragBoardImg();
  const {
    endGame,
    showBoardImgControl,
    setHidePlatforms,
    onClickSaveSummaryImage = () => {},
    setShowBoardImgControl = () => {},
  } = props;

  const [sending, setSending] = useState(false);
  const [cloneDataUrl, setCloneDataUrl] = useState("");

  const onClickTakeImage = useCallback(async () => {
    setHidePlatforms(true);

    await sleep(1000);
    const miniData = await getBoardCanvasMiniCloneImageData();
    const { cloneDataUrl: newDataUrl } = miniData;
    setHidePlatforms(false);

    setCloneDataUrl(newDataUrl);
  }, [setCloneDataUrl, setHidePlatforms]);

  const clickSummaryCard = useCallback(async () => {
    setSending(true);
    await onClickSaveSummaryImage({ cloneDataUrl });
    setSending(false);
    setShowBoardImgControl(false);
  }, [setSending, cloneDataUrl, setShowBoardImgControl]);

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      style={{
        zIndex: showBoardImgControl ? 989 : 0,
        backgroundColor: "#2a2c2c",
        minHeight: 300,
        width: 250,
        position: "absolute",
      }}
      className="board-img-con d-flex flex-column"
    >
      <div className="flex-1">
        <img style={{ maxWidth: "100%" }} src={cloneDataUrl} alt="" />
      </div>
      <button
        className="my-1"
        onClick={() => {
          onClickTakeImage();
        }}
        style={{
          flexGrow: 0,
          position: "relative",
          backgroundImage: "url(/images/paperforbutton2.png)",
          backgroundColor: "transparent",
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
          border: 0,
          textAlign: "center",
          padding: "6px 12px",
        }}
      >
        take Image
      </button>
      <button
        className="my-1"
        onClick={() => {
          setHidePlatforms((hidePlatform) => !hidePlatform);
        }}
        style={{
          flexGrow: 0,
          position: "relative",
          backgroundImage: "url(/images/paperforbutton2.png)",
          backgroundColor: "transparent",
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
          border: 0,
          textAlign: "center",
          padding: "6px 12px",
        }}
      >
        toggle bone bar
      </button>
      {!!cloneDataUrl && (
        <button
          className="my-1"
          onClick={() => {
            if (!sending) {
              clickSummaryCard();
            }
          }}
          style={{
            flexGrow: 0,
            position: "relative",
            backgroundImage: "url(/images/paperforbutton2.png)",
            backgroundColor: "transparent",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
            border: 0,
            textAlign: "center",
            padding: "6px 12px",
          }}
        >
          {sending ? "sending data ..." : "save board image"}
        </button>
      )}

      <button
        className="my-1"
        onClick={() => {
          setShowBoardImgControl(false);
        }}
        style={{
          flexGrow: 0,
          position: "relative",
          backgroundImage: "url(/images/paperforbutton2.png)",
          backgroundColor: "transparent",
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
          border: 0,
          textAlign: "center",
          padding: "6px 12px",
        }}
      >
        close
      </button>
    </div>
  );
};
