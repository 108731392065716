



import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

export function UseSort(props) {

    const { id, children,...pass } = props
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        < >
            {
                React.cloneElement(children, {
                    ...pass,
                    ...attributes,
                    ...listeners,
                    style: {
                        ...(children.props.style || {}),
                        ...style
                    },
                    ref: setNodeRef
                })
            }
        </>
    );
}