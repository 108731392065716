import React, { useMemo, useEffect, useState } from "react";
import "./style.css";
import { initInterActConfig } from './initInterActConfig';
import { AgoraStreamerWindow } from "./AgoraStreamerWindow";
import firebase from "../../firebase";
import { useAgoraStreamers } from "./useAgoraStreamers";

export const VideoChatAgora = (props) => {
  const { open, onClose = () => { }, cloudGame } = props;
  const { streamers = [], turnOnCam, turnOnMic, stopStream } = useAgoraStreamers({ cloudGame })

  useEffect(() => {
    initInterActConfig()
  }, []);

  const players = useMemo(() => {
    if (cloudGame) {
      const {
        playerOrderList = [],
        playerHands = {},
        setShowHand: saveShowHand = () => { },
        cohadoStates = {},
        turn = {},
      } = cloudGame;
      const { boneStates = {} } = cohadoStates;

      const { id: turnPlayerId } = turn;
      const playersData = playerOrderList.map((playerOrderData) => {
        const playerStreamData =
          streamers.find((streamer) => streamer.id === playerOrderData.id) ||
          {};
        const playerData = cloudGame[playerOrderData.id] || {};
        const { email, showHand } = playerData;

        const { playerStream = {} } = playerStreamData;

        const playerHand = playerHands[playerOrderData.id] || [];

        const localHandBones = playerHand
          .filter((bone) => {
            return !boneStates[bone.boneKey];
          })
          .map((bone) => {
            return {
              ...bone,
            };
          });

        const isMyStream =
          firebase.auth().currentUser.uid === playerOrderData.id;

        const setShowHand = isMyStream ? saveShowHand : undefined;

        return {
          id: playerOrderData.id,
          isPlayersTurn: turnPlayerId == playerOrderData.id,
          setShowHand,
          isMyStream,
          email,
          showHand,
          localHandBones,
          playerStream,
          screenName:playerOrderData.screenName
        };
      });

      return playersData;
    }
    return [];
  }, [cloudGame, streamers]);

  const streamSpace = useMemo(() => players.find(streamer => streamer.playerStream.videoTrack), [players])

  const [streamerslayout, setStreamerslayout] = useState(true);

  return open ? (
    <div
      className={`video-chat-container d-flex flex-column resize-drag ${streamerslayout ? 'wide-layout' : 'vert-layout'}`}
      style={{
        position:'absolute',
        top:100,
        left:0,
        overflow:'visible',
        backgroundImage: "url(/images/glasswithborder2.png)",
        backgroundSize: "100% 100%",
        width:'100%',
        maxHeight:'100%'
      }}
    >
      <div
        className="streams-scroll position-relative"
      >
        {players.map((streamerData, playerIndex) => {
          return (
            <AgoraStreamerWindow
              stopStream={stopStream}
              turnOnCam={turnOnCam}
              turnOnMic={turnOnMic}
              playerNumber={playerIndex + 1}
              key={streamerData.id}
              {...streamerData}
            />
          );
        })}
      </div>
      <div
        style={{
          position: 'relative',
          top: -20,
          marginBottom: -20,
        }}
        className="d-flex justify-content-center align-items-center" >
        <img
          onClick={onClose}
          src="images/exit.png"
          style={{
            position: "relative",
            height: 50,
            width: 60,
            flex: 'none',
          }}
        />

        {/* <button
          onClick={() => {
            setStreamerslayout(!streamerslayout)
          }}
          style={{
            backgroundImage: "url(/images/paperforbutton1.png)",
            backgroundSize: "100% 100%",
            backgroundColor: "transparent",
            padding: "-0px 12px",
            border: 0,
            textAlign: "center",
            textDecoration: "none",
            color: "black",
            flex: 'none',
            height: 35
          }}
        >
          layout
        </button> */}
      </div>
    </div>
  ) : (
    <></>
  );
};
