import { useTexture } from '@react-three/drei';

export const BaseWellMesh = (props) => {
    const boneBarTexture = useTexture('./images/welltray.png');

    const { children, scale = 1 } = props
    return (
        <mesh
            scale={scale}
            position={[-.078, .1, .22]}
            name='floorwood'
        >
            <boxGeometry
                args={[1.6, .03, 1.6]} />
            <meshBasicMaterial
                attach="material"
                map={boneBarTexture}
                transparent />

            <mesh
                position={[0,.06,0]}
                name='floorwoodch'
            >

                {children}
            </mesh>
        </mesh>
    );
};
