import { useMemo } from "react";
import { ScoreDigit } from "./ScoreDigit";

export const ScoreDigitGroup = (props) => {
  const { number = 0, digitCount = 1, title = "word" } = props;

  const scoreDigits = useMemo(() => {
    const digitsAtIndex = `${number}`.split("");

    const minDigitCoun = Math.max(digitsAtIndex.length, digitCount);

    const zeros = Math.max(minDigitCoun - digitsAtIndex.length, 0);

    const pick = [
      ...new Array(zeros).fill("0"),
      ...new Array(digitsAtIndex.length)
        .fill("")
        .map((x, i) => digitsAtIndex[i]),
    ];

    return pick.map((digitChar) => {
      return {
        digitChar,
      };
    });
  }, [number, digitCount]);

  return (
    <div
      className="scoredigitgroup"
      style={{
        position: "relative",
        margin: 6
      }}
    >
      <div className="d-flex pb-1">
        <div
          className="linediv position-relative"
          style={{
            flex: 1,
            borderTop: "1px solid white",
            borderLeft: "1px solid white",
          }}
        />
        <div
          className="linediv position-relative linediv-title"
          style={{
            flex: 1,
            color: 'white',
            top: -3,
            margin: '0 6px',
            fontFamily: 'isocpeur',
            fontSize: 12,
            lineHeight: 1
          }}>
          {title}
        </div>
        <div
          className="linediv position-relative"
          style={{
            flex: 1,
            borderTop: "1px solid white",
            borderRight: "1px solid white",
          }}
        />
      </div>

      <div
        className="d-flex justify-content-center"
        style={{
          position: "relative",
        }}
      >
        {scoreDigits.map((scoreDigit, i) => {
          return <ScoreDigit key={i} scoreDigit={scoreDigit} />;
        })}
      </div>
    </div>
  );
};
