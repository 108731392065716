import interact from 'interactjs';

let mapPinchScale = 1;

export const initKeyMenuDrag = (props) => {
    interact('.keymenu-drag')
    .gesturable({
      listeners: {
        move(event) {
          keyMenuDragMoveListener(event);
        },
        end(event) {
          mapPinchScale = mapPinchScale * event.scale;
        },
      },
    })
    .draggable({
      listeners: { move: keyMenuDragMoveListener },
    });
};

function keyMenuDragMoveListener(event) {

    const target = event.target;

    const currentScale = event.scale
      ? event.scale * mapPinchScale
      : mapPinchScale;

      const scaleTranform = `scale(${currentScale})`;

    const x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx
    const y = (parseFloat(target.getAttribute('data-y')) || 0) 


    const translate = "translate(" + x + "px, " + y + "px)";

    target.style.transform = `${translate} ${scaleTranform}`;

    target.setAttribute('data-x', x)
    target.setAttribute('data-y', y)

}