import { useState } from "react";

import { firestore, auth } from "../firebase";
import moment from "moment";
import "./feedback.css";
import { FeedBackThankYouModal } from "./FeedBackThankYouModal";

const cohadoHeight = 1014;

const ratio = 1014 / 1871;

const cohadoWidth = cohadoHeight * ratio;

export const CommunityFeedBackPopUp = () => {
  const [feedBackText, setFeedBackText] = useState("");
  const [feedBackType, setFeedBackType] = useState("N/A");

  const [quoteText, setQuoteText] = useState("");
  const [quoteAuthor, setQuoteAuthor] = useState("");

  const changeFeedBackType = (event) => {
    const {
      target: { id },
    } = event;
    setFeedBackType(id);
  };

  const sendCommunityFeedBack = async () => {
    const myModal = new window.bootstrap.Modal(
      document.getElementById("feedBackThankYou"),
      {
        keyboard: false,
      }
    );
    myModal.show();

    const currentUser = auth.currentUser;
    const stamp = moment();
    const searchParams = new URLSearchParams(window.location.search);
    const gameId = searchParams.get("gameId");
    const date = stamp.format("LLL");
    if (feedBackText) {
      await firestore.collection("communityFeedback").add({
        uid: currentUser.uid,
        gameId,
        email: currentUser.email,
        feedBackText,
        feedBackType,
        stamp: stamp.valueOf(),
        date,
      });
      setFeedBackText("");
      setFeedBackType("");
    }
  };

  const sendQuote = async () => {
    const myModal = new window.bootstrap.Modal(
      document.getElementById("feedBackThankYou"),
      {
        keyboard: false,
      }
    );
    myModal.show();

    const currentUser = auth.currentUser;
    const stamp = moment();

    const date = stamp.format("LLL");
    if (quoteText && quoteAuthor) {
      await firestore.collection("communityQuotes").add({
        uid: currentUser.uid,
        email: currentUser.email,
        quoteText,
        quoteAuthor,
        stamp: stamp.valueOf(),
        date,
      });

      setQuoteText("");
      setQuoteAuthor("");
    }
  };

  return (
    <>
      <div
        className="modal fade"
        id="communityFeedBackPopUp"
        data-bs-keyboard="true"
        tabIndex="-1"
        aria-labelledby="communityFeedBackPopUp"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable communityFeedBackPopUp">
          <div
            className="modal-content"
            style={{
              backgroundImage: "url(/images/scoresummary2.png)",
              backgroundSize: "100% 100%",
              height: cohadoHeight,
              width: cohadoWidth,
              backgroundColor: "transparent",
            }}
          >
            <div className="modal-header d-flex justify-content-center">
              <img
                style={{
                  height: 55,
                }}
                src="/images/feedbacktext.png"
                alt=""
              />
            </div>

            <div
              className="modal-body d-flex flex-column"
              style={{
                paddingLeft: 20,
              }}
            >
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="feedback-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#feedback"
                    type="button"
                    role="tab"
                    aria-controls="feedback"
                    aria-selected="true"
                  >
                    Feedback
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="quote-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#quote"
                    type="button"
                    role="tab"
                    aria-controls="quote"
                    aria-selected="false"
                  >
                    Quote
                  </button>
                </li>
              </ul>
              <div
                className="tab-content"
                id="myTabContent"
                style={{
                  flex: 1,
                }}
              >
                <div
                  className="tab-pane fade show active h-100"
                  id="feedback"
                  role="tabpanel"
                  aria-labelledby="feedback-tab"
                >
                  <div className=" d-flex flex-column h-100">
                    <div
                      style={{
                        flex: 1,
                      }}
                    >
                      <textarea
                        value={feedBackText}
                        onChange={({ target: { value } }) => {
                          setFeedBackText(value);
                        }}
                        className="w-100 h-100 form-control"
                        style={{
                          backgroundColor: "transparent",
                          color: "white",
                        }}
                      />
                    </div>

                    <div className="w-100 p-1 d-flex justify-content-start flex-column">
                      <label
                        htmlFor="Feelings"
                        className="feedbacktype d-flex justify-content-start my-2"
                      >
                        <input
                          className="me-3"
                          id="Feelings"
                          type="radio"
                          name="feedbacktype"
                          onChange={changeFeedBackType}
                        />
                        <span>Feelings </span>
                      </label>
                      <label
                        htmlFor="Ideas"
                        className="feedbacktype d-flex justify-content-start my-2"
                      >
                        <input
                          className="me-3"
                          id="Ideas"
                          type="radio"
                          name="feedbacktype"
                          onChange={changeFeedBackType}
                        />
                        <span>Ideas </span>
                      </label>
                      <label
                        htmlFor="Issues"
                        className="feedbacktype d-flex justify-content-start my-2"
                      >
                        <input
                          className="me-3"
                          id="Issues"
                          type="radio"
                          name="feedbacktype"
                          onChange={changeFeedBackType}
                        />
                        <span>Issues </span>
                      </label>
                      <label
                        htmlFor="Props/Accolades"
                        className="feedbacktype d-flex justify-content-start my-2"
                      >
                        <input
                          className="me-3"
                          id="Props/Accolades"
                          type="radio"
                          name="feedbacktype"
                          onChange={changeFeedBackType}
                        />
                        <span>Props/Accolades </span>
                      </label>
                      <label
                        htmlFor="Other"
                        className="feedbacktype d-flex justify-content-start my-2"
                      >
                        <input
                          className="me-3"
                          id="Other"
                          type="radio"
                          name="feedbacktype"
                          onChange={changeFeedBackType}
                        />
                        <span>Other </span>
                      </label>
                    </div>

                    <div className="d-flex justify-content-between align-items-center">
                      <button
                        onClick={() => {
                          sendCommunityFeedBack();
                        }}
                        data-bs-dismiss="modal"
                        style={{
                          backgroundImage: "url(/images/paperforbutton3.png)",
                          backgroundSize: "100% 100%",
                          backgroundColor: "transparent",
                          padding: "6px 12px",
                          border: 0,
                          textAlign: "center",
                          padding: "18px 18px",
                          textDecoration: "none",
                          color: "black",
                          margin: 12,
                        }}
                      >
                        Send Offerings
                      </button>

                      <img
                        style={{
                          margin: cohadoHeight / 30,
                          height: cohadoHeight / 25,
                        }}
                        src="/images/CohadoX.png"
                        type="button"
                        data-bs-dismiss="modal"
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade h-100"
                  id="quote"
                  role="tabpanel"
                  aria-labelledby="quote-tab"
                >
                  <div className="d-flex flex-column h-100">
                    <span>Quote Text:</span>
                    <div
                      style={{
                        flex: 2,
                      }}
                    >
                      <textarea
                        value={quoteText}
                        onChange={({ target: { value } }) => {
                          setQuoteText(value);
                        }}
                        className="w-100 h-100 form-control"
                        style={{
                          backgroundColor: "transparent",
                          color: "white",
                        }}
                      />
                    </div>
                    <span>Author</span>
                    <div
                      style={{
                        flex: 1,
                      }}
                    >
                      <textarea
                        value={quoteAuthor}
                        onChange={({ target: { value } }) => {
                          setQuoteAuthor(value);
                        }}
                        className="w-100 h-100 form-control"
                        style={{
                          backgroundColor: "transparent",
                          color: "white",
                        }}
                      />
                    </div>

                    <div className="d-flex justify-content-between align-items-center">
                      <button
                        onClick={() => {
                          sendQuote();
                        }}
                        data-bs-dismiss="modal"
                        style={{
                          backgroundImage: "url(/images/paperforbutton3.png)",
                          backgroundSize: "100% 100%",
                          backgroundColor: "transparent",
                          padding: "6px 12px",
                          border: 0,
                          textAlign: "center",
                          padding: "18px 18px",
                          textDecoration: "none",
                          color: "black",
                          margin: 12,
                        }}
                      >
                        Send Quote
                      </button>

                      <img
                        style={{
                          margin: cohadoHeight / 30,
                          height: cohadoHeight / 25,
                        }}
                        src="/images/CohadoX.png"
                        type="button"
                        data-bs-dismiss="modal"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FeedBackThankYouModal />
    </>
  );
};
