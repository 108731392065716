import { useState } from "react";
import { firestore } from "../../firebase";

const communityFeedbackRef = firestore.collection("communityFeedback");
const getFeedbackForGameId = async (props) => {

  const { gameId } = props;

  const found = await communityFeedbackRef.where('gameId', '==', gameId).get();

  const { docs = [] } = found;

  return docs[0];
};

const fecthFeedbackByDate = async (props) => {

  const { start, end } = props;

  const usersResults = await communityFeedbackRef
    .where('stamp', '>=', start)
    .where('stamp', '<=', end)
    .orderBy('stamp', 'desc')
    .get();

  const { docs = [] } = usersResults;

  return docs.map(doc => ({ ...doc.data(), id: doc.id }))
};

export const useGetFeedback = () => {

  const [comFeedback, setComFeedback] = useState([]);

  const setFeedbackForGameId = async (gameId) => {

    const results = await getFeedbackForGameId({ gameId })

    setComFeedback(results)

  }

  const getFeedBackbyDateRange = async (dateRange = {}) => {
    const results = await fecthFeedbackByDate({ ...dateRange })
    setComFeedback(results)
  }


  return { comFeedback, setFeedbackForGameId, getFeedBackbyDateRange };
};
