import { useState, useEffect } from 'react';
import { geHalfBoneCoordinates, addRawCoordinate, convertElementToHalfBones, getElementCenter } from './placeReport';
import { useInteactConfig } from './useInteactConfig';



const addtouchMatches = (halfBones, dropStates) => {

    return halfBones.map((half) => {

        const { xsmooth, ysmooth, dropstateKey, key } = half;

        const allNe = getNeighborCells({ xsmooth, ysmooth });
        const touches = allNe.filter(nepoint => {
            const { dropstateKey } = nepoint
            return dropStates[dropstateKey]
        })
        const touchMatches = touches.map(nepoint => {
            const { dropstateKey, } = nepoint
            const dropstate = dropStates[dropstateKey]
            return {
                key: dropstate.half.key,
                dropstateKey
            }
        })
            .filter(nedropstate => {
                const isMatch = (nedropstate.key === key);
                return isMatch
            })

        const newDropState = { half, allNe, touchMatches }
        dropStates[dropstateKey] = { ...newDropState }

        return {
            ...half,
            allNe,
            touchMatches
        }
    })


}

export const useCohadoStates = () => {
    const [mainCoord, setMainCoord] = useState(undefined);
    console.log("useCohadoStates -> mainCoord", mainCoord)
    const [boneStates, setBoneStates] = useState({});
    console.log("useCohadoStates -> boneStates", boneStates)
    const [dropStates, setDropStates] = useState({});
    console.log("useCohadoStates -> dropStates", dropStates)
    const [waste, setWaste] = useState([]);
    console.log("useCohadoStates -> waste", waste)

    const [wasteScore, setWasteScore] = useState(0);
    console.log("useCohadoStates -> wasteScore", wasteScore)

    const initMainCoord = (currentTarget) => {
        const mainCenter = getElementCenter(currentTarget)
        setMainCoord(mainCenter)
        return mainCenter
    }


    const getHalfbones = (currentTarget) => convertElementToHalfBones(currentTarget)
        .map(addRawCoordinate)
        .map((withRaw) => geHalfBoneCoordinates(withRaw, mainCoord || initMainCoord(currentTarget)));

    const onEnd = (event) => {
        const { currentTarget } = event;
        const halfBones = getHalfbones(currentTarget)

        console.log("onEnd -> halfBones", halfBones)

        // const drops = dropReport(currentTarget, parentNode, event)
        const [drop1, drop2] = halfBones;
        console.log("end -> drop1x, drop2", drop1.xCoord, drop2.xCoord);
        console.log("end -> drop1y, drop2", drop1.yCoord, drop2.yCoord);
        const boneKey = `${drop1.key}-${drop2.key}`;
        const boneState = boneStates[boneKey];


        setDropStates((currentDropStates) => {

            const stateUpdate = {
                ...currentDropStates
            }

            if (boneState) {
                const [statedrop1, statedrop2] = boneState.halfBones;

                delete stateUpdate[statedrop1.dropstateKey]
                delete stateUpdate[statedrop2.dropstateKey]
            }


            setBoneStates((currentBoneStates) => {
                return {
                    ...currentBoneStates,
                    [boneKey]: {
                        halfBones,
                        boneKey,
                    }
                }
            })

            const withTouchMatches = addtouchMatches(halfBones, stateUpdate)

            withTouchMatches.forEach(half => {

                const { dropstateKey, allNe, touchMatches, key } = half;
                const newDropState = { half, allNe, touchMatches }

                stateUpdate[dropstateKey] = { ...newDropState }

                touchMatches.map(touchMatch => {

                    const currentDropStateMatchNe = stateUpdate[touchMatch.dropstateKey];
                    const { touchMatches: tms } = currentDropStateMatchNe;
                    const newTouchMatches = tms.filter(tm => tm.dropstateKey !== dropstateKey)
                    newTouchMatches.push({ key, dropstateKey })
                    stateUpdate[touchMatch.dropstateKey] = {
                        ...currentDropStateMatchNe,
                        touchMatches: newTouchMatches
                    }
                    return touchMatch
                })


            })

            return {
                ...stateUpdate,
            }
        })

        // console.log("end -> boneStates", boneStates)
        // console.log("end -> dropStates", dropStates)
        // const singles = Object.keys(dropStates).map(key => dropStates[key]).filter(dropstate => dropstate.touchMatches.length === 0)
        // console.log("end -> singles", singles)
        // document.getElementById('dang-score').innerText = singles.length
    };
    const onendState = useInteactConfig({});

    useEffect(() => {
        if (onendState) {
            onEnd(onendState)
        }
    }, [onendState]);

    useEffect(() => {
        const wasteUpdate = Object.keys(dropStates).map(key => dropStates[key]).filter(dropstate => dropstate.touchMatches.length === 0)
        setWaste(wasteUpdate)
    }, [dropStates]);

    useEffect(() => {
        const wasteScoreUpdate = waste.reduce((score, wasteItem) => {
            const { half } = wasteItem;
            const { points } = half;
            return score + points
        }, 0)
        setWasteScore(wasteScoreUpdate)
    }, [waste]);

    return { dropStates, boneStates, wasteScore,mainCoord };
};

const getNeighborCells = ({ ysmooth, xsmooth }) => {

    const xset = [xsmooth - 1, xsmooth, xsmooth + 1]

    const yset = [ysmooth - 1, ysmooth + 1]

    const allNe = xset.reduce((all, xpoint) => {

        const combos = yset.map(ypoint => ({ xpoint, ypoint, nestateKey: `${xpoint}-${ypoint}` }))

        return [...all, ...combos]
    }, [])

        .map(nepoint => {

            const { xpoint, ypoint } = nepoint

            if (xpoint === xsmooth) {

                const ydiff = ypoint - ysmooth

                const newypoint = (ydiff * 2) + ysmooth

                return {
                    ...nepoint,
                    ypoint: newypoint,
                    dropstateKey: `${xpoint}-${newypoint}`
                }
            }

            return {
                ...nepoint,
                dropstateKey: `${xpoint}-${ypoint}`
            }

        })

    return allNe
}

