

export const AltAxisMeshWrapper = (props) => {
  const { children = [], bone } = props;

  return (
    <mesh
      name={`${bone.boneKey}-alt-axis`}
      // rotation={[0, (Math.PI / 180) * 240, 0]}
      // position={[0, 0, 5.2]}
    >
      <mesh
        name={`${bone.boneKey}-alt-axis-counter`}
        // position={[0, 0, -5.2]}
      >
        {children}
      </mesh>
    </mesh>
  );
};