import React, { useMemo, useState } from "react";
import firebase from "../../../firebase";
import { Bone } from "../Bone";

import "./bottomBar.css";
import { getGlyphType } from "../getGlyphSourceType";

export const BottomBar = (props) => {
  const { cloudGame } = props;

  const [stageState, setStageState] = useState("down");

  const stageBottom = useMemo(() => {
    switch (stageState) {
      case "up":
        return -15;
      case "down":
        return -65;
      default:
        return -65;
    }
  }, [stageState]);
  const [glyphHintsVisible, setGlyphHintsVisible] = useState(false);

  const turnCount = useMemo(() => {
    if (cloudGame && cloudGame.turn) {
      const { playerOrderList = [], turn = {} } = cloudGame;

      const turnCount = playerOrderList
        .map((player) => player.id)
        .indexOf(turn.id);
      return turnCount + 1;
    }
  }, [cloudGame]);

  const players = useMemo(() => {
    if (cloudGame) {
      const {
        playerOrderList = [],
        playerHands = {},
        setShowHand: saveShowHand = () => {},
        cohadoStates = {},
        turn = {},
      } = cloudGame;
      const { boneStates = {} } = cohadoStates;

      const { id: turnPlayerId } = turn;

      const playersData = playerOrderList.map((playerOrderData) => {
        const playerData = cloudGame[playerOrderData.id] || {};
        const { email, showHand } = playerData;

        const playerHand = playerHands[playerOrderData.id] || [];

        const localHandBones = playerHand
          .filter((bone) => {
            return !boneStates[bone.boneKey];
          })
          .map((bone) => {
            return {
              ...bone,
            };
          });
        // .slice(0, 7);

        const isMyStream =
          firebase.auth().currentUser.uid === playerOrderData.id;

        const setShowHand = isMyStream ? saveShowHand : undefined;

        return {
          id: playerOrderData.id,
          isPlayersTurn: turnPlayerId == playerOrderData.id,
          setShowHand,
          isMyStream,
          email,
          showHand,
          localHandBones,
          screenName: playerOrderData.screenName,
        };
      });

      return playersData;
    }
    return [];
  }, [cloudGame]);

  const silverHeight = useMemo(() => {
    if (stageState === "down") {
      return -6;
    }

    if (stageState === "up" && glyphHintsVisible) {
      return -160;
    }

    if (stageState === "up" && !glyphHintsVisible) {
      return 6;
    }
  }, [stageState, glyphHintsVisible]);

  return (
    <div
      className="bottomBar actionslide"
      style={{
        position: "absolute",
        width: "fit-content",
        bottom: stageBottom, // '-2%', //'-8%', // 0,//-40, // -15, //
        //  overflowX: "scroll",
        //  overflowY:'visible',
        display: "flex",
        flexWrap: "nowrap",
        zIndex: 50,
      }}
    >
      <div
        onClick={() => {
          switch (stageState) {
            case "down": {
              return setStageState("up");
            }
            case "up": {
              return setStageState("down");
            }
            default:
              break;
          }
        }}
        className="playerhand-b d-flex position-relative"
        style={{
          marginRight: -8,
          height: 90,
          width: 125,
          overflow: "visible",
          zIndex: 9,
        }}
      >
        <div
          style={{
            position: "absolute",
            top: silverHeight,
            transition: "top .5s ease",
          }}
        >
          {turnCount ? (
            <div
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setGlyphHintsVisible(!glyphHintsVisible);
              }}
              style={{
                position: "absolute",
                height: 30,
                top: "15%",
                zIndex: 10,
                width: "100%",
              }}
            >
              <img
                src={getGlyphType(turnCount, "color2")}
                style={{
                  position: "relative",
                  margin: "auto",
                  height: "100%",
                }}
              />
            </div>
          ) : (
            <></>
          )}
          <img
            src="./images/0d_numbers.png"
            alt=""
            style={{
              position: "relative",
              width: 125,
            }}
          />
        </div>
      </div>
      {players.map((streamerData, playerIndex) => {
        const {
          localHandBones = [],
          email = "",
          showHand,
          setShowHand = () => {},
          isPlayersTurn,
          isMyStream,
          screenName = '',
        } = streamerData;
        return (
          <div
            key={email}
            onClick={() => {
              setShowHand(!showHand);
            }}
            className="playerhand-b position-relative"
            style={{
              margin: "0 -8px",
              top: isPlayersTurn ? -13 : 0,
              backgroundImage: `url(/images/tab0${playerIndex + 1}.png)`,
              backgroundSize: "105% 105%",
              backgroundPosition: "-5px 0",
              height: 90, //isPlayersTurn ? 100:90,
              minWidth: 100,
              width: "fit-content",
              padding: "0 20px 0 20px",
              zIndex: 8 - playerIndex,
              transition: "top 1s ease",
            }}
          >
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                position: "relative",
                height: "36%",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              <img
                style={{
                  height: 20,
                  marginRight: 6,
                }}
                src={getGlyphType(playerIndex + 1, "colorsharp")}
              />
              <span
                style={{
                  textOverflow: "ellipsis",
                  maxWidth: 100,
                  overflow: "hidden",
                }}
              >
                {screenName || email}
              </span>
            </div>
            {
              <div
                className="d-flex align-items-center position-relatives"
                style={{
                  opacity:
                    (showHand || isMyStream) && stageState === "up" ? 1 : 0,
                  minWidth: "fit-content",
                  height: "fit-content",
                  transition: "opacity .5s ease",
                }}
              >
                {localHandBones.map((bone) => {
                  return (
                    <Bone
                      grey={isMyStream && !showHand}
                      key={bone.combo}
                      bone={bone}
                    />
                  );
                })}
              </div>
            }
          </div>
        );
      })}
    </div>
  );
};
