import { useState, useRef } from "react";
import { getGlyphType } from "./getGlyphSourceType";

const getBoneImgUrl = (glyph) => {
  return getGlyphType(glyph.points, "color2");
};

export const Bone = (props) => {
  const caseRef = useRef();
  const { bone, grey } = props;

  const [focused, setFocused] = useState(false);

  const [rotation, setRotation] = useState(0);

  const { thang1, thang2, combo } = bone;

  return (
    <div
      ref={caseRef}
      // onContextMenu={(event) => {
      //   event.preventDefault()
      //   setFocused(!focused)
      // }}
      onMouseOver={(event) => {
        event.preventDefault();
        setFocused(true);
      }}
      onMouseLeave={(event) => {
        event.preventDefault();
        setFocused(false);
      }}
      key={combo}
      className="bonecase draggable"
    >
      <div
        className="d-flex flex-column bone "
        style={{
          transform: `rotate(${rotation}deg)`,
        }}
      >
        <div className="half-hold">
          <img src="/images/50hexo.png" alt="" className="hexo" />

          <div className="marrow">
            <img
              className="half-bone"
              src={
                !grey
                  ? getBoneImgUrl(thang1)
                  : `/images/${thang1.strang}`.replace(".png", "-grey.png")
              }
              alt=""
            />
          </div>
        </div>
        <div className="half-hold">
          <img src="/images/50hexo.png" alt="" className="hexo" />

          <div className="marrow">
            <img
              style={{ transform: "rotate(180deg)" }}
              className="half-bone"
              src={
                !grey
                  ? getBoneImgUrl(thang2)
                  : `/images/${thang2.strang}`.replace(".png", "-grey.png")
              }
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};
