import { ScoreDigitGroup } from "./ScoreDigitGroup";
import { ScoreBoardWrapper } from './ScoreBoardWrapper'

export const ScoreBoard = (props) => {
  const { scoreReady, beats = 0, pointSections = {}, coe = 0 } = props;
  const { assets = { total: 0 }, waste = { total: 0 }, una = { total: 0 } } = pointSections;

  return (
    <ScoreBoardWrapper>
      <div
        className="scoreback unselectable"
        style={{
          // width: 300,
          backgroundColor: 'transparent',
          position: "relative",
          padding: 6,
          display: 'flex',
        }}
      >
        <ScoreDigitGroup title="ASSETS" number={!scoreReady ? 'COH' : assets.total} digitCount={3} />
        <ScoreDigitGroup title="WASTE" number={!scoreReady ? 'ADO' : waste.total} digitCount={3} />
        <ScoreDigitGroup title="BEATS" number={beats} digitCount={2} />
        <ScoreDigitGroup title="UNA" number={una.total} digitCount={3} />
        <ScoreDigitGroup title="COE" number={coe} digitCount={3} />
      </div>
    </ScoreBoardWrapper>

  );
};
