import { useMemo } from 'react';
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { useLoader } from '@react-three/fiber';

const baseUrl = '/glbs/gritbase.glb'

export const useLoadBase = () => {

  const baselglb = useLoader(GLTFLoader, baseUrl)

  const baseGroup = useMemo(() => {
    const { scene } = baselglb
    const clone = scene.clone()    
    return clone
  }, [baselglb])

  return baseGroup;
};
