import { useState, useRef, useMemo } from "react";
import { checkForUpdatedKeys } from "./checkForUpdatedKeys";
import { purgeBoneFromState } from "./addtouchMatches";
import moment from "moment";
import { useCallback } from "react";
import { calculateBoneStateNumbers } from "./calculateBoneStateNumbers";
import { getCheathado } from "../getCheathado";

export const useThreehadoStates = () => {
  const itemsRef = useRef({});

  const [boneStates, setBoneStates] = useState({});

  const onRemoteBonePosition = useCallback(
    event => {
      const { boneKey, position, rotation } = event;
      const boneItem = itemsRef.current[boneKey];

      if (boneItem) {
        boneItem.moveBone(position);
        boneItem.rotateBone(rotation._y, true);
      }
    },
    [itemsRef]
  );

  const removeBone = useCallback(
    boneKey => {
      setBoneStates(currentBoneStates => {
        const stateUpdate = {
          ...currentBoneStates
        };
        delete stateUpdate[boneKey];
        return {
          ...stateUpdate
        };
      });
    },
    [setBoneStates]
  );

  const onEnd = useCallback(
    event => {
      const { glyphs = [], boneItem = {} } = event;

      const { position, rotation } = boneItem.getPositionUpdate();

      const { boneListItem } = boneItem;

      const [drop1, drop2] = glyphs;
      const boneKey = `${drop1.key}-${drop2.key}`;

      const oldBoneState = boneStates[boneKey];

      const injectionNumber = Object.keys(boneStates)
        .map((stateKey) => boneStates[stateKey].injectionNumber || 0)
        .reduce((max, stateInjectionNumber) => Math.max(max, stateInjectionNumber), 0) + 1

      const isNewBoneState = !oldBoneState;
      delete boneStates[boneItem.boneKey];
      const boneCount = Object.keys(boneStates).length + 1;

      const stampPac = isNewBoneState
        ? {
          stamp: moment().valueOf(),
          boneCount,
          injectionNumber,
        }
        : {
          stamp: oldBoneState.stamp,
          boneCount: oldBoneState.boneCount,
          injectionNumber,
        };

      // purgeBoneFromState(oldBoneState);// we may not need this any more
      const { parentId, ...cleanListItem } = boneListItem;

      const newBoneState = {
        ...stampPac,
        boneListItem: {
          ...cleanListItem,
          initPosition: {
            xGridPoint: position.x,
            zGridPoint: position.z,
            rotation: rotation._y
          }
        },
        glyphs: glyphs,
        boneKey
      };

      setBoneStates(currentBoneStates => {
        const boneStateUpdate = {
          ...currentBoneStates,
          [boneKey]: {
            ...newBoneState
          }
        };

        return {
          ...boneStateUpdate
        };
      });
    },
    [boneStates, setBoneStates]
  );

  const lastestInjectionNumber = useMemo(() => {
    const boneList = Object.keys(boneStates).map(boneKey => boneStates[boneKey]);

    const latestInjectionNumber = boneList.reduce((largestInjecttionNUmber, boneState) => {
      const { injectionNumber = 0 } = boneState
      return injectionNumber > largestInjecttionNUmber ? injectionNumber : largestInjecttionNUmber
    }, 0)

    return latestInjectionNumber
  }, [boneStates]);

  const { validState, pointSections = {} } = useMemo(() => {
    const {
      pointSections = {},
      boneIslands = [],
      totalBadTouches = []
    } = calculateBoneStateNumbers(boneStates);

    const validState = getCheathado() || (boneIslands.length === 0 && totalBadTouches.length === 0);
    return {
      pointSections,
      validState,
    };
  }, [lastestInjectionNumber, boneStates]);

  const shouldUpdateBoneStates = useCallback(
    props => {
      const { cloudGameData = {} } = props;
      const { cohadoStates = {} } = cloudGameData;
      const boneStateUpdate = cohadoStates.boneStates || {};
      const updatedStates = checkForUpdatedKeys(boneStates, boneStateUpdate);

      if (updatedStates.length) {
        if (updatedStates.length === 1 && boneStates[updatedStates[0]]) {
          const boneMove = boneStateUpdate[updatedStates[0]];
          if (boneMove) {
            const { boneListItem = {}, boneKey } = boneMove;
            const { initPosition } = boneListItem;
            if (initPosition) {
              const { xGridPoint, zGridPoint, rotation } = initPosition;

              onRemoteBonePosition({
                boneKey,
                position: {
                  x: xGridPoint,
                  z: zGridPoint
                },
                rotation: {
                  _y: rotation
                }
              });
            }
          } else {
            //  means bone was removed
            return removeBone(updatedStates[0]);
          }
        }
        setBoneStates({
          ...boneStateUpdate
        });
      }
    },
    [boneStates, setBoneStates, onRemoteBonePosition]
  );

  return {
    pointSections,
    validState,
    removeBone,
    shouldUpdateBoneStates,
    boneStates,
    onEnd,
    setBoneStates,
  };
};
