import firebase from "firebase";

// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyCwOAS5wWTVAlHNikoTK-vfvUTfO-7fg0w",
//   authDomain: "big-hado.firebaseapp.com",
//   projectId: "big-hado",
//   storageBucket: "big-hado.appspot.com",
//   messagingSenderId: "508572604537",
//   appId: "1:508572604537:web:8c726d15b663907de6dca8",
//   measurementId: "G-T1N459BFPT"
// };

// firebase.initializeApp(firebaseConfig);

 const firestore = firebase.firestore();
// const settings = {
//   timestampsInSnapshots: true
// };
// firestore.settings(settings);
 const gamesCollectionRef = firestore.collection('cloudGames')
 const summaryCardCollectionRef = firestore.collection('summaryCards')
 const roundSetsCollectionRef = firestore.collection('roundSets')

 const auth = firebase.auth()

const getCurrentUser = () => {

  // const { search } = window.location;

  // if (search) {

  //   const params = new URLSearchParams(search)

  //   const demoUser = params.get('demou');

  //   if (demoUser) {
  //     return {
  //       id: demoUser
  //     }

  //   }
  // }

  // return {
  //   id: 'player1'
  //   // id: 'i2s' + Math.floor(Math.random() * 80) 
  // }


  const user = auth.currentUser

  if (user && user != null) {

    const { uid, email } = user
    const userPack = {
      email,
      id: uid
    }


    return userPack

  }


}


export {
  firebase,
  auth,
  firestore,
  getCurrentUser,
  gamesCollectionRef,
  summaryCardCollectionRef,
  roundSetsCollectionRef,
}

export default firebase;