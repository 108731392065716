import { Vector3 } from "three";
import { calcGridROtaion, vectorDiffRotation } from "../getGridCoords";
import { setlastSpot } from "../../HexGrid/lastSpot";

export const rotateAltAxis = (props) => {
  const { neRef, radians, radiansStep, axis = 1, boneKey } = props;

  try {
    const { altAxis, altAxisCounter } = getBoneAltAxisMeshes({
      boneKey,
      neRef,
    });

    const altAxisOffset = axis * 5.5;

    altAxis.position.z = altAxisOffset;
    altAxisCounter.position.z = -altAxisOffset;

    if (radiansStep) {
      altAxis.rotation.y += radiansStep;
    } else if (radians) {
      altAxis.rotation.y = radians;
    }
  } catch (error) {
    console.log(
      "🚀 ~ file: rotateAltAxis.js:24 ~ rotateAltAxis ~ error:",
      error
    );
  }
};

export const getBoneGlyphMeshes = (props) => {
  const { innerAxis, boneKey } = props;

  try {
    const glyph1 = innerAxis.children.find((item) => {
      return item.name == `${boneKey}-glyph_1`;
    });

    const glyph2 = innerAxis.children.find((item) => {
      return item.name == `${boneKey}-glyph_-1`;
    });
    const base = innerAxis.children.find((item) => {
      return item.name == `${boneKey}-base`;
    });
    return { glyph1, glyph2, base };
  } catch (error) {
    console.log(
      "🚀 ~ file: rotateAltAxis.js:30 ~ getBoneAltAxisMeshes ~ error:",
      error
    );
  }
};

export const getBoneGlyphMarker = (props) => {
  const { glyphData, boneKey } = props;

  try {
    const { glyphSpin, ref } = glyphData;

    const keyName = `rTaget${glyphSpin}${boneKey}`;

    const marker = ref.children.find((item) => {
      return item.name == keyName;
    });
    return marker;
  } catch (error) {
    console.log(
      "🚀 ~ file: rotateAltAxis.js:30 ~ getBoneAltAxisMeshes ~ error:",
      error
    );
  }
};
const getBoneAltAxisMeshes = (props) => {
  const { neRef, boneKey } = props;

  try {
    const altAxis = neRef.children.find((item) => {
      return item.name == `${boneKey}-alt-axis`;
    });

    const altAxisCounter = altAxis.children.find((item) => {
      return item.name == `${boneKey}-alt-axis-counter`;
    });

    return { altAxis, altAxisCounter };
  } catch (error) {
    console.log(
      "🚀 ~ file: rotateAltAxis.js:30 ~ getBoneAltAxisMeshes ~ error:",
      error
    );
  }
};

export const resetAltAxis = (props) => {
  const { neRef, boneKey } = props;

  const { altAxis, altAxisCounter } = getBoneAltAxisMeshes({
    boneKey,
    neRef,
  });

  const { glyph1, glyph2, base } = getBoneGlyphMeshes({
    innerAxis: altAxisCounter,
    boneKey,
  });

  const grid = calcGridROtaion(altAxis.rotation.y);
  altAxis.rotation.y = grid;

  let glyph1Position = new Vector3();
  glyph1.getWorldPosition(glyph1Position);

  let glyph2Position = new Vector3();
  glyph2.getWorldPosition(glyph2Position);

  // may need to change vector order based on rotation glyph
  const rad = vectorDiffRotation(glyph1Position, glyph2Position);

  let neRefPosi = new Vector3();
  base.getWorldPosition(neRefPosi);

  neRef.position.x = neRefPosi.x; // (glyph1Position.x + glyph2Position.x) / 2;
  neRef.position.z = neRefPosi.z; // (glyph1Position.z + glyph2Position.z) / 2;

  neRef.rotation.y = rad;
   setlastSpot(neRef.position);

  altAxis.position.z = 0;
  altAxisCounter.position.z = 0;
  altAxis.rotation.y = 0;

};
