import { isCohadoDrop } from "../useThreehadoStates/addCreatedAssets";
import { calculateBoneStateNumbers } from "../useThreehadoStates/calculateBoneStateNumbers";

export const setQuotesIfBridge = (props) => {

  let { cloudGameuUdate } = props;



  const { turns = [], turn } = cloudGameuUdate;

  const { boneStates } = turn

  try {

    if (!turn.tookBeat && turns.length > 3) {

      const { matchGroups, glyphToGroup, dropStatesUpdate } = calculateBoneStateNumbers(turn.boneStates);

      const madeBridge = isCohadoDrop({ matchGroups, glyphToGroup, boneStates, dropStates: dropStatesUpdate });

      if (madeBridge) {

        let { usedQuotes = [], quoteBay = [] } = cloudGameuUdate;

        if (!quoteBay.length) {

          quoteBay = usedQuotes.sort(() => Math.random()-.05);

          usedQuotes = [];
        }

        const [one, ...bayUpdate] = quoteBay;
        cloudGameuUdate.turn.madeBridge = true;
        cloudGameuUdate.turn.quote = one;

        cloudGameuUdate.turns[turns.length - 1].madeBridge = true;
        cloudGameuUdate.turns[turns.length - 1].quote = one;

        cloudGameuUdate = {
          ...cloudGameuUdate,
          quoteBay: [
            ...bayUpdate,
          ],
          usedQuotes: [
            ...usedQuotes,
            one,
          ]
        };

        return cloudGameuUdate

      }
    }
  } catch (error) {
    console.log("setQuotesIfBridge -> error", error);
    return cloudGameuUdate
  }

  return cloudGameuUdate
};


export const showBridgeAlertIfNeeded = (cloudGame) => {

  if (cloudGame && cloudGame.turns) {
    const { turns = [] } = cloudGame

    const lastTurn = turns[turns.length - 1]

    if (lastTurn && lastTurn.madeBridge) {

      document.getElementById('cohadobridgequote').innerText = lastTurn.quote.text
      document.getElementById('cohadobridgeauthor').innerText = lastTurn.quote.author
      const myModal = new window.bootstrap.Modal(document.getElementById('cohadoAlert'), {
        keyboard: true,
        backdrop: false
      });

      myModal.show()

    }
  }

}
