import { Suspense } from 'react'
import { Canvas } from '@react-three/fiber'

export const ThreeWrap = (props) => {
  const { children, backgroundColor } = props
  return (
    <Canvas
      onAbort={(error) => {
        console.log("🚀 ~ file: ThreeWrap.js:27 ~ ThreeWrap onAbort~ error", error)
      }}
      onError={(error) => {
        console.log("🚀 ~ file: ThreeWrap.js:27 ~ ThreeWrap ~ onErrorerror", error)
      }}
      gl={{ preserveDrawingBuffer: true }}
      id='canvas'
      style={{
        backgroundColor: backgroundColor,
      }}
    >
      <Suspense
        fallback={<>Loading</>}
      >
        {children}
      </Suspense>
    </Canvas>
  )
}