import { getGridCoords, gridUnitHeight } from "../BoneMeshSL/getGridCoords";
import { positionMHXR, rotateMHXR, showMHXR } from "../HexGrid/MoveHex";
import {
  resetAltAxis,
  rotateAltAxis
} from "../BoneMeshSL/AltAxisMeshWrapper/rotateAltAxis";
import { getLastSelectedBone } from "./setLastSelectedBone";
import { pickFloorMesh } from "../ThreeUtills/worldSpace";
import { updateParent } from "../BoneMeshSL/updateParent";
import { setlastSpot } from "../HexGrid/lastSpot";

const moveBone = (props) => {
  const selectedBone = getLastSelectedBone();
  if (selectedBone && selectedBone.neRef) {
    const { dx = 0, dz = 0 } = props;
    selectedBone.neRef.position.x += dx;
    selectedBone.neRef.position.z += dz;
    const pick = pickFloorMesh(selectedBone.neRef);

    if (pick) {
      const oldParentScale = selectedBone.neRef.parent.scale;
      const oldParentRotation = selectedBone.neRef.parent.rotation.y || 0;
      const nextParent = updateParent(selectedBone.neRef, pick.object.name);
      if (nextParent) {
        selectedBone.neRef.rotation.y =
          selectedBone.neRef.rotation.y -
          pick.object.rotation.y +
          oldParentRotation;
        selectedBone.neRef.position.x = pick.point.x / oldParentScale.x;
        selectedBone.neRef.position.z = pick.point.z / oldParentScale.z;

        if (nextParent.name === "board") {
          showMHXR();

          const { zGridPoint, xGridPoint } = getGridCoords(
            selectedBone.neRef.position.x,
            selectedBone.neRef.position.z,
            selectedBone.neRef.rotation.y
          );

          selectedBone.neRef.position.x = xGridPoint;
          selectedBone.neRef.position.z = zGridPoint;
        }
      }
    }
    setlastSpot(undefined);
    rotateMHXR(selectedBone.neRef.rotation.y);
    positionMHXR(selectedBone.neRef.position);
  }
};
export const onGlyphPress = (props) => {
  const selectedBone = getLastSelectedBone();
  if (selectedBone && selectedBone.neRef) {
    const { angleIndex } = props;

    const deg = angleIndex * 60;

    const rad = deg * (Math.PI / 180);

    const unitLength = gridUnitHeight;

    const dx = -unitLength * Math.sin(rad);
    const dz = unitLength * Math.cos(rad);

    moveBone({ dx, dz });
  }
};
export const rotate = (dir) => {
  const selectedBone = getLastSelectedBone();
  if (selectedBone && selectedBone.neRef) {
    const { glyphData, neRef } = selectedBone;
    const { glyphSpin: axis = 1 } = glyphData;

    const radiansStep = dir * (Math.PI / 180) * 60;

    if (neRef.parent.name !== "board") {
      neRef.rotation.y += radiansStep;
    } else {
      rotateAltAxis({
        neRef: selectedBone.neRef,
        axis,
        boneKey: selectedBone.touchPack.boneKey,
        radiansStep,
      });

      resetAltAxis({
        neRef: selectedBone.neRef,
        boneKey: selectedBone.touchPack.boneKey,
      });
      positionMHXR(selectedBone.neRef.position);
      rotateMHXR(selectedBone.neRef.rotation.y);
    }
  }
};
