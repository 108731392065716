import { getDropStateUpdates } from "./getDropStateUpdates";
import {
  addCreatedAssets,
  getGroupList,
  isCohadoDrop
} from "./addCreatedAssets";
import { getPointSectionFromGroups } from "./getPointSectionFromGroups";

let lastDropStates = {}

export const getLastDropStates = ( ) =>{

  return lastDropStates
}

export const calculateBoneStateNumbers = boneStates => {
  let dropStatesUpdate = {};

  const boneList = Object.keys(boneStates)
    .map(boneKey => boneStates[boneKey])
    .sort((a, b) => a.injectionNumber - b.injectionNumber);

  let matchGroups = {};
  let glyphToGroup = {}

  const totalBadTouches = [];

  boneList.map((boneState) => {
    const {
      dropStates,
      allBadTouches,
      withTouchMatches
    } = getDropStateUpdates({
      boneState,
      dropStates: dropStatesUpdate
    });

    totalBadTouches.push(...allBadTouches);

    dropStatesUpdate = {
      ...dropStates
    };

    return {
      boneState,
      withTouchMatches,
    }
  });

  const boneIslands = boneList.filter((boneState, boneCountRunIndex) => {

    const { glyphs = [], boneKey } = boneState

    const glyphWithOutTouches = glyphs.filter(glyph => {

      const dropstate = dropStatesUpdate[glyph.dropstateKey]

      const { touchMatches = [] } = dropstate;

      const outTouches = touchMatches.filter((tm) => tm.boneKey !== boneKey)

      return outTouches.length == 0
    })

    return glyphWithOutTouches.length == 2 && boneCountRunIndex
  });

  lastDropStates = {
    ...dropStatesUpdate
  }

  const dropStatesList = Object.keys(dropStatesUpdate)
    .map(key => dropStatesUpdate[key])

  dropStatesList.forEach(drpState => {

    const result = addCreatedAssets({
      glyph: drpState,
      matchGroups,
      glyphToGroup,
      dropStates: dropStatesUpdate,
    });

    matchGroups = {
      ...result.matchGroups,
    }

    glyphToGroup = {
      ...result.glyphToGroup
    }

  })

  const groupList = getGroupList({ matchGroups });
  const pointSections = getPointSectionFromGroups(groupList);

  return {
    matchGroups,
    glyphToGroup,
    pointSections,
    dropStatesUpdate,
    boneIslands,
    totalBadTouches
  };
};
