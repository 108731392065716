export const getPointSectionFromGroups = (groupList) => {

  return groupList.reduce(
    (sectionMap, group) => {
      const { assets, waste, una } = sectionMap;

      const { matches = [] } = group;

      switch (matches.length) {
        case 1:
          return {
            ...sectionMap,
            waste: updatePointSection(waste, group)
          }
        case 2:
          return {
            ...sectionMap,
            una: updatePointSection(una, group)
          }
        default:
          return {
            ...sectionMap,
            assets: updatePointSection(assets, group)
          }
      }
    },
    {
      assets: { total: 0, title: 'Assets: Clusters with three or more connected symbols', groups: [] },
      waste: { total: 0, title: 'Waste: Symbols with no connections', groups: [] },
      una: { total: 0, title: 'UNA: Unactivated couplets', groups: [] },
    }
  );

};


const updatePointSection = (section, group) => {

  const { groups } = section;


  const newGroups = [...groups, group]

  return {
    ...section,
    groups: newGroups,
    total: newGroups.reduce((total, group) => {

      const { matches, points } = group

      return total + (matches.length * points)

    }, 0),
  }
}
