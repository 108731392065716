import { useState } from "react";
import { auth } from "../../firebase";

const cohadoHeight = 300;

const ratio = 1152 / 648;

const cohadoWidth = cohadoHeight * ratio;

export const ForgotPassword = () => {
  const [resetEmail, setResetEmail] = useState("");

  const [error, setError] = useState();
  const [feedBack, setFeedBack] = useState();

  const onSendClick = async () => {
    if (resetEmail) {
      try {
        await auth.sendPasswordResetEmail(resetEmail);
        setFeedBack({
          status: "sent",
          msg: "email sent! look out for a reset password email",
        });
      } catch (emailerror) {
        console.log(
          "🚀 ~ file: ForgotPassword.js:26 ~ onSendClick ~ emailerror:",
          emailerror
        );
        setError(emailerror)
      }
    }
  };

  return (
    <div
      className="modal fade"
      id="forgotpassword"
      data-bs-keyboard="true"
      tabIndex="-1"
      aria-labelledby="forgotpasswordTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable cohado-alert">
        <div
          className="modal-content"
          style={{
            backgroundImage: "url(/images/PaperPiece.png)",
            backgroundSize: "100% 100%",
            backgroundColor: "transparent",
            width: cohadoWidth,
          }}
        >
          <h2 className="modal-title mt-3" id="forgotpasswordTitle">
            Forgot Password
          </h2>
          {!feedBack ? (
            <div
              style={{
                fontSize: 24,
              }}
              className="modal-body d-flex flex-column align-items-center"
            >
              <p className="text-center px-3 ">
                Enter Email and we will send you and password reset link
              </p>

              {error ? (
                <p className="text-center bg-danger px-3">
                  {error.message}
                </p>
              ) : (
                <></>
              )}

              <div className="w-100 px-3">
                <input
                  onChange={(event) => {
                    const {
                      target: { value },
                    } = event;
                    setResetEmail(value);
                  }}
                  placeholder="Email"
                  type="text"
                  className="form-control form-control-lg"
                />
                <div className="d-flex justify-content-center mt-2">
                  <button
                    onClick={() => {
                      onSendClick();

                    }}
                    style={{
                      backgroundImage: "url(/images/paperforbutton3.png)",
                      backgroundSize: "100% 100%",
                      backgroundColor: "transparent",
                      padding: "6px 12px",
                      border: 0,
                      textAlign: "center",
                      padding: 12,
                      textDecoration: "none",
                      color: "black",
                    }}
                  >
                    send email
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{
                fontSize: 24,
              }}
              className="modal-body d-flex flex-column align-items-center"
            >
              <p className="text-center px-3">{feedBack.msg}</p>
            </div>
          )}

          <div className="d-flex justify-content-center mx-3 mb-3">
            <img
              style={{
                margin: cohadoHeight / 25,
                height: cohadoHeight / 10,
              }}
              src="/images/CohadoX.png"
              type="button"
              data-bs-dismiss="modal"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
