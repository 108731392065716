import moment from "moment";
import { auth, firestore } from "../../firebase";
const cofilecolectionref = firestore.collection("cofiles");

const buildCofileDataFormEvent = (props) => {
  try {
    const { formSubmitEvent } = props;

    const { target } = formSubmitEvent;

    const listElements = [...target.getElementsByTagName("LI")];

    const cofileData = listElements.map((listElement) => {
      const [textElemnent] = listElement.getElementsByTagName("P");
      const listId = listElement.id;
      const keyName = listId.replace("cofile-li-", "");
      let value;
      const [textarea] = listElement.getElementsByTagName("textarea");
      const [input, input2] = listElement.getElementsByTagName("input");
      const [fieldset] = listElement.getElementsByTagName("fieldset");

      const text = textElemnent.innerText;

      const shouldConfirm = !!input2;

      const confirmed = shouldConfirm
        ? {
            canSend: input2.checked,
          }
        : {};

      if (fieldset) {
        const [fieldset] = listElement.getElementsByTagName("fieldset");

        const labels = fieldset.getElementsByTagName("label");

        const fieldValues = [...labels].map((fv) => {
          const [span] = fv.getElementsByTagName("span");
          const [input] = fv.getElementsByTagName("input");

          return {
            label: span.innerText,
            checked: input.checked,
          };
        });

        return {
          keyName,
          text,
          fieldValues,
        };
      }

      if (textarea) {
        value = textarea.value || "";
      }

      if (input && (input.type === "text" || input.type === "email")) {
        value = input.value || "";
      }

      const [checkImg] = listElement.getElementsByTagName("img");

      if (checkImg) {
        value = checkImg.src.indexOf("switch_on") > -1 ? true : false;
      }

      if (input && input.type === "file") {
        const [img] = listElement.getElementsByTagName("img");
        value = img.src;
      }

      return {
        keyName,
        text,
        value,
        ...confirmed,
      };
    });

    return cofileData;
  } catch (error) {
    console.log(
      "🚀 ~ file: SignUp2.js:91 ~ buildCofileDataFormEvent ~ error:",
      error
    );
    return [];
  }
};

export const setCofileData = (props) => {
  try {
    const { cofileData, keyProps = {} } = props;

    const target = document.getElementById("cofileform");
    const listElements = [...target.getElementsByTagName("LI")];

    listElements.map((listElement, elementIndex) => {
      const listId = listElement.id;
      const keyName = listId.replace("cofile-li-", "");

      const [textarea] = listElement.getElementsByTagName("textarea");
      const [input] = listElement.getElementsByTagName("input");
      const [fieldset] = listElement.getElementsByTagName("fieldset");

      const cofileMatch = cofileData.find(
        (cofileItem) => cofileItem.keyName == keyName
      );

      const { value, fieldValues } = cofileMatch;
      const valueItem = value || fieldValues;
      
      if (fieldset) {
        const fieldValues = valueItem; // cofileData[elementIndex].fieldValues;

        const [fieldset] = listElement.getElementsByTagName("fieldset");

        const labels = fieldset.getElementsByTagName("label");

        [...labels].map((fv, findex) => {
          const [input] = fv.getElementsByTagName("input");
          input.checked = fieldValues[findex].checked;
        });
      }

      if (textarea) {
        textarea.value = valueItem; // cofileData[elementIndex].value;
      }

      if (input && (input.type === "text" || input.type === "email")) {
        input.value = valueItem; // cofileData[elementIndex].value;
      }

      const [checkImg] = listElement.getElementsByTagName("img");

      if (checkImg) {
        checkImg.src = valueItem // cofileData[elementIndex].value
          ? "/images/switch_on.png"
          : "/images/switch_off.png";
      }

      if (input && input.type === "file") {
        const [img] = listElement.getElementsByTagName("img");
        img.src = valueItem; // cofileData[elementIndex].value;
      }
    });
  } catch (error) {
    console.log(
      "🚀 ~ file: formatFormSubmitEvent.js:164 ~ setCofileData ~ error:",
      error
    );
    return [];
  }
};

export const formatFormSubmitEvent = async (props) => {
  const { formSubmitEvent, savedCofile } = props;

  const cofileData = buildCofileDataFormEvent({ formSubmitEvent });

  await createSaveCofile({ cofileData, savedCofile });

  window.location.href = "/dash";
};

export const getUserCoilfe = async (props) => {
  try {
    const { userId = "" } = props;

    const { docs = [] } = await cofilecolectionref
      .where("userId", "==", userId)
      .get();
    const [doc] = docs;

    if (doc) {
      const data = {
        id: doc.id,
        ...doc.data(),
      };

      return data;
    }
  } catch (error) {
    console.log("🚀 ~ file: SignUp2.js:27 ~ getUserCoilfe ~ error:", error);
  }
};

export const getUserCoilfeWithEmail = async (props) => {
  try {
    const { email = "" } = props;

    const { docs = [] } = await cofilecolectionref
      .where("email", "==", email.toLowerCase())
      .get();
    const [doc] = docs;

    if (doc) {
      const data = {
        id: doc.id,
        ...doc.data(),
      };

      return data;
    }
  } catch (error) {
    console.log("🚀 ~ file: SignUp2.js:27 ~ getUserCoilfe ~ error:", error);
  }
};

const createSaveCofile = async (props) => {
  try {
    const { cofileData = [], savedCofile } = props;

    const currentUser = auth.currentUser;
    const userId = currentUser.uid;

    const moStamp = moment();
    const date = moStamp.format("LLL");
    const stamp = moStamp.valueOf();

    const { value: screenName } = cofileData[5] || {};
    const email = currentUser.email ? currentUser.email.toLowerCase() : "";

    const coPack = {
      userId,
      screenName,
      email,
      cofileData,
      date,
      stamp,
    };

    if (savedCofile) {
      await cofilecolectionref.doc(savedCofile.id).update({
        cofileData,
        updatedStamp: stamp,
        updated: date,
        screenName,
        email,
      });
    } else {
      await cofilecolectionref.add({ ...coPack });
    }
  } catch (error) {
    console.log("🚀 ~ file: SignUp2.js:27 ~ createSaveCofile ~ error:", error);
  }
};
const fileReaderAsync = (one) => {
  return new Promise((res, rej) => {
    let reader = new FileReader();

    reader.onloadend = function () {
      res(reader.result);
    };
    reader.readAsDataURL(one);
  });
};

export const onFileUpload = async (props) => {
  const { event } = props;
  const { files = [] } = event.target;
  const [one] = files;

  const preview = document.getElementById("preview");

  const dataUrl = await fileReaderAsync(one);

  preview.onload = function () {
    if (!preview.getAttribute("hasSmallUrl")) {
      var canvas = document.createElement("canvas");

      canvas.height = preview.height;
      canvas.width = preview.width;
      var context = canvas.getContext("2d");

      context.drawImage(preview, 0, 0, preview.width, preview.height);

      const cloneDataUrl = canvas.toDataURL();
      preview.src = cloneDataUrl;
      preview.setAttribute("hasSmallUrl", true);
    }
  };

  preview.src = dataUrl;
  preview.style.minWidth = "unset";
};
