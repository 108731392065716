export const getNeighborCells = ({ zsmooth, xsmooth }) => {

    const xset = [xsmooth - 2, xsmooth, xsmooth + 2]

    const zset = [zsmooth - 2, zsmooth + 2]

    const allNe = xset.reduce((all, xpoint) => {

        const combos = zset.map(zpoint => ({ xpoint, zpoint, nestateKey: `${xpoint}-${zpoint}` }))

        return [...all, ...combos]
    }, [])

        .map(nepoint => {

            const { xpoint, zpoint } = nepoint

            if (xpoint === xsmooth) {

                const zdiff = zpoint - zsmooth

                const newzpoint = (zdiff * 2) + zsmooth

                return {
                    ...nepoint,
                    zpoint: newzpoint,
                    dropstateKey: `${xpoint}-${newzpoint}`
                }
            }

            return {
                ...nepoint,
                dropstateKey: `${xpoint}-${zpoint}`
            }

        })

    return allNe
}