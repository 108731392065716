import "./style.css";
import moment from "moment";
import { startNewCloudGame } from "../ThreeHado/firebase/startNewCloudGame";
import {
  getCurrentUser,
  auth,
  gamesCollectionRef,
  firebase,
  firestore,
} from "../../firebase";
import { useHasAdminAccess } from "./useHasAdminAccess";
import { KeyMenu } from "../KeyMenu";

import { useUserGames } from "./useUserGames";
import { GamePill } from "./GamePill";
import { useCallback, useState, useEffect, useMemo } from "react";
import Switch from "react-switch";
import { makeWatcher } from "./makeWatcher";
import { getUserCoilfe } from "../SignUp/formatFormSubmitEvent";

const cloudGameRef = firestore.collection("cloudGames");

export function DashBoard() {
  const user = useMemo(() => {
    return getCurrentUser();
  }, []);

  const isAdmin = useHasAdminAccess({ user });

  const { email } = user;

  const onSignOut = () => {
    auth.signOut();
  };

  const { pagedGames = [], getUserGames = () => {} } = useUserGames();

  const [clickedPlayerData, setClickedPlayerData] = useState(undefined);

  const [showMyHostedGames, setShowMyHostedGames] = useState(false);

  const [showArchivedGames, setShowArchivedGames] = useState(false);

  const [clickedGameData, setClickedGameData] = useState(undefined);

  const [cofileData, setNeedsCofile] = useState(false);

  useEffect(() => {
    const getCofile = async () => {
      if (user) {
        const cofile = await getUserCoilfe({ userId: user.id });
        setNeedsCofile(cofile);
      }
    };

    getCofile();
  }, [user]);

  const [pages, setPages] = useState([]);

  const [page, setPage] = useState(0);

  const turnPage = useCallback(
    (way) => {
      const wayPlus = way > 0;

      const shouldTurn = wayPlus || page > 0;

      const nextPage = shouldTurn ? page + way : page;

      const startStamp =
        wayPlus && pagedGames.length
          ? pagedGames[pagedGames.length - 1].createdStamp + 1
          : pages[nextPage] || moment().valueOf();

      setPages({
        ...pages,
        [nextPage]: startStamp,
      });

      getUserGames({
        startStamp,
        archived: showArchivedGames,
        showMyHostedGames,
      });
      setPage(nextPage);
    },
    [
      page,
      setPage,
      pagedGames,
      pages,
      setPages,
      getUserGames,
      showArchivedGames,
      showMyHostedGames,
    ]
  );

  useEffect(() => {
    getUserGames({
      startStamp: moment().valueOf(),
      archived: showArchivedGames,
      showMyHostedGames,
    });
  }, [showArchivedGames, showMyHostedGames]);

  const onClickNewGame = async () => {
    await startNewCloudGame();
    getUserGames({
      startStamp: moment().valueOf(),
      archived: showArchivedGames,
      showMyHostedGames,
    });

    setPage(0);
    setPages([]);
  };

  const onPlayerDoubleClick = (deleteProps) => {
    setClickedPlayerData(deleteProps);

    const myModal = new window.bootstrap.Modal(
      document.getElementById("prePlayerConfig"),
      {
        keyboard: false,
      }
    );
    myModal.show();
  };

  const deletePlayer = useCallback(async () => {
    const { game, player } = clickedPlayerData;

    const { allPlayers = [], invitedPlayers = [], activeEmails = [] } = game;

    const allPlayersUpdate = allPlayers.filter(
      (playa) => playa.email !== player.email
    );

    const allEmails = allPlayersUpdate.map((player) => {
      return player.email;
    });

    const activeEmailsUpdate = activeEmails.filter(
      (email) => email !== player.email
    );

    const invitedUpdate = invitedPlayers.filter(
      (invitedEmail) => invitedEmail !== player.email
    );

    try {
      if (player.isInvitedData) {
        await gamesCollectionRef.doc(game.id).update({
          allPlayers: allPlayersUpdate,
          allEmails,
          activeEmails: [...activeEmailsUpdate],
          invitedPlayers: invitedUpdate,
          [player.email.toLowerCase().split(".").join("-")]:
            firebase.firestore.FieldValue.delete(),
        });
      } else {
        await gamesCollectionRef.doc(game.id).update({
          allPlayers: allPlayersUpdate,
          activeEmails: [...activeEmailsUpdate],
          allEmails,
          invitedPlayers: invitedUpdate,
          [player.email.toLowerCase().split(".").join("-")]:
            firebase.firestore.FieldValue.delete(),
        });
        await gamesCollectionRef.doc(game.id).update({
          allPlayers: allPlayersUpdate,
          allEmails,
          activeEmails: [...activeEmailsUpdate],
          invitedPlayers: invitedUpdate,
          [player.id]: firebase.firestore.FieldValue.delete(),
        });
      }
    } catch (error) {
      console.log("deletePlayer -> error", error);
    }
  }, [clickedPlayerData]);

  const onArchiveGame = useCallback(async () => {
    const { game, } = clickedPlayerData;

    try {
      // await gamesCollectionRef.doc(game.id).update({
      //   archived,
      // });
    } catch (error) {
      console.log("onArchiveGame -> error", error);
    }
  }, [clickedPlayerData]);

  const fireMakeWatcher = useCallback(async () => {
    makeWatcher(clickedPlayerData);
  }, [clickedPlayerData]);

  const cofileImage = useMemo(() => {
    if (cofileData) {
      const { cofileData: list = [] } = cofileData;

      const image = list[list.length - 1];

      if (image && image.value) {
        return image.value;
      }
    }
  }, [cofileData]);

  return (
    <>
      <div
        style={{
          backgroundImage: "url(/images/GameHistory_background.png)",
          backgroundSize: "100% auto",
          overflow: "hidden",
        }}
        className="h-100 dash-board w-100 d-flex flex-column"
      >
        {/* {!cofileData && (
          <a
            href="/cofile"
            style={{
              backgroundColor: "#2a2c2c",
              color: "gold",
              fontSize: 36,
            }}
            className="d-flex co-header  justify-content-center  w-100"
          >
            Click here to fill out co-file (cohado profile)
          </a>
        )} */}
        <div
          style={{
            position: "relative",
            zIndex: 99,
          }}
          className="d-flex co-header justify-content-center  w-100"
        >
          <div className="flex-1 d-flex align-items-center  justify-content-center"></div>

          <div
            style={{
              position: "relative",
              width: 170,
              height: 150,
            }}
          >
            {cofileData ? (
              <>
                <div
                  onClick={async () => {
                    onClickNewGame();
                  }}
                  className="d-flex align-items-center justify-content-center"
                  style={{
                    position: "absolute",
                    width: 170,
                    height: "100%",
                    color: "brown",
                    lineHeight: 1,
                  }}
                >
                  START A NEW EXPERIENCE
                </div>
                <img
                  src="/images/buttonblink.gif"
                  // src="/images/startButton.png"
                  onClick={async () => {
                    onClickNewGame();
                  }}
                  className="newexpbutton"
                />
              </>
            ) : (
              <a href="/cofile">
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{
                    position: "absolute",
                    width: 170,
                    height: "100%",
                    color: "brown",
                    lineHeight: 1,
                  }}
                >
                  COMPLETE
                  <br />
                  YOUR
                  <br />
                  COFILE
                </div>
                <img src="/images/buttonblink.gif" className="newexpbutton" />
              </a>
            )}
          </div>

          <div className="flex-1 cohado drop down">
            <div
              style={{
                position: "relative",
                top: 12,
                // marginRight: 17,
              }}
            >
              <div
                className="dash-drop"
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    width: 90,
                  }}
                >
                  <img
                    src="/images/account_logo.png"
                    className="account_logo"
                  />
                  <div
                    style={{
                      top: 0,
                      left: 0,
                      zIndex: 10,
                      width: "100%",
                      height: "100%",
                    }}
                    className="position-absolute dropdown d-flex justify-content-center align-items-center"
                  >
                    {!cofileData && (
                      <img
                        data-bs-toggle="dropdown"
                        src="/images/general_notification.png"
                        className="general_notification"
                        style={{
                          top: 0,
                          left: -10,
                          zIndex: 20,
                          height: 50,
                          position: "absolute",
                        }}
                      />
                    )}
                    <div
                      data-bs-toggle="dropdown"
                      className="d-flex justify-content-center align-items-center"
                      style={{
                        height: 75,
                        borderRadius: "150%",
                        overflow: "hidden",
                        width: 75,
                        position: "relative",
                      }}
                    >
                      <img
                        style={{
                          position: "relative",
                          height: "150%",
                        }}
                        src={cofileImage}
                      />
                    </div>
                    <ul
                      style={{
                        backgroundImage: "url(/images/paper01.png)",
                        backgroundColor: "transparent",
                        backgroundSize: "100% 100%",
                        color: "#2a2c2c",
                      }}
                      className="dropdown-menu align-items-center"
                    >
                      <li>
                        <a className="dropdown-item" href="#">
                          <span
                            style={{
                              fontWeight: "bold",
                            }}
                          >
                            {email}
                          </span>
                        </a>
                      </li>

                      <li>
                        <a
                          className="dropdown-item"
                          style={{
                            position: "relative",
                            border: 0,
                            textAlign: "center",
                            textDecoration: "none",
                          }}
                          href={`/cofile`}
                        >
                          <p
                            style={{
                              position: "relative",
                              border: 0,
                              margin: 0,
                              textAlign: "center",
                              textDecoration: "none",
                            }}
                          >
                            Cofile
                          </p>

                          {!cofileData && (
                            <img
                              src="/images/arrow2.png"
                              style={{
                                position: "relative",
                                top: -5,
                                // position: "absolute",
                                // left: -90,
                                height: 15,
                              }}
                            />
                          )}
                        </a>
                      </li>

                      <li
                        style={{
                          position: "relative",
                        }}
                        className="d-flex justify-content-center"
                      >
                        {!cofileData && (
                          <img
                            src="/images/cofile_notification.png"
                            style={{
                              position: "absolute",
                              left: -140,
                              height: 80,
                            }}
                          />
                        )}

                        <img
                          src="/images/paperkey.png"
                          style={{
                            position: "relative",
                            height: 60,
                            transform: "rotate(40deg)",
                          }}
                          onClick={() => {
                            const myModal = new window.bootstrap.Modal(
                              document.getElementById("keyMenu"),
                              {
                                keyboard: false,
                              }
                            );
                            myModal.show();
                          }}
                        />
                      </li>

                      {isAdmin && (
                        <li>
                          <a
                            className="dropdown-item"
                            style={{
                              border: 0,
                              textAlign: "center",
                              textDecoration: "none",
                            }}
                            // href={`/feedback?gameId=${game.id}`}
                            href={`/feedback`}
                          >
                            Feedback
                          </a>
                        </li>
                      )}

                      <li>
                        <button
                          className="dropdown-item"
                          style={{
                            border: 0,
                            textAlign: "center",
                          }}
                          onClick={onSignOut}
                        >
                          Log out
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {cofileData ? (
          <>
            <div>
              <img
                src="/images/gamehistorytext.png"
                alt=""
                style={{
                  height: 50,
                  marginBottom: 12,
                }}
              />
            </div>

            {/* <h1 className="history-title"> Game History </h1> */}
            <div id="gamepillscroll" className="pill-bay w-100 flex-1 scroll-y">
              {pagedGames.map((game) => {
                return (
                  <GamePill
                    key={game.id}
                    onPlayerDoubleClick={onPlayerDoubleClick}
                    onDeleteClick={setClickedGameData}
                    // onArchiveGame={onArchiveGame}
                    game={game}
                  />
                );
              })}
            </div>

            <div className="d-flex justify-content-center align-items-center">
              <div
                style={{
                  flex: 1,
                }}
              />

              <button
                className="btn btn-dark barlow"
                onClick={() => {
                  turnPage(-1);
                }}
              >
                Previous
              </button>

              <p
                className="bg-dark barlow text-light mx-3"
                style={{
                  fontSize: 24,
                  borderRadius: 50,
                  width: 36,
                  textAlign: "center",
                }}
              >
                {page + 1}
              </p>

              <button
                className="btn btn-dark barlow"
                onClick={() => {
                  turnPage(1);
                }}
              >
                Next
              </button>
              <div
                style={{
                  flex: 1,
                }}
                className="d-flex  align-items-center ps-3"
              >
                {/* <span
              style={{
                position: "relative",
                fontWeight: "bolder",
                backgroundColor: "white",
              }}
            >
              Archived Games ?
            </span>

            <Switch
              className="mx-3"
              onChange={() => {
                setShowArchivedGames(!showArchivedGames);
              }}
              checked={showArchivedGames}
            /> */}
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>

      {/*  */}
      <div
        className="modal fade"
        id="deletePlayer"
        data-bs-keyboard="true"
        tabIndex="-1"
        aria-labelledby="deletePlayer"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header d-flex justify-content-center">
              <h5 className="modal-title" id="deletePlayer">
                Delete Player ?
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {!!clickedPlayerData && (
              <div className="modal-body">
                do you want to delete <b>{clickedPlayerData.player.email}</b>,
                from game ID {clickedPlayerData.game.id} ?
              </div>
            )}
            <div className="modal-footer justify-content-between">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                no
              </button>
              <button
                data-bs-dismiss="modal"
                type="button"
                className="btn btn-warning"
                onClick={() => {
                  deletePlayer();
                }}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>

      {/*  */}

      <div
        className="modal fade"
        id="deleteGame"
        data-bs-keyboard="true"
        tabIndex="-1"
        aria-labelledby="deleteGame"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header d-flex justify-content-center">
              <h5 className="modal-title" id="deleteGame">
                Delete Game ?
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {!!clickedGameData && (
              <div className="modal-body">
                do you want to delete game code {clickedGameData.id} ?
              </div>
            )}
            <div className="modal-footer justify-content-between">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                no
              </button>
              <button
                data-bs-dismiss="modal"
                type="button"
                className="btn btn-warning"
                onClick={async () => {
                  const doc = await cloudGameRef.doc(clickedGameData.id);
                  await doc.delete();
                }}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="prePlayerConfig"
        data-bs-keyboard="true"
        tabIndex="-1"
        aria-labelledby="prePlayerConfig"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header d-flex justify-content-center">
              <h5 className="modal-title" id="prePlayerConfig">
                Pre Player Config
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            {!!clickedPlayerData && (
              <div className="modal-body">
                <p>
                  <b>{clickedPlayerData.player.email}</b>
                </p>
                {clickedPlayerData.player.id ? (
                  <p>Player ID : {clickedPlayerData.player.id}</p>
                ) : (
                  <></>
                )}
                <p>Game ID : {clickedPlayerData.game.id}</p>
              </div>
            )}

            <div className="modal-footer justify-content-between">
              <button
                onClick={() => {
                  const myModal = new window.bootstrap.Modal(
                    document.getElementById("deletePlayer"),
                    {
                      keyboard: false,
                    }
                  );
                  myModal.show();
                }}
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                delete player
              </button>
              <button
                data-bs-dismiss="modal"
                type="button"
                className="btn btn-warning"
                onClick={() => {
                  const myModal = new window.bootstrap.Modal(
                    document.getElementById("makewatcher"),
                    {
                      keyboard: false,
                    }
                  );
                  myModal.show();
                }}
              >
                make observer
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="makewatcher"
        data-bs-keyboard="true"
        tabIndex="-1"
        aria-labelledby="makewatcher"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header d-flex justify-content-center">
              <h5 className="modal-title" id="makewatcher">
                Make Observer ?
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {!!clickedPlayerData && (
              <div className="modal-body">
                <p>
                  do you want to make <b>{clickedPlayerData.player.email}</b> a
                  Observer?
                </p>
                {clickedPlayerData.player.id ? (
                  <p>Player ID : {clickedPlayerData.player.id}</p>
                ) : (
                  <></>
                )}
                <p>Game ID : {clickedPlayerData.game.id}</p>
                <p>
                  Player will not be able to pick or play bone pieces but will
                  be able to join game and see updates
                </p>
              </div>
            )}
            <div className="modal-footer justify-content-between">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                no
              </button>
              <button
                data-bs-dismiss="modal"
                type="button"
                className="btn btn-warning"
                onClick={() => {
                  fireMakeWatcher();
                }}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
      <KeyMenu />
    </>
  );
}
