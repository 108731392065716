// import { HexMesh } from "./HexMesh";
// import { HexModel } from "./HexModel";

// import { poistions } from './getHexPositions'

// export const HexGrid = (props) => {

//     return (
//         <>
//             {
//                 poistions.map((pos, i) => {
//                     return (
//                         <HexModel
//                             key={i}
//                             pos={pos}
//                         />
//                     )
//                 })
//             }
//         </>
//     )
// }

import { getLocalFeature } from "../../KeyMenu/localFeatureConfig";
import { WholeHexModel } from "./WholeHexModel";
import { WholeHexModel2 } from "./WholeHexModel/WholeHexModel2";

const lowFiv1 = getLocalFeature("lowFiv1");

export const HexGrid = (props) => {
  if (lowFiv1) {
    return <WholeHexModel2 />;
  }
  return (
    <>
      <WholeHexModel />
    </>
  );
};
// import { HexMesh } from "./HexMesh";
// import { poistions } from "./getHexPositions";
// import { useEffect, useRef } from "react";
// import * as THREE from "three";
// import { hexShape } from "./createHex";

// const grey = new THREE.MeshBasicMaterial({ color: "grey" });
// grey.color.set("grey");
// const geo = new THREE.ShapeBufferGeometry(hexShape);

// const points = [];
// points.push(new THREE.Vector3(0, 0, 0));
// points.push(new THREE.Vector3(0, 1, 10));
// // points.push( new THREE.Vector3( 0, 0, 0 ) );

// const lineGeometry = new THREE.BufferGeometry().setFromPoints(points);

// export function HexGrid({ temp = new THREE.Object3D() }) {
//   const ref = useRef();
//   useEffect(() => {
//     // Set positions
//     poistions.forEach((pos, i) => {
//       temp.position.set(pos.x, pos.y, 0);
//       temp.updateMatrix();
//       ref.current.setMatrixAt(i, temp.matrix);
//     });

//     // Update the instance
//     ref.current.instanceMatrix.needsUpdate = true;
//   }, []);
//   return (
//     <instancedMesh
//       ref={ref}
//       args={[null, null, poistions.length]}
//       rotation={[-90 * (Math.PI / 180), 0, 0]}
//       geometry={geo}
//       material={grey}
//     >
//         <line geometry={lineGeometry}>
//                 <lineBasicMaterial attach="material" color={'#9c88ff'} linewidth={10} linecap={'round'} linejoin={'round'} />
//             </line>

//     </instancedMesh>
//   );
// }
