import { useState, useMemo, useEffect, useCallback } from "react";
import {
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { UseSort } from "./UseSort";
import { ContextOrNah } from "./ContextOrNah";
import { useAnimationState } from "./useAnimationState";
import { sendRTMmessage } from "../VideoChatAgora/initAgoraRTM";
import "./playerorder.css";
import { getLocalFeature } from "../../../KeyMenu/localFeatureConfig";
const boneCountForPlayerCount = {
  1: 14,
  2: 7,
  3: 7,
  4: 5,
  5: 4,
  6: 3,
  7: 3,
};

const cohadoHeight = 600;

const ratio = 1080 / 1772;

const cohadoWidth = cohadoHeight * ratio + 20;

export const PlayerOrder = (props) => {
  const { show, setShow, cloudGame, setPlayerOrder, showStartScreen, iAmhost } =
    props;

  const cloudPlayers = useMemo(() => {
    // if (cloudGame) {
    //   const playerList = Object.keys(cloudGame)
    //     .map((gameKey) => cloudGame[gameKey])
    //     .filter((gameSlice) => gameSlice.isPlayerData)
    //     .filter((player) => !player.isWatcher)
    //     .sort((a, b) => a.stamp - b.stamp);
    //   return [...playerList];
    // }
    // return [];

    if (cloudGame) {
      const { allPlayers = [] } = cloudGame;

      const filteredPlayers = allPlayers
        .filter((player) => player.isPlayerData)
        .filter((player) => !player.isWatcher)
        .sort((a, b) => a.stamp - b.stamp);
      return [...filteredPlayers];
    }
    return [];
  }, [cloudGame]);

  const playersForCount = useMemo(() => {
    if (cloudGame) {
      const { allPlayers = [] } = cloudGame;
      return [...allPlayers];
    }
    return [];
  }, [cloudGame]);

  const [keywordOpacity, setKeywordOpacity] = useState(0);

  const [playerOrderList, setPlayerOrderList] = useState(cloudPlayers);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const onNextStep = async () => {
    const bonesPerPlayer = boneCountForPlayerCount[playerOrderList.length];

    await setPlayerOrder({ playerOrderList, cloudGame, bonesPerPlayer });
    window.playerOrderWindow.hide();
    // setShow(false);
  };

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active && over && active.id !== over.id) {
      setPlayerOrderList((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);
        const newItems = arrayMove(items, oldIndex, newIndex);
        sendRTMmessage({
          msg: JSON.stringify({
            type: "animationState",
            phase: "playerOrder",
            playerOrder: [...newItems],
          }),
        });
        return newItems;
      });
    }
  }

  useEffect(() => {
    setPlayerOrderList([...cloudPlayers]);
  }, [cloudPlayers]);

  const [goAnimation, setGoAnimation] = useState(false);

  const goStartAnimation = useCallback(() => {
    setGoAnimation("move");
    setTimeout(() => {
      // setNewGameMenu(true);
      setGoAnimation("end");
    }, 8000);
  }, [setGoAnimation]);

  const remoteAnimationState = useAnimationState();

  useEffect(() => {
    if (remoteAnimationState && !iAmhost && showStartScreen) {
      const { phase } = remoteAnimationState;

      const shouldSelfStart = getLocalFeature("allSelfStart");

      if (!shouldSelfStart && phase && phase === "goStart" && !goAnimation) {
        goStartAnimation();
      }
      if (phase && phase === "playerOrder") {
        setPlayerOrderList([...remoteAnimationState.playerOrder]);
      }
    }
  }, [
    remoteAnimationState,
    iAmhost,
    showStartScreen,
    goAnimation,
    goStartAnimation,
    setPlayerOrderList,
  ]);

  useEffect(() => {
    if (showStartScreen) {
      setTimeout(() => {
        setKeywordOpacity(1);
      }, 3500);
      window.playerOrderWindow = new window.bootstrap.Modal(
        document.getElementById("playerOrder"),
        {
          keyboard: false,
        }
      );
      window.playerOrderWindow.show();
    } else {
      if (window.playerOrderWindow) {
        window.playerOrderWindow.hide();
      }
    }
  }, [showStartScreen, setKeywordOpacity]);

  return (
    <>
      <div
        className="modal fade"
        id="playerOrder"
        data-bs-backdrop="static"
        data-bs-keyboard="true"
        tabIndex="-1"
        aria-labelledby="playerOrder"
        aria-hidden="true"
        style={{
          overflow: "hidden",
        }}
      >
        <div
          className="modal-dialog"
          style={{
            top: 50,
            height: cohadoHeight,
            width: cohadoWidth,
          }}
        >
          <div
            className="modal-content"
            style={{
              height: cohadoHeight,
              width: cohadoWidth,
              backgroundColor: "transparent",
            }}
          >
            <div
              style={{
                zIndex: goAnimation === "end" ? 1 : 100,
                height: 850,
                flex: "none",
                top: -130,
                left: -500,
                position: "absolute",
              }}
            >
              {/*
               <img
                style={{
                  left: "35%",
                  top: "35%",
                  position: "absolute",
                  zIndex: 100,
                  // zIndex:goAnimation === false ? 100 : 1,
                  // opacity: goAnimation === false ? 1 : 0,
                  opacity: keywordOpacity,
                  display: goAnimation === false ? "unset" : "none",
                  transition: "opacity 1s ease-in",
                }}
                src="./images/keytalkcloud.png"
                alt=""
              />
               */}
              <div
                className="keymessage"
                style={{
                  cursor: "pointer",
                  left: "39%",
                  top: playersForCount.length > 1 ? "40%" : "45%",
                  position: "absolute",
                  zIndex: 90,
                  width: 180,
                  opacity: keywordOpacity,
                  display: goAnimation === false ? "unset" : "none",
                  transition: "opacity 1s ease-in",
                }}
              >
                {playersForCount.length > 1 ? (
                  <p
                    style={{
                      fontFamily: "isocpeur",
                      textAlign: "center",
                      color: "#ff9933",
                    }}
                  >
                    Welcome to the Cohado Experience. Your Co-ponents are
                    joining the platform
                  </p>
                ) : (
                  <p
                    style={{
                      fontFamily: "isocpeur",
                      textAlign: "center",
                      color: "#ff9933",
                    }}
                  >
                    Welcome to the Cohado Experience.
                  </p>
                )}
                <div>
                  <p
                    style={{
                      fontFamily: "isocpeur",
                      textAlign: "center",
                      color: "#ff9933",
                    }}
                  >
                    Touch the
                  </p>
                  <p
                    style={{
                      fontFamily: "isocpeur",
                      textAlign: "center",
                      color: "#ff9933",
                    }}
                  >
                    key to open
                  </p>
                  <img
                    className="touch-sign"
                    style={{
                      marginTop: 20,
                      height: 15,
                      rotate: "180deg",
                    }}
                    src="/images/arrow.png"
                    alt=""
                  />
                </div>
              </div>

              <div
                onClick={(e) => {
                  e.stopPropagation();

                  // const shouldSelfStart = getLocalFeature('allSelfStart')

                  // if (iAmhost && !shouldSelfStart) {
                  //   sendRTMmessage({
                  //     msg: JSON.stringify({
                  //       type: "animationState",
                  //       phase: "goStart",
                  //     }),
                  //   });
                  // }

                  // if (shouldSelfStart) {
                  goStartAnimation();
                  // } else if (iAmhost) {
                  //   goStartAnimation()
                  // }
                }}
                style={{
                  cursor: "pointer",
                  left: "40%",
                  top: "59%",
                  position: "absolute",
                  zIndex: 100,
                  height: 230,
                  width: 150,
                  // border: "1px solid black",
                }}
              />

              <img
                style={{
                  display: goAnimation === false ? "unset" : "none",
                  height: 850,
                  zIndex: 1,
                  position: "relative",
                }}
                src="/images/setupdrop.gif"
                alt=""
              />
              <img
                id="animationbook"
                style={{
                  height: 850,
                  left: 0,
                  top: 0,
                  zIndex: 5,
                  display:
                    goAnimation === "move" || goAnimation === "end"
                      ? "unset"
                      : "none",
                  position: "absolute",
                }}
                src="/images/setup2.gif"
                alt=""
              />
            </div>

            <div
              className={`${
                goAnimation === "end" ? "setupfade " : ""
              }setup-body modal-body`}
              style={{
                height: cohadoHeight,
                width: cohadoWidth,
                zIndex: 10,
                position: "relative",
              }}
            >
              <ContextOrNah
                enabled={iAmhost}
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
              >
                <SortableContext
                  strategy={verticalListSortingStrategy}
                  items={playerOrderList}
                >
                  <div className="d-flex h-100 flex-column">
                    <div
                      style={{
                        height: 100,
                      }}
                    />
                    <div
                      style={{
                        width: "100%",
                        position: "relative",
                      }}
                    >
                      <img
                        style={{
                          top: -10,
                          opacity: playersForCount.length > 1 ? 1 : 0,
                          position: "relative",
                          height: 22,
                          margin: "auto",
                        }}
                        src={
                          iAmhost
                            ? "./images/set_up_sentence2b.png"
                            : "./images/set_up_sentenceb.png"
                        }
                        alt=""
                      />
                    </div>
                    <div
                      onClick={(event) => event.stopPropagation()}
                      className="d-flex flex-column flex-1"
                    >
                      <div className="usersList w-100 flex-1 mt-1 d-flex flex-column justify-content-start">
                        {playerOrderList.map((user, playerNumber) => {
                          return (
                            <UseSort key={user.id} id={user.id}>
                              <div
                                className="position-relative d-flex align-items-center"
                                style={{
                                  height: 45,
                                  width: "100%",
                                  flex: "none",
                                  margin: "2px 0",
                                }}
                              >
                                <img
                                  src={`images/${playerNumber + 1}s.png`}
                                  style={{
                                    position: "relative",
                                    height: 40,
                                    margin: 6,
                                  }}
                                  alt=""
                                />

                                <p
                                  style={{
                                    position: "relative",
                                    fontFamily: "oatmilk",
                                    fontSize: 24,
                                    margin: "0 auto",
                                    width: cohadoWidth * 0.8,
                                    textDecoration: "ellipsis",
                                    overflow: "hidden",
                                    color: "#2a2c2c",
                                  }}
                                >
                                  {user.screenName || user.email}
                                </p>
                              </div>
                            </UseSort>
                          );
                        })}
                      </div>
                    </div>
                    {iAmhost ? (
                      <div className="new-game-top-bar justify-content-center d-flex w-100 mb-3">
                        <button
                          style={{
                            position: "relative",
                            backgroundImage: "url(/images/paperforbutton3.png)",
                            backgroundSize: "100% 100%",
                            backgroundColor: "transparent",
                            padding: "3px 6px",
                            border: 0,
                            textAlign: "center",
                            padding: "9px",
                            textDecoration: "none",
                            fontFamily: "oatmilk",
                            color: "black",
                            fontSize: 24,
                          }}
                          onClick={() => {
                            onNextStep();
                          }}
                          className="m-1"
                        >
                          Pick Bones
                        </button>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </SortableContext>
              </ContextOrNah>
            </div>
          </div>
        </div>
      </div>

      {/* 
      <div
        className="modal fade"
        id="startGame"
        data-bs-backdrop="static"
        data-bs-keyboard="true"
        tabIndex="-1"
        aria-labelledby="startGame"
        aria-hidden="true"
        style={{
          overflow: 'hidden'
        }}
      >
        <div
          className="modal-dialog"

          style={{
            margin: 0,
            height: '100%',
            width: '100%',
            maxWidth: '100%',
          }}
        >
          <div
            className="modal-content"
            style={{
              backgroundColor: "transparent",
              margin: 0,
              height: '100%',
              width: '100%'
            }}
          >
            <div
              className="modal-body d-flex align-items-center  justify-content-center"
              style={{
                backgroundColor: 'transparent',
                zIndex: 10,
                position: "relative",
              }}
            >

              <div
                onClick={() => {
                  // setVideoChatOpen(true);
                }}
                className="menubutton-on"
              >
                <span>Start</span>
              </div>

            </div>
          </div>
        </div>
      </div> 
*/}
    </>
  );
};
