import { gamesCollectionRef } from "../firebase";
import { isEqual } from "lodash";
import { checkForUpdatedKeys } from "../useThreehadoStates/checkForUpdatedKeys";
import { calculateCoe } from "../useThreehadoStates/calculateCoe";

export const shouldUpdateCohadoState = async props => {
  console.log("shouldUpdateCohadoState -> props", props);
  const { cohadoStates = {}, cloudGame = {}, pointSections } = props;

  if (cloudGame && cloudGame.id) {
    const { boneStates } = cloudGame.cohadoStates || {};

    const boneStateUpdate = cohadoStates.boneStates || {};

    const boneChange = !isEqual(boneStates, boneStateUpdate);

    const updatedStates = checkForUpdatedKeys(boneStates, boneStateUpdate);
    console.log("shouldUpdateCohadoState -> updatedStates", updatedStates);

    // const dropStateUpdate = cohadoStates.dropStates || {}

    // const dropChange = !isEqual(dropStates, dropStateUpdate);
    if (updatedStates.length) {
      const coe = calculateCoe({ pointSections, beats: cloudGame.beats })
      await gamesCollectionRef.doc(cloudGame.id).update({
        cohadoStates,
        pointSections,
        coe,
      });
    }

    /////////////////////////

    // const { boneStates = {} } = cohadoStates;
    // const cloudStates = cloudGame.cohadoStates || {};
    // const { boneStates: cloudBones = {} } = cloudStates;
    // const keysWereAdded = !isEqual(Object.keys(boneStates).sort(), Object.keys(cloudBones).sort());
    // if (keysWereAdded && cloudGame.id) {
    //     await gamesCollectionRef
    //         .doc(cloudGame.id)
    //         .update({
    //             cohadoStates,
    //         });
    // }
  }
};
