export const shouldShowBeatMsg = (cloudGame) => {

  if (cloudGame && cloudGame.turn) {
    const { turn } = cloudGame;

    const { tookBeat } = turn;

    if (tookBeat) {
      const myModal = new window.bootstrap.Modal(
        document.getElementById("beatmsg"),
        {
          keyboard: true,
          backdrop: false,
        }
      );

      myModal.show();
    }

  }
};
