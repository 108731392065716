import { useEffect } from 'react';
export const useSearchParamGameId = (initCloudGame, cloudGame) => {

  useEffect(() => {
    var searchParams = new URLSearchParams(window.location.search);

    const gameId = searchParams.get('gameId');

    if (gameId) {
      const isCurrentGame = cloudGame && cloudGame.id === gameId;
      if (gameId && !isCurrentGame) {
        initCloudGame(gameId);
      }
    }

  }, []);
};
