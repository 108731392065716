import { addtouchMatches } from './addtouchMatches';
export const getDropStateUpdates = (props) => {
    const allBadTouches = [];
    const { dropStates, boneState } = props;
    const { boneKey, glyphs, stamp, boneCount,injectionNumber } = boneState;
    const withTouchMatches = addtouchMatches(glyphs, dropStates);
    withTouchMatches.forEach(glyph => {
        const { badTouches = [], ...half } = glyph;
        const { dropstateKey, allNe, touchMatches, key, points } = half;
        allBadTouches.push(...badTouches);
        const newDropState = {
            stamp,
            boneCount,
            injectionNumber,
            dropstateKey,
            half,
            key,
            points,
            allNe,
            touchMatches,
            boneKey
        };
        dropStates[dropstateKey] = {
            ...newDropState,
        };
        touchMatches.map(touchMatch => {
            const currentDropStateMatchNe = dropStates[touchMatch.dropstateKey];
            const { touchMatches: tms } = currentDropStateMatchNe;
            const newTouchMatches = tms.filter(tm => tm.dropstateKey !== dropstateKey);
            newTouchMatches.push({ key, dropstateKey, boneKey,points });
            dropStates[touchMatch.dropstateKey] = {
                ...currentDropStateMatchNe,
                touchMatches: newTouchMatches
            };
            return touchMatch;
        });
    });
    const allTouchMatches = withTouchMatches.reduce((all, glyphData) => {
        const { touchMatches = [] } = glyphData;
        return [
            ...all,
            ...touchMatches.filter((touch) => touch.boneKey !== boneKey)
        ];
    }, []);

    return {
        withTouchMatches,
        allTouchMatches,
        allBadTouches,
        dropStates: {
            ...dropStates
        }
    };
};
